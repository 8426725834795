import React from 'react';
import './Start.scss';
import { DefaultButton } from '@fluentui/react';
import { useAppDispatch } from 'store';
import { downloadVoorbeeldBestand } from 'store/actions/importAannemers/data';

export const Start = () => {
    const dispatch = useAppDispatch();
    const handleDownloadVoorbeeldBestand = (): any => {
        dispatch(downloadVoorbeeldBestand()).then();
    };
    return (
        <div className="start">
            <div className="page-title">Importeren aannemers die opdrachten via email ontvangen</div>
            <div>
                <DefaultButton text="Download voorbeeldbestand" onClick={handleDownloadVoorbeeldBestand} />
            </div>
            <div>
                De volgende gegevens worden geïmporteerd:
                <ul>
                    <li>Naam Aannemer</li>
                    <li>Externe Referentie (referentie in uw ERP/CRM)</li>
                    <li>GLN-code (GLN-code van Ketenstandaard, optioneel)</li>
                    <li>Emailadres Opdracht (Het emailadres waar de opdrachten naar toe worden gestuurd)</li>
                </ul>
            </div>
            <div>
                Indien een aannemer geen eigen GLN-code heeft, mag dit veld leeg gelaten worden.
            </div>
            <div>Klik op volgende om de import te beginnen.</div>
        </div>
    )
};
