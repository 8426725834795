import React, { useEffect, useState } from 'react';
import { EndpointsBezorgen } from 'components/Endpoints/EndpointsBezorgen/EndpointsBezorgen';
import { EndpointsOntvangen } from 'components/Endpoints/EndpointsOntvangen/EndpointsOntvangen';
import { peekResult, useAppDispatch, useTypedSelector } from 'store';
import { getEndpointsOntvangen, getCorporatieEndpointsBezorgen } from 'store/selectors/instellingen';
import {
    createCorporatieEndpointsBezorgen,
    deleteCorporatieEndpointsBezorgen,
    fetchCorporatieEndpointsBezorgen,
    resetCorporatieEndpointsBezorgen,
    updateCorporatieEndpointsBezorgen
} from 'store/actions/instellingen/endpointsBezorgen';
import {
    createCorporatieEndpointsOntvangen, deleteCorporatieEndpointsOntvangen,
    fetchCorporatieEndpointsOntvangen, resetCorporatieEndpointsOntvangen, updateCorporatieEndpointsOntvangen
} from 'store/actions/instellingen/endpointsOntvangen';
import {
    EndpointBezorgen,
    ICreateEndpointBezorgenForm,
    IUpdateEndpointBezorgenForm
} from 'interfaces/endpointBezorgen';
import { unwrapResult } from '@reduxjs/toolkit';
import { EndpointOntvangen } from 'interfaces/endpointOntvangen';

export const Endpoints = () => {
    const dispatch = useAppDispatch();
    const [refetch, setRefetch] = useState(false);
    const {status: statusBezorgen, endpointsBezorgen, error: errorBezorgen} = useTypedSelector(getCorporatieEndpointsBezorgen);
    const {status: statusOntvangen, endpointsOntvangen, error: errorOntvangen} = useTypedSelector(getEndpointsOntvangen);

    useEffect(() => {
        dispatch(fetchCorporatieEndpointsBezorgen());
        dispatch(fetchCorporatieEndpointsOntvangen());

    }, [dispatch]);

    useEffect(() => {
        if (refetch) {
            dispatch(fetchCorporatieEndpointsBezorgen());
            dispatch(fetchCorporatieEndpointsOntvangen());
            setRefetch(false);
        }
    }, [dispatch, refetch]);

    // Endpoints bezorgen
    async function onAddItemBezorgen(itemx: ICreateEndpointBezorgenForm) {
        return dispatch(createCorporatieEndpointsBezorgen({data: itemx})).then(unwrapResult);
    }

    async function onEditItemBezorgen(item: IUpdateEndpointBezorgenForm): Promise<EndpointBezorgen> {
        await dispatch(resetCorporatieEndpointsBezorgen());
        return dispatch(updateCorporatieEndpointsBezorgen({ data: item })).then(unwrapResult);
    }

    async function onDeleteItemBezorgen(item: EndpointBezorgen): Promise<boolean> {
        const result = await dispatch(deleteCorporatieEndpointsBezorgen(item.id.toString())).then(peekResult);
        if (result) {
            dispatch(fetchCorporatieEndpointsBezorgen());
        }
        return result;
    }

    async function onResetBezorgen() {
        await dispatch(resetCorporatieEndpointsBezorgen());
    }
    
    // Endpoints ontvangen
    async function onAddItemOntvangen(itemx: EndpointOntvangen) {
        return dispatch(createCorporatieEndpointsOntvangen({data: itemx})).then(unwrapResult);
    }

    async function onEditItemOntvangen(item: EndpointOntvangen): Promise<EndpointOntvangen> {
        await dispatch(resetCorporatieEndpointsOntvangen());
        return dispatch(updateCorporatieEndpointsOntvangen({ data: item })).then(unwrapResult);
    }

    async function onDeleteItemOntvangen(item: EndpointOntvangen): Promise<boolean> {
        const result = await dispatch(deleteCorporatieEndpointsOntvangen(item.id.toString())).then(peekResult);
        if (result) {
            dispatch(fetchCorporatieEndpointsOntvangen());
        }
        return result;
    }

    async function onResetOntvangen() {
        await dispatch(resetCorporatieEndpointsOntvangen());
    }
    
    

    return (
        <>
            <EndpointsBezorgen endpointsBezorgen={endpointsBezorgen}
                               titelEnkelvoud="Vastleggen endpoint voor bezorgen Status & Factuur"
                               titelMeervoud="Vastleggen endpoints voor bezorgen Status & Factuur"
                               isCorporatie={true}
                               onAddItem={onAddItemBezorgen}
                               onEditItem={onEditItemBezorgen}
                               onDeleteItem={onDeleteItemBezorgen}
                               reset={onResetBezorgen}
                               error={errorBezorgen}
                               status={statusBezorgen}
            ></EndpointsBezorgen>
            <EndpointsOntvangen endpointsOntvangen={endpointsOntvangen}
                                titelEnkelvoud="Vastleggen endpoint voor ontvangen DICO berichten vanuit ERP"
                                titelMeervoud="Vastleggen endpoints voor ontvangen DICO berichten vanuit ERP"
                                isCorporatie={true}
                                onAddItem={onAddItemOntvangen}
                                onEditItem={onEditItemOntvangen}
                                onDeleteItem={onDeleteItemOntvangen}
                                reset={onResetOntvangen}
                                error={errorOntvangen}
                                status={statusOntvangen}
            ></EndpointsOntvangen>
        </>
    )
};
