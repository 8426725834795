import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { IStateMachine, RootState } from 'store'
import { IDeelnemer } from '../../../interfaces/deelnemer'

interface IFetchDeelnemerArgs {
  gln: string
}

const fetchDeelnemer = createAsyncThunk<IDeelnemer | null, IFetchDeelnemerArgs, { state: RootState }>(
  'deelnemers/fetchByGln',
  async ({ gln }, thunkApi) => {
    return await invokeFetch<IDeelnemer | null>(thunkApi, 'GET', `/ketenstandaard/deelnemers/${gln}`, null, false)
  }
)

const initState: IStateMachine = {
  status: 'idle',
  error: '',
}

export const slice = createSlice({
  name: 'deelnemers/fetch',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDeelnemer.pending, (state, _) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(fetchDeelnemer.fulfilled, (state, _) => {
      state.status = 'succeeded'
    })
    builder.addCase(fetchDeelnemer.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })
  },
})

export { fetchDeelnemer }

export default slice.reducer
