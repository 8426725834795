import React from 'react'
import { Text } from '@fluentui/react'
import './index.scss'

interface IInputCardProps {
  title: string
  className?: string
  style?: React.CSSProperties
  contentStyle?: React.CSSProperties
  required?: boolean
}

const InputCard: React.FC<IInputCardProps> = ({
  title,
  className,
  children,
  style = {},
  contentStyle = {},
  required = false,
}) => {
  return (
    <div className={`inputCard ${className}`} style={style}>
      <Text as="h2" variant="large">
        {title}
        {required && (
          <Text variant="large" styles={{ root: { color: '#a4262c' } }}>
            &nbsp;*
          </Text>
        )}
      </Text>
      <div className="inputCardContent" style={contentStyle}>
        {children}
      </div>
    </div>
  )
}

export default InputCard
