import React, { FunctionComponent } from 'react'
import './App.css'
import './Main.scss'

import Toolbar from './containers/Toolbar'
import Navbar from './containers/Navbar'
import GenericError from 'containers/GenericError'

type MainProps = {}

const Main: FunctionComponent<MainProps> = props => {
    return (
        <div className="page">
            <Toolbar/>
            <div className="main">
                <Navbar/>
                <GenericError/>
                <div className="content">{props.children}</div>
            </div>
        </div>
    )
};

export default Main
