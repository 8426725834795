import React, { useState } from 'react'
import {
  DefaultButton,
  IButtonStyles,
  ISpinnerStyles,
  IStackStyles,
  ITextFieldStyles,
  ITextStyles,
  MessageBar,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'
import OhkTextField from '../../../../components/OhkTextField'
import { useForm } from 'react-hook-form'
import OhkReadOnlyField from '../../../../components/OhkReadOnlyField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { IAddBusinessRule, IConfigAvailableBusinessRules, IUpdateBusinessRule } from '../models/businessRules'
import OhkToggle from '../../../../components/OhkToggle'
import { callApi } from '../api/BusinessRuleData'
import { stringToLocaleString } from '../../../../lib/internationalization'
import { overlayPopupWindow } from './BusinessRulesSelectionPopup'

const textFieldStyles: Partial<ITextFieldStyles> = {
  wrapper: {
    border: 0,
    '::after': {
      border: 0
    }
  },
  subComponentStyles: {
    label: {
      root: {
        fontWeight: '600',
        width: 120,
        paddingLeft: 10
      }
    }
  },
  field: {
    fontWeight: 600
  },
  suffix: {
    background: ''
  },
  fieldGroup: {
    border: '2px solid black'
  },
  root: {
    border: 0
  }

}

const stackStyles: IStackStyles = {
  root: {
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10
  }
}

const stackButtonsStyles: IStackStyles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10
  }
}

const annulerenOpslaanButtonsStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row'
  }
}

const ButtonsStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%'
  }
}

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    marginRight: 10
  }
}

const readOnlyFieldStyles: Partial<ITextFieldStyles> = {
  wrapper: {
    border: 0,
    wordWrap: 'wrap',
    '::after': {
      border: 0
    }
  },
  subComponentStyles: {
    label: {
      root: {
        width: 0
      }
    }
  },
  field: {
    fontWeight: 800,
    width: '100%',
    overflow: 'hidden'
  }
}

const unBoldReadOnlyFieldStyles: Partial<ITextFieldStyles> = {
  wrapper: {
    border: 0,
    wordWrap: 'wrap',
    '::after': {
      border: 0
    }
  },
  subComponentStyles: {
    label: {
      root: {
        width: 0
      }
    }
  },
  field: {
    width: '100%',
    overflow: 'hidden'
  }
}

const dateFieldStyles: Partial<ITextFieldStyles> = {
  wrapper: {
    border: 0,
    wordWrap: 'wrap',
    '::after': {
      border: 0
    }
  },
  subComponentStyles: {
    label: {
      root: {
        paddingLeft: 8,
        fontWeight: '600'
      }
    }
  },
  field: {
    width: '100%',
    overflow: 'hidden',
    paddingLeft: 4
  }
}

const textStyles: Partial<ITextStyles> = {
  root: {
    width: 107,
    fontWeight: '600',
    paddingLeft: 0
  }
}

const StackContainerStyles: IStackStyles = {
  root: {
    position: 'fixed',
    left: '50%',
    width: '70%',
    translate: '-50% -50%',
    top: '50%',
    backgroundColor: '#fff',
    opacity: 10,
    zIndex: 20,
    padding: 20,
    maxHeight: '80%'
  }
}

const spinnerStyles: Partial<ISpinnerStyles> = {
  root: {
    zIndex: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

interface PopupSavingWindowProps {
  businessRules: IConfigAvailableBusinessRules;
  updateBusinessRules: (refetchBusinessRules: boolean) => void;
  openPopup: boolean;
  updatePopup: (isOpen: boolean) => void;
  updateSelectedBusinessRule: (selectedData: any) => void;
}

const BusinessRuleConfigurationPopup: React.FC<PopupSavingWindowProps> = ({
                                                                            businessRules,
                                                                            updateBusinessRules,
                                                                            updatePopup,
                                                                            openPopup,
                                                                            updateSelectedBusinessRule
                                                                          }) => {
  const [error, setError] = useState()

  const { control, formState: { errors, isSubmitting, isDirty }, handleSubmit } = useForm<IAddBusinessRule>()

  const { code, codeNaam, naam, toelichting, opmerkingen, actief, id, datumGewijzigd } = businessRules

  const onSavingBusinessRuleSucess = () => {
    updateBusinessRules(true)
    updatePopup(false)
    updateSelectedBusinessRule(undefined)
  }

  const onSaveBusinessRules = async (data: IAddBusinessRule | IUpdateBusinessRule) => {
    if (id) {
      await callApi(`BusinessRules/config/${id}`, 'PUT', { ...data, id: id })
        .catch(error => setError(error))
    } else {
      await callApi('BusinessRules/config', 'POST', { ...data, code: code })
        .catch(error => setError(error))
    }
    onSavingBusinessRuleSucess()
  }

  const deleteBusinessRule = async () => {
    await callApi(`BusinessRules/config/${id}`, 'DELETE')
      .catch(error => setError(error))
    onSavingBusinessRuleSucess()
  }

  const onRemovePopup = () => {
    updatePopup(false)
    updateSelectedBusinessRule(undefined)
  }

  return (
    <>
      {openPopup && <>
        <Stack styles={StackContainerStyles}>
          {error && <MessageBar>{Error}</MessageBar>}
          <OhkReadOnlyField title={''} value={`Business rule - ${naam} (${codeNaam})`}
                            styles={readOnlyFieldStyles} />
          <OhkReadOnlyField title={''} value={toelichting} styles={unBoldReadOnlyFieldStyles}
                            multiline={toelichting.length > 150} />
          <Stack horizontal styles={stackStyles}>
            <Text styles={textStyles}>Actief</Text>
            <OhkToggle name={getPropertyName<IAddBusinessRule | IUpdateBusinessRule>('actief')} label=""
                       control={control}
                       showOnOffText={false} checked={actief ?? true} />
          </Stack>
          <OhkTextField name={getPropertyName<IAddBusinessRule | IUpdateBusinessRule>('opmerkingen')}
                        defaultValue={opmerkingen ?? ''} label="Opmerkingen" errors={errors} control={control}
                        placeholder="" multiline styles={textFieldStyles} />
          {datumGewijzigd && <OhkReadOnlyField title={'Datum gewijzigd'} value={stringToLocaleString(datumGewijzigd)}
                                               styles={dateFieldStyles}
                                               multiline={toelichting.length > 150} />}
          <Stack horizontal styles={stackButtonsStyles}>
            {id &&
              <Stack>
                <DefaultButton type="submit"
                               onClick={handleSubmit(deleteBusinessRule)}
                               iconProps={{ iconName: 'Delete', className: 'icon' }} disabled={isSubmitting}>
                  Verwijderen
                </DefaultButton>
              </Stack>
            }
            <Stack styles={id ? annulerenOpslaanButtonsStyles : ButtonsStyles}>
              <DefaultButton onClick={() => updatePopup(false)} text="Annuleren" styles={buttonStyles} />
              <DefaultButton primary allowDisabledFocus type="submit" onClick={handleSubmit(onSaveBusinessRules)}
                             disabled={(id ? !isDirty : false) || isSubmitting}>
                Opslaan
              </DefaultButton>
            </Stack>
          </Stack>
          {isSubmitting && <Spinner size={SpinnerSize.large} styles={spinnerStyles} />}
        </Stack>
        <Stack styles={overlayPopupWindow} onClick={onRemovePopup} />
      </>}
    </>
  )
}

export default BusinessRuleConfigurationPopup
