import * as y from 'yup';
import * as m from '../lib/constants/validationMessages';

export interface EndpointOntvangen {
    id: number;
    naam: string;
    protocol: number;
    gebruikersnaam: string;
    geblokkeerd: boolean;
    aannemerId: number;
    wachtwoordEenmalig: string;
}

export interface ICreateEndpointOntvangenForm {
    naam: string
    protocol: number;
    aannemerId?: number;
}

export interface IUpdateEndpointOntvangenForm {
    id: number
    naam: string
    // protocol: number;
    geblokkeerd: boolean
}

export const endpointOntvangenSchema = y.object({
    naam: y.string().required(m.REQUIRED).max(100, m.STR_MAX_LENGTH_100),
    protocol: y.number().required(m.REQUIRED).oneOf([1, 2], "Protocol moet 1 (SOAP) of 2 (REST) zijn"),
});

export const endpointOntvangenUpdateSchema = y.object({
    naam: y.string().required(m.REQUIRED).max(100, m.STR_MAX_LENGTH_100),
});
