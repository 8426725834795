import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStateMachine } from 'store'
import { RootState } from 'store/rootReducer'
import { invokeFetch } from 'services/apiClient'
import { DashboardDateRange, IDashboardStatistiekPerStatus } from 'interfaces/dashboard';

interface IDashboardState extends IStateMachine {
  data: IDashboardStatistiekPerStatus
}

const initState: IDashboardState = {
  data: {
    alleBerichten: 0,
    onbestelbaar: 0,
    inBehandeling: 0,
    nietBezorgd: 0,
    bezorgd: 0,
    handmatigAfgehandeld: 0,
  },
  status: 'idle',
  error: '',
}

const fetchStatistiekenPerStatus = createAsyncThunk<IDashboardStatistiekPerStatus, DashboardDateRange, { state: RootState }>(
  'dashboard/statistieken/fetch',
  async (dashboardDateRange , thunkApi) => {
    var body = {DatumVan: dashboardDateRange.datumVan, DatumTm: dashboardDateRange.datumTm};
    return await invokeFetch<IDashboardStatistiekPerStatus>(
      thunkApi,
      'POST',
      `/dashboard/statistieken/status`,
        body,
      false
    )
  }
)

export const slice = createSlice({
  name: 'dashboard/statistieken/type',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStatistiekenPerStatus.pending, (state, action) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(fetchStatistiekenPerStatus.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(fetchStatistiekenPerStatus.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })
  },
})

export { fetchStatistiekenPerStatus }

export default slice.reducer
