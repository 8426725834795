import { MsalClient } from '../../../../services/MsalClient'
import { store } from '../../../../store'

type methods = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH'
const callApi = async (apiUrl: string, method: methods, body?: any) => {
  const apiEndpoint = store.getState().app.configuration.apiEndpoint
  const token = await MsalClient.getApiToken()

  const options: RequestInit = {
    method: method,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${token}`
    }
  }

  if (body) {
    options.body = JSON.stringify(body)
  }

  const response = await fetch(`${apiEndpoint}/${apiUrl}`, options)
  if (method === 'DELETE') return
  return response.json()
}

export { callApi }
