import React, { useMemo } from 'react';
import { ImportUploadFileRow } from 'interfaces/importUploadFileRow';
import { IColumn } from '@fluentui/react';
import { getPropertyName } from 'lib/interfaceUtils';
import OhkTable from 'components/OhkTable';

interface IProps {
    importFileRows: ImportUploadFileRow[];
}

export const UploadPreview = ({importFileRows}: IProps) => {

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: getPropertyName<ImportUploadFileRow>('naam'),
                name: 'Naam aannemer',
                fieldName: getPropertyName<ImportUploadFileRow>('naam'),
                data: 'string',
                minWidth: 120,
                maxWidth: 250
            },
            {
                key: getPropertyName<ImportUploadFileRow>('referentie'),
                name: 'Externe referentie',
                fieldName: getPropertyName<ImportUploadFileRow>('referentie'),
                data: 'string',
                minWidth: 120,
                maxWidth: 250
            },
            {
                key: getPropertyName<ImportUploadFileRow>('glnCode'),
                name: 'GLN-code ketenstandaard',
                fieldName: getPropertyName<ImportUploadFileRow>('glnCode'),
                data: 'string',
                minWidth: 120,
                maxWidth: 250
            },
            {
                key: getPropertyName<ImportUploadFileRow>('email'),
                name: 'Emailadres opdracht',
                fieldName: getPropertyName<ImportUploadFileRow>('email'),
                data: 'string',
                minWidth: 120,
                maxWidth: 250
            }
        ], []);

    function handleGetKey(item): string {
        return (item as ImportUploadFileRow).email;
    }

    return (
        <div className="upload-preview">
            <OhkTable columns={columns} items={importFileRows} loading={false} onGetKey={handleGetKey}></OhkTable>
        </div>
    );
};
