import React, { useMemo, useState } from 'react'
import { DefaultButton, IColumn, IStackStyles, Stack } from '@fluentui/react'
import { SelectableFluentUiTable } from './selectableFluentUiTable'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { IAvailableBusinessRules, IConfigAvailableBusinessRules } from '../models/businessRules'

interface BusinessRuleSelectionPopupProps {
  isPopupOpen: boolean;
  updatePopup: (isPopupOpen: boolean) => void;
  updateSavingPopup: (isPopupOpen: boolean) => void;
  updateBusinessRulesRefetching: (refetch: boolean) => void;
  availableBusinessRules: IAvailableBusinessRules[];
  onSelectionChanged: (selectedData: any) => void;
}

const stackStyles: IStackStyles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10
  }
}

export const overlayPopupWindow: IStackStyles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'black',
    opacity: 0.8,
    zIndex: 10,
    backdropFilter: 'blur(4px)'
  }
}

const BusinessRulesSelectionPopup: React.FC<BusinessRuleSelectionPopupProps> = ({
                                                                                  isPopupOpen,
                                                                                  updatePopup,
                                                                                  updateBusinessRulesRefetching,
                                                                                  availableBusinessRules,
                                                                                  onSelectionChanged,
                                                                                  updateSavingPopup
                                                                                }) => {

  const [selectedBusinessRule, setSelectedBusinessRule] = useState<IConfigAvailableBusinessRules>()

  const handleBusinessRuleSelection = (selectedBusinessRule: IAvailableBusinessRules[]) => {
    setTimeout(() => {
      setSelectedBusinessRule(selectedBusinessRule[0])
    })
  }

  const StackContainerStyles: IStackStyles = {
    root: {
      position: 'fixed',
      left: '50%',
      width: '70%',
      translate: '-50% -50%',
      top: '50%',
      backgroundColor: '#fff',
      opacity: 10,
      zIndex: 20,
      padding: 20,
      height: '80%',
      maxHeight: '600px'
    }
  }

  const columns: IColumn[] = useMemo(() =>
      [
        {
          key: getPropertyName<IAvailableBusinessRules>('codeNaam'),
          name: 'Code',
          fieldName: getPropertyName<IAvailableBusinessRules>('codeNaam'),
          minWidth: 30,
          maxWidth: 40,
          isResizable: true,
          data: 'string'
        },
        {
          key: getPropertyName<IAvailableBusinessRules>('naam'),
          name: 'Business rule',
          fieldName: getPropertyName<IAvailableBusinessRules>('naam'),
          minWidth: 100,
          maxWidth: 200,
          isMultiline: true,
          isResizable: true,
          data: 'string'
        },
        {
          key: getPropertyName<IAvailableBusinessRules>('sectie'),
          name: 'Sectie',
          fieldName: getPropertyName<IAvailableBusinessRules>('sectie'),
          minWidth: 50,
          maxWidth: 60,
          isResizable: true,
          data: 'string'
        },
        {
          key: getPropertyName<IAvailableBusinessRules>('toelichting'),
          name: 'Toelichting',
          fieldName: getPropertyName<IAvailableBusinessRules>('toelichting'),
          minWidth: 60,
          maxWidth: 80,
          isMultiline: true,
          isResizable: true,
          data: 'string'
        }
      ],
    [])

  const handlePopupClose = () => {
    updatePopup(false)
    updateBusinessRulesRefetching(true)
  }

  const handleAddButtonClick = () => {
    onSelectionChanged(selectedBusinessRule)
    updateSavingPopup(true)
    updatePopup(false)
  }

  return (
    <>
      {isPopupOpen && (
        <>
          <Stack styles={StackContainerStyles}>
            {<SelectableFluentUiTable columns={columns} data={availableBusinessRules}
                                      onSelectionChanged={handleBusinessRuleSelection}
                                      header={'Business rule toevoegen'} />}
            <Stack horizontal styles={stackStyles}>
              <DefaultButton onClick={handlePopupClose} text="Annuleren" />
              <DefaultButton primary allowDisabledFocus disabled={!selectedBusinessRule} onClick={handleAddButtonClick}>
                Toevoegen
              </DefaultButton>
            </Stack>
          </Stack>
          <Stack styles={overlayPopupWindow} onClick={handlePopupClose} />
        </>
      )}
    </>
  )
}

export default BusinessRulesSelectionPopup
