import { IStateMachine, RootState } from '../../index';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { invokeFetch } from 'services/apiClient';
import {
    EndpointOntvangen,
    ICreateEndpointOntvangenForm,
    IUpdateEndpointOntvangenForm
} from 'interfaces/endpointOntvangen';


interface IAannemerEndpointsOntvangen extends IStateMachine {
    endpointsOntvangen: EndpointOntvangen[];
}

const initState: IAannemerEndpointsOntvangen = {
    endpointsOntvangen: [],
    status: 'idle',
    error: '',
};

const fetchAannemerEndpointsOntvangen = createAsyncThunk<EndpointOntvangen[], string, { state: RootState; }>(
    'aannemers/endpointsOntvangen/fetch',
    async (aannemerId, thunkApi) => {
        return await invokeFetch<EndpointOntvangen[]>(thunkApi, 'GET', `/aannemers/endpointsOntvangen/${aannemerId}`);
    }
);

interface ICreateEndpointOntvangenArgs {
    data: ICreateEndpointOntvangenForm;
}

const createAannemerEndpointsOntvangen = createAsyncThunk<EndpointOntvangen, ICreateEndpointOntvangenArgs, { state: RootState; }>(
    'aannemers/endpointsOntvangen/create',
    async ({ data }, thunkApi) => {
        return await invokeFetch<EndpointOntvangen>(thunkApi, 'POST', `/aannemers/endpointsOntvangen`, data)
    }
);

interface IUpdateEndpointOntvangenArgs {
    data: IUpdateEndpointOntvangenForm;
}

const updateAannemerEndpointsOntvangen = createAsyncThunk<EndpointOntvangen, IUpdateEndpointOntvangenArgs, { state: RootState; }>(
    'aannemers/endpointsOntvangen/update',
    async ({ data }, thunkApi) => {
        return await invokeFetch<EndpointOntvangen>(thunkApi, 'PUT', `/aannemers/endpointsOntvangen/${data.id}`, data)
    }
);

const deleteAannemerEndpointsOntvangen = createAsyncThunk<EndpointOntvangen, string, { state: RootState; }>(
    'aannemers/endpointsOntvangen/delete',
    async (id, thunkApi) => {
        return await invokeFetch<EndpointOntvangen>(thunkApi, 'DELETE', `/aannemers/endpointsOntvangen/${id}`)
    }
);

export const slice = createSlice({
    name: 'aannemers/endpointsOntvangen',
    initialState: initState,
    reducers: {
        resetAannemerEndpointsOntvangen: state => {
            state.status = initState.status;
            state.error = '';
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchAannemerEndpointsOntvangen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(fetchAannemerEndpointsOntvangen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsOntvangen = action.payload;
        });
        builder.addCase(fetchAannemerEndpointsOntvangen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });

        builder.addCase(createAannemerEndpointsOntvangen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(createAannemerEndpointsOntvangen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsOntvangen.push(action.payload);
        });
        builder.addCase(createAannemerEndpointsOntvangen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });

        builder.addCase(updateAannemerEndpointsOntvangen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(updateAannemerEndpointsOntvangen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsOntvangen = state.endpointsOntvangen.map(b => b.id === action.payload.id ? action.payload : b);
        });
        builder.addCase(updateAannemerEndpointsOntvangen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });


        builder.addCase(deleteAannemerEndpointsOntvangen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(deleteAannemerEndpointsOntvangen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsOntvangen = state.endpointsOntvangen.filter(b => b.id !== action.payload.id);
        });
        builder.addCase(deleteAannemerEndpointsOntvangen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });
    }
});

export const { resetAannemerEndpointsOntvangen } = slice.actions;
export { fetchAannemerEndpointsOntvangen, createAannemerEndpointsOntvangen,
    updateAannemerEndpointsOntvangen, deleteAannemerEndpointsOntvangen };
export default slice.reducer;