import React, { useEffect, useMemo, useState } from 'react';
import './Importeren.scss';
import commandBarStyles from 'styles/commandBarStyles';
import { CommandBar, DefaultButton, ICommandBarItemProps, Pivot, PivotItem } from '@fluentui/react';
import pivotStyles from 'styles/pivotStyles';
import { Start } from './Start/Start';
import { Uploaden } from './Uploaden/Uploaden';
import { Controleren } from './Controleren/Controleren';
import { Resultaat } from './Resultaat/Resultaat';
import { useHistory } from 'react-router-dom';
import { resetFileUploadImportAannemers } from 'store/actions/importAannemers/data';
import { useAppDispatch } from 'store';

enum tabs {
    Start = 'Start',
    Uploaden = 'Uploaden',
    Controleren = 'Controleren',
    Resultaat = 'Resultaat',
}

export const Importeren = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [selectedTab, setSelectedTab] = useState(tabs.Start);
    const [startFinished, setStartFinished] = useState(false);
    const [uploadFinished, setUploadFinished] = useState(false);
    const [controlerenFinished, setControlerenFinished] = useState(false);
    const [resultaatFinished, setResultaatFinished] = useState(false);
    const [uploadFile, setUploadFile] = useState<File>();

    useEffect(() => {
        // reset fileUploadImportAannemers
        dispatch(resetFileUploadImportAannemers());
    }, []);


    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'pagetitle',
                onRender: () => (
                    <div className="page-title">Importeren aannemers</div>
                )
            },
        ],
        []
    );

    const handleVorige = (): any => {
        switch (selectedTab) {
            case tabs.Start:
                break;
            case tabs.Uploaden:
                setSelectedTab(tabs.Start);
                break;
            case tabs.Controleren:
                setSelectedTab(tabs.Uploaden);
                break;
            case tabs.Resultaat:
                setSelectedTab(tabs.Controleren);
                break;
        }
    };
    const handleVolgende = (): any => {
        switch (selectedTab) {
            case tabs.Start:
                setStartFinished(true);
                setSelectedTab(tabs.Uploaden);
                break;
            case tabs.Uploaden:
                setSelectedTab(tabs.Controleren);
                break;
            case tabs.Controleren:
                setSelectedTab(tabs.Resultaat);
                break;
            case tabs.Resultaat:
                history.push(`/aannemers`);
                break;
        }
    };

    let handleVorigeDisabled = selectedTab === tabs.Start;
    let VolgendeText = selectedTab === tabs.Resultaat ? 'Sluiten' : 'Volgende';
    let handleVolgendeDisabled2 = VolgendeDisabled();

    function VolgendeDisabled(): boolean {
        switch (selectedTab) {
            case tabs.Start:
                return false;
            case tabs.Uploaden:
                return !uploadFinished;
            case tabs.Controleren:
                return !controlerenFinished;
            case tabs.Resultaat:
                return !resultaatFinished;
        }
        return false;
    }


    const handleUploadenFinished = (resultaat: boolean): any => {
        setResultaatFinished(false);
        setControlerenFinished(false);
        setUploadFinished(resultaat);
    };
    const handleControlerenFinished = (resultaat: boolean): any => {
        setResultaatFinished(false);
        setControlerenFinished(resultaat);
    };
    const handleResultaatFinished = (resultaat: boolean): any => {
        setResultaatFinished(resultaat);
    };

    function onLinkClick(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>): any {
        switch (item?.props?.itemKey) {
            case tabs.Start:
                setSelectedTab(tabs.Start);
                break;
            case tabs.Uploaden:
                if (startFinished) {
                    setSelectedTab(tabs.Uploaden);
                }
                break;
            case tabs.Controleren:
                if (uploadFinished) {
                    setSelectedTab(tabs.Controleren);
                }
                break;
            case tabs.Resultaat:
                if (controlerenFinished) {
                    setSelectedTab(tabs.Resultaat);
                }
                break;
        }

    }

    return (
        <div className="importeren">
            <CommandBar items={commandBarItems} styles={commandBarStyles}/>
            <div className="importeren-content">
                <Pivot styles={pivotStyles} headersOnly={true} selectedKey={selectedTab} onLinkClick={onLinkClick}>
                    <PivotItem headerText="Start" itemKey={tabs.Start} itemIcon={startFinished ? "SkypeCheck" : ""}/>
                    <PivotItem headerText="Uploaden" itemKey={tabs.Uploaden}
                               itemIcon={uploadFinished ? "SkypeCheck" : ""}/>
                    <PivotItem headerText="Controleren" itemKey={tabs.Controleren}
                               itemIcon={controlerenFinished ? "SkypeCheck" : ""}/>
                    <PivotItem headerText="Resultaat" itemKey={tabs.Resultaat}
                               itemIcon={resultaatFinished ? "SkypeCheck" : ""}/>
                </Pivot>
                <div className="importeren-content-tabs">
                    {selectedTab === tabs.Start && <Start></Start>}
                    {selectedTab === tabs.Uploaden &&
                        <Uploaden uploadFile={uploadFile} onUploadFileChanged={setUploadFile}
                                  onFinished={handleUploadenFinished}></Uploaden>}
                    {selectedTab === tabs.Controleren &&
                        <Controleren onFinished={handleControlerenFinished}></Controleren>}
                    {selectedTab === tabs.Resultaat && <Resultaat onFinished={handleResultaatFinished}></Resultaat>}
                </div>
                <div className="importeren-content-navigation">
                    <div className="importeren-content-navigation-left">
                        <DefaultButton text="Vorige" disabled={handleVorigeDisabled} onClick={handleVorige}/>
                        <DefaultButton text={VolgendeText} primary={true} disabled={handleVolgendeDisabled2}
                                   onClick={handleVolgende}/>
                    </div>
                    <div className="importeren-content-navigation-right">
                        <DefaultButton text="Annuleren" onClick={() => history.push(`/aannemers`)}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
