import * as y from 'yup'
import * as m from 'lib/constants/validationMessages'
import { OpdrachtSoort } from 'enums/opdrachtSoort'
import { WerkwijzeOptie } from 'enums/werkwijzeOptie'

export interface IWerkwijze {
  id: number
  opdrachtsoort: OpdrachtSoort
  werkwijze: WerkwijzeOptie
  aannemerId: number
  endpointBezorgingId?: number
}

export interface ICreateWerkwijzeForm {
  opdrachtsoort?: OpdrachtSoort
  werkwijze?: WerkwijzeOptie
  endpointBezorgingId?: number
}

export const createWerkwijzeSchema = y.object({
  opdrachtsoort: y.string().required(m.REQUIRED),
  werkwijze: y.string().required(m.REQUIRED),
  endpointBezorgingId: y.number().when('werkwijze', (werkwijze: WerkwijzeOptie, schema) => {
    if (werkwijze === WerkwijzeOptie.DICO) {
      return schema.required(m.REQUIRED)
    }
  }),
});

export interface IUpdateWerkwijzeForm {
  werkwijze: WerkwijzeOptie
  endpointBezorgingId?: number
}

export const updateWerkwijzeSchema = y.object({
  werkwijze: y.string().required(m.REQUIRED),
  endpointBezorgingId: y.number().when('werkwijze', (werkwijze: WerkwijzeOptie, schema) => {
    if (werkwijze === WerkwijzeOptie.DICO) {
      return schema.required(m.REQUIRED)
    }
  }),
});
