import React, { useMemo } from 'react'
import {
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  Spinner,
  SpinnerSize,
  Text,
} from '@fluentui/react'

interface IConfirmDialogProps {
  title: string
  subText?: string[]
  onCancel: () => void
  onOk: () => void
  loading?: boolean
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({ title, subText = [], onCancel, onOk, loading = false }) => {
  const modalProps = useMemo(
    (): IModalProps => ({
      isBlocking: true,
    }),
    []
  )

  const dialogContentProps = useMemo(
    (): IDialogContentProps => ({
      type: DialogType.normal,
      title: title,
    }),
    [title]
  )

  return (
    <Dialog
      hidden={false}
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      minWidth={500}
    >
      <DialogContent styles={{ header: { height: 0, width: 0 }, inner: { padding: 0 } }}>
        {subText.map((line, index) => (
          <div key={index}>
            <Text>{line}</Text>
          </div>
        ))}
      </DialogContent>
      <DialogFooter>
        <DefaultButton primary allowDisabledFocus disabled={loading} onClick={onOk}>
          {loading ? <Spinner size={SpinnerSize.small} /> : 'Ok'}
        </DefaultButton>
        <DefaultButton disabled={loading} onClick={onCancel} text="Annuleren" />
      </DialogFooter>
    </Dialog>
  )
}

export default ConfirmDialog
