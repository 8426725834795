import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    Icon,
    ITextFieldStyles,
    Link,
    Pivot,
    PivotItem,
    TextField
} from '@fluentui/react'
import './index.scss'
import { useAppDispatch, useTypedSelector } from 'store'
import commandBarStyles from 'styles/commandBarStyles'
import { fetchAannemers } from 'store/actions/aannemers/data'
import { IAannemer } from 'interfaces/aannemer'
import { getPropertyName } from 'lib/interfaceUtils'
import BooleanColumn from 'components/BooleanColumn'
import { getAannemers } from 'store/selectors/aannemers'
import Add from './Add'
import OhkTable from 'components/OhkTable'
import pivotStyles from 'styles/pivotStyles'
import { GlnCodeType } from 'interfaces/aannemerGlnType'

enum tabs {
    KetenStandaardGln = 'GLN-code Ketenstandaard',
    OnderhoudsketenGln = 'GLN-code Onderhoudsketen'
}

const SearchTextFieldStyles: Partial<ITextFieldStyles> = {
    root: { padding: 5, paddingLeft: 10, paddingRight: 10, width: '400px'},
}

const Aannemers: React.FC<{}> = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [addItem, setAddItem] = useState(false);
    const [addOnderhoudsketenAannemer, setAddOnderhoudsketenAannemer] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [selectedTab, setSelectedTab] = useState<tabs>(tabs.KetenStandaardGln);
    const {list: items, status: fetchStatus} = useTypedSelector(getAannemers);
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        const fetchPromise = dispatch(fetchAannemers());

        return () => {
            fetchPromise.abort();
        };
    }, [dispatch]);

    useEffect(() => {
        if (refetch) {
            dispatch(fetchAannemers());
            setRefetch(false);
        }
    }, [dispatch, refetch]);

    const handleGetKey = (item): string => {
        return (item as IAannemer).id.toString();
    };

    const handleItemInvoked = useCallback(
        (id: string) => {
            history.push(`/aannemers/${id}`);
        },
        [history]
    );

    const handleColumnClick = () => null;

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: getPropertyName<IAannemer>('naam'),
                name: 'Naam',
                fieldName: getPropertyName<IAannemer>('naam'),
                minWidth: 100,
                maxWidth: 400,
                isResizable: true,
                isRowHeader: true,
                data: 'string',
                isSorted: true,
                isSortedDescending: false,
                onRender: (item: IAannemer) => (
                    <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
                        {item.naam}
                    </Link>
                ),
            },
            {
                key: getPropertyName<IAannemer>('gln'),
                name: 'GLN-code',
                fieldName: getPropertyName<IAannemer>('gln'),
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                data: 'string'
            },
            {
                key: getPropertyName<IAannemer>('glnCodeType'),
                name: 'GLN-Code Type',
                fieldName: getPropertyName<IAannemer>('glnCodeType'),
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                data: 'string'
            },
            {
                key: getPropertyName<IAannemer>('referentie'),
                name: 'Externe referentie',
                fieldName: getPropertyName<IAannemer>('referentie'),
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                data: 'string'
            },            
            {
                key: getPropertyName<IAannemer>('opdrachtEmailAdres'),
                name: 'Opdracht email-adres',
                fieldName: getPropertyName<IAannemer>('opdrachtEmailAdres'),
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                data: 'string'
            },
            {
                key: getPropertyName<IAannemer>('geblokkeerd'),
                name: 'Geblokkeerd',
                fieldName: getPropertyName<IAannemer>('geblokkeerd'),
                minWidth: 60,
                maxWidth: 60,
                isResizable: true,
                data: 'boolean',
                onRender: (item: IAannemer) => <BooleanColumn value={item.geblokkeerd}/>,
            },
        ],
        [handleColumnClick]
    );


    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'pagetitle',
                onRender: () => (
                    <div className="aannemers-title page-title">Aannemers</div>
                )
            },
        ],
        []
    );

    const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'import',
                text: 'Importeren',
                iconProps: {iconName: 'Upload', className: 'greenIcon'},
                ariaLabel: 'Importeren',
                onClick: () => history.push(`/aannemers/importeren`)
            },
            {
                key: 'newItem',
                text: 'Nieuw',
                iconProps: {iconName: 'Add', className: 'greenIcon'},
                split: false,
                ariaLabel: 'Nieuw',
                onClick: () => {
                    selectedTab === tabs.KetenStandaardGln
                        ? setAddItem(true)
                        : setAddOnderhoudsketenAannemer(true);
                },
                disabled: (selectedTab !== tabs.KetenStandaardGln)
            },
            {
                key: 'refresh',
                text: 'Vernieuwen',
                iconProps: {iconName: 'Refresh', className: 'icon'},
                split: false,
                ariaLabel: 'Vernieuwen',
                onClick: () => setRefetch(true),
            },
        ],
        [tabs, selectedTab, history]
    );

    const handleSearchChange = (e, value) => {
        setSearchTerm(value || '');
    };

    const filteredItems = useMemo(() => {
        if (!searchTerm) return items;

        return items.filter((item: IAannemer) => {
            const searchTextLower = searchTerm.toLowerCase();
            return (
              item.naam.toLowerCase().includes(searchTextLower) ||
              item.gln?.toLowerCase().includes(searchTextLower) ||
              item.referentie?.toLowerCase().includes(searchTextLower) ||
              item.opdrachtEmailAdres?.toLowerCase().includes(searchTextLower)
            );
        });
    }, [items, searchTerm]);

    const handleLinkClickPivot = (item?: PivotItem) => {
        if (item) {
            setSelectedTab(item.props.itemKey as tabs);
            setRefetch(true)
        }
    };

    const ketenStandaardGlnItems = useMemo(() => {
        return filteredItems.filter(item => item.glnCodeType === GlnCodeType.Ketenstandaard);
    }, [filteredItems]);

    const onderhoudsketenGlnItems = useMemo(() => {
        return filteredItems.filter(item => item.glnCodeType === GlnCodeType.Onderhoudsketen);
    }, [filteredItems])

    return (
        <div className="aannemersWrapper flex-container flex-column flex-grow">
            <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles}/>
            <div className="aannemers flex-container flex-column flex-grow">
                <Pivot styles={pivotStyles} headersOnly={true} selectedKey={selectedTab}
                       onLinkClick={handleLinkClickPivot}>
                    <PivotItem headerText={tabs.KetenStandaardGln} itemKey={tabs.KetenStandaardGln}/>
                    <PivotItem headerText={tabs.OnderhoudsketenGln} itemKey={tabs.OnderhoudsketenGln}/>
                </Pivot>
                <TextField
                  value={searchTerm}
                  onChange={handleSearchChange}
                  styles={SearchTextFieldStyles}
                  onRenderSuffix={() => (
                    <>
                        {(!searchTerm
                          ? (<Icon iconName='Search'/>)
                          : (<Icon iconName='Clear' onClick={() => setSearchTerm('')} style={{ cursor: 'pointer'}} />))}
                    </>
                  )}
                />
                {selectedTab === tabs.KetenStandaardGln && (
                    <div className="aannemers-tab-ketenstandaard flex-container flex-column flex-grow">
                        {addItem && <Add onCancel={() => setAddItem(false)}/>}
                        <OhkTable
                            disableSort={false}
                            columns={columns}
                            items={ketenStandaardGlnItems}
                            loading={fetchStatus === 'pending'}
                            onGetKey={handleGetKey}
                            onItemInvoked={handleItemInvoked}
                        />
                    </div>
                )}
                {selectedTab === tabs.OnderhoudsketenGln && (
                    <div className="aannemers-tab-onderhoudsketen flex-container flex-column flex-grow">
                        {addOnderhoudsketenAannemer &&
                            <div onClick={() => setAddOnderhoudsketenAannemer(false)}>Toevoegen ohk aannemer</div>}
                        <OhkTable
                            disableSort={false}
                            columns={columns}
                            items={onderhoudsketenGlnItems}
                            loading={fetchStatus === 'pending'}
                            onGetKey={handleGetKey}
                            onItemInvoked={handleItemInvoked}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export default Aannemers;
