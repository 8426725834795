import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback } from 'react';
import { EndpointBezorgen, endpointBezorgenSchema, ICreateEndpointBezorgenForm } from 'interfaces/endpointBezorgen';
import { getPropertyName } from 'lib/interfaceUtils';
import { DefaultButton, MessageBar, MessageBarType, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { GetEndpointProtocolOptions } from '../Common/EndpointHelper';
import { EndpointProtocolType } from 'interfaces/endpointProtocolType';
import '../Common/EndpointProtocolStyle.scss'

interface IProps {
    onClose: () => void;
    onAddItemSave: (item: EndpointBezorgen) => void;
    error?: string;
    status?: string;
}


export const AddEndpointBezorgen = ({onClose, onAddItemSave, error, status}: IProps) => {

    const {handleSubmit, control, errors: formErrors, formState} = useForm<ICreateEndpointBezorgenForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(endpointBezorgenSchema),
        defaultValues: {naam: '', protocol: 1,  endpointUrl: '', gebruikersnaam: '', wachtwoord: ''},
    });

    const handleSave = () => {
        handleSubmit(onSave)();
    };

    const onSave = useCallback(
        (data: EndpointBezorgen) => {
            onAddItemSave(data);
        },
        []
    );

    return (
        <div style={headerStyles}>
            {error && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {error}
                </MessageBar>
            )}
            <form onSubmit={handleSubmit(onSave)}>
                <Controller
                    name={getPropertyName<EndpointBezorgen>('naam')}
                    control={control}
                    render={({onChange, onBlur, value}) => (
                        <TextField
                            autoComplete="off"
                            autoFocus={true}
                            required={true}
                            label="Naam"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            errorMessage={formErrors.naam?.message}
                        />
                    )}
                />
                <Controller
                    name={getPropertyName<EndpointBezorgen>('protocol')}
                    control={control}
                    render={({onChange, onBlur, value}) => (
                        <ChoiceGroup
                            defaultSelectedKey={EndpointProtocolType.SOAP.toString()}
                            options={GetEndpointProtocolOptions}
                            required={true}
                            onChange={(ev, option) => onChange(parseInt(option?.key ?? '0'))}
                            label="Protocol"
                            className='endpoint-protocol'
                        />
                    )}
                />
                <Controller
                    name={getPropertyName<EndpointBezorgen>('endpointUrl')}
                    control={control}
                    render={({onChange, onBlur, value}) => (
                        <TextField
                            autoComplete="off"
                            required={true}
                            label="Endpoint URL"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            errorMessage={formErrors.endpointUrl?.message}
                        />
                    )}
                />
                <Controller
                    name={getPropertyName<EndpointBezorgen>('gebruikersnaam')}
                    control={control}
                    render={({onChange, onBlur, value}) => (
                        <TextField
                            autoComplete="off"
                            required={true}
                            label="Gebruikersnaam"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            errorMessage={formErrors.gebruikersnaam?.message}
                        />
                    )}
                />
                <Controller
                    name={getPropertyName<EndpointBezorgen>('wachtwoord')}
                    control={control}
                    render={({onChange, onBlur, value}) => (
                        <TextField
                            autoComplete="off"
                            required={true}
                            label="Wachtwoord"
                            type="password"
                            canRevealPassword
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            errorMessage={formErrors.wachtwoord?.message}
                        />
                    )}
                />
                <input type="submit" style={{visibility: 'hidden'}}/>
            </form>
            <div className="add-endpoints-bezorgen-actions flex-container flex-gap-16"
                 style={{justifyContent: 'space-between'}}>
                <div className="action-left">
                </div>
                <div className="action-right flex-container flex-gap-16">
                    <DefaultButton onClick={onClose} text="Annuleren" disabled={status === 'pending'}/>
                    <DefaultButton primary allowDisabledFocus disabled={!formState.isValid} onClick={handleSave}>
                        {status === 'pending' ? <Spinner size={SpinnerSize.small}/> : 'Opslaan'}
                    </DefaultButton>

                </div>
            </div>
        </div>
    );
};

const headerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    width: '100%',
    borderBottom: '1px solid #efefef',
    boxShadow: '#efefef 0px 0px 9px -1px',
};

