import moment from 'moment';

export class DateHelper {

    public static formDateToUTCIgnoringTimezoneWithoutTime(localDate: Date): Date | null {
        if (localDate) {
            const date = moment(localDate);
            const utcDate = Date.UTC(
                date.year(),
                date.month(),
                date.date()
            );

            return new Date(utcDate);
        }

        return null;
    }
    
    public static todayAsUtcDateWithoutTime(): Date {
        return DateHelper.formDateToUTCIgnoringTimezoneWithoutTime(new Date()) as Date;
    }
}

