import React, { useEffect } from 'react';
import './Resultaat.scss';
import { MessageBar, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import { useAppDispatch, useTypedSelector } from 'store';
import { getImportAannemers } from 'store/selectors/importAannemersUploadFile';
import { importAannemers } from 'store/actions/importAannemers/data';
import { ResultaatData } from './ResultaatData/ResultaatData';


interface IProps {
    onFinished: (resultaat: boolean) => void;
}

export const Resultaat = ({onFinished} : IProps) => {
    const dispatch = useAppDispatch();
    const {importResult, importFileRows, status, error} = useTypedSelector(getImportAannemers);

    useEffect(() => {
        if (!!importResult) {
            return;
        }
        dispatch(importAannemers(importFileRows)).then();
    }, []);

    useEffect(() => {
        if (status === 'succeeded' && importResult && importResult.success) {
            onFinished(true);
        } else {
            onFinished(false);
        }
    }, [importResult, status]);
    
    return (
        <div className="resultaat">
            {status === 'pending' && <div className="resultaat-loading">
                Bezig met importeren...
                <Spinner size={SpinnerSize.large}/>
            </div>}
            {status === 'failed' && <div className="resultaat-error">
                <MessageBar messageBarType={MessageBarType.error}>
                    {error}
                </MessageBar>
            </div>}
            {status === 'succeeded' && importResult !== undefined &&
                <>
                    <div className="resultaat-title title">Resultaat import</div>
                    {!importResult.success &&
                        <div className="resultaat-failed">
                            <div className="resultaat-failed-title title">Importbestand niet succesvol geïmporteerd</div>
                            <MessageBar messageBarType={MessageBarType.error}>{importResult.message}</MessageBar>
                        </div>
                    }
                    {importResult.success &&
                        <div className="resultaat-success">
                            <div className="resultaat-success-title title">Importbestand succesvol geïmporteerd</div>
                            <MessageBar messageBarType={MessageBarType.success}>{importResult.message}</MessageBar>
                        </div>
                    }
                    {importResult?.aannemers.length > 0 && 
                        <div className="resultaat-aannemers">
                            <div className="resultaat-aannemers-title title">Aannemers geïmporteerd</div>
                            <ResultaatData aannemers={importResult.aannemers} />
                        </div>
                    }
                </>
            }
        </div>
    )
};
