import React from 'react'
import { Controller } from 'react-hook-form'
import { IIconProps, ITextFieldStyles, TextField } from '@fluentui/react'

interface IOhkFieldProps {
  name: string
  label: string
  control: any
  errors: any
  required?: boolean
  readOnly?: boolean
  iconProps?: IIconProps
  defaultValue?: string
  height?: string | number
  labelWidth?: number
  multiline?: boolean
  autoAdjustHeight?: boolean
  disabled?: boolean
  showIf?: boolean
  placeholder?: string
  styles?: Partial<ITextFieldStyles>
}

const OhkTextField: React.FC<IOhkFieldProps> = props => {
  const labelWidth = props.labelWidth ? props.labelWidth : 215

  const textFieldStyles: Partial<ITextFieldStyles> = {
    wrapper: {
      height: props.height,
      borderRadius: '5px',
      border: '1px solid black'
    },
    subComponentStyles: {
      label: {
        root: { width: labelWidth, fontWeight: 'normal' },
      },
    },
    field: {
      fontWeight: 600,
    },
    suffix: {
      background: '',
    },
  }

  return (
    <div hidden={props.showIf !== undefined && !props.showIf}>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.defaultValue}
        render={({ onChange, value }) => (
          <TextField
            disabled={props.disabled}
            autoComplete="off"
            name={props.name}
            onChange={onChange}
            value={value ??  ''}
            iconProps={props.iconProps}
            styles={props.styles ?? textFieldStyles}
            label={props.label}
            borderless
            underlined
            required={props.required}
            readOnly={props.readOnly}
            placeholder={props.placeholder ?? "----"}
            errorMessage={props.errors[props.name]?.message}
            multiline={props.multiline}
            autoAdjustHeight={props.autoAdjustHeight}
          />
        )}
      />
    </div>
  )
}

export default OhkTextField
