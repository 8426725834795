import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CommandBar, ICommandBarItemProps, ResponsiveMode, Spinner, SpinnerSize } from '@fluentui/react';
import commandBarStyles from 'styles/commandBarStyles';
import './AfhandelenNietBezorgd.scss';
import BerichtBezorgingenTable from '../BerichtBezorgingen/BerichtbezorgingenTable/BerichtbezorgingenTable';
import { peekResult, useAppDispatch, useTypedSelector } from 'store';
import { getBerichtBezorgingen, getUpdateHandmatigAfhandelen } from 'store/selectors/berichtbezorgingen';
import { fetchBerichtbezorging } from 'store/actions/berichtbezorging/data';
import { Berichtstatus } from 'enums/berichtStatus';
import HandmatigAfhandelenDialog from './HandmatigAfhandelen/HandmatigAfhandelenDialog';
import { useHistory } from 'react-router-dom';
import { AfhandelenHandmatigRequest, AfhandelenOpnieuwBezorgenRequest } from 'interfaces/berichtbezorging';
import {
    afhandelenUpdatingSetPending,
    resetAfhandelenUpdating,
    updateHandmatigAfhandelen,
    updateOpnieuwBezorgen, updateOpnieuwBezorgenPerEmail
} from 'store/actions/berichtbezorging/afhandelen';
import { EndpointInfoRequest } from 'interfaces/EndpointInfoRequest';
import { EndpointType } from 'enums/endpointType';
import EndpointInformation from 'components/EndpointInformation/EndpointInformation';
import OhkModal from 'components/OhkModal';

const AfhandelenNietBezorgd = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    
    const [refetch, setRefetch] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const { list: items, status: fetchStatus } = useTypedSelector(getBerichtBezorgingen);
    const {status: updateStatus} = useTypedSelector(getUpdateHandmatigAfhandelen);
    // Afhandelen acties
    const [handmatigAfhandelenShowDialog, setHandmatigAfhandelenShowDialog] = useState(false);
    const [EndpointInfoRequest, setEndpointInfoRequest] = useState<EndpointInfoRequest>();
    const [spinnerText, setSpinnerText] = useState('Bezig met afhandelen, even geduld a.u.b. ...');
    

    useEffect(() => {
        setSpinnerText('Bezig met laden gegevens, even geduld a.u.b. ...')
        dispatch(resetAfhandelenUpdating());
        const fetchPromise = dispatch(fetchBerichtbezorging(Berichtstatus.NietBezorgd));
        return () => {
            fetchPromise.abort()
        }
    }, [dispatch]);

    useEffect(() => {
        if (refetch) {
            setSpinnerText('Bezig met laden gegevens, even geduld a.u.b. ...')
            dispatch(resetAfhandelenUpdating());
            dispatch(fetchBerichtbezorging(Berichtstatus.NietBezorgd));
            setRefetch(false)
        }
    }, [dispatch, refetch]);

    const handleHandmatigAfhandelen = useCallback((toelichting: string) => {
        setSpinnerText('Bezig met handmatig afhandelen, even geduld a.u.b. ...');
        dispatch(afhandelenUpdatingSetPending());
        if (selectedKeys.length > 0) {
            const selectedItems = items.filter(item => selectedKeys.includes(item.key));
            const bezorgingIds = selectedItems.map(item => item.bezorgingId);

            const request = {
                bezorgingIds,
                toelichting
            } as AfhandelenHandmatigRequest;
            
            dispatch(updateHandmatigAfhandelen({request}))
                .then(peekResult)
                .then(success => {
                    setRefetch(true);
                });

            setHandmatigAfhandelenShowDialog(false);
        }
    }, [dispatch, history, selectedKeys]);    
    
    
    function getSelectedCount() {
        return selectedKeys.length;
    }
    
    function onOpnieuwBezorgen(ids: string[]) {
        setSpinnerText('Bezig met opnieuw bezorgen, even geduld a.u.b. ...');
        dispatch(afhandelenUpdatingSetPending());
        if (selectedKeys.length > 0) {
            const selectedItems = items.filter(item => selectedKeys.includes(item.key));
            const bezorgingIds = selectedItems.map(item => item.bezorgingId);
            const request = {
                bezorgingIds
            } as AfhandelenOpnieuwBezorgenRequest;
            dispatch(updateOpnieuwBezorgen({request}))
                .then(peekResult)
                .then(success => {
                    setRefetch(true);
                });
            
        }
    }

    function onOpnieuwPerEmailBezorgen(ids: string[]) {
        setSpinnerText('Bezig met email versturen en afhandelen, even geduld a.u.b. ...');
        dispatch(afhandelenUpdatingSetPending());
        if (selectedKeys.length > 0) {
            const selectedItems = items.filter(item => selectedKeys.includes(item.key));
            const bezorgingIds = selectedItems.map(item => item.bezorgingId);
            const request = {
                bezorgingIds
            } as AfhandelenOpnieuwBezorgenRequest;
            dispatch(updateOpnieuwBezorgenPerEmail({request}))
                .then(peekResult)
                .then(success => {
                    setRefetch(true);
                });

        }
    }
    
    function onHandmatigAfhandelen(ids: string[]) {
        setHandmatigAfhandelenShowDialog(true);
    }

    function onSelectionChanged(keys: string[]) {
        setSelectedKeys(keys);
    }

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'pagetitle',
                onRender: () => (
                    <span className="page-title">Afhandelen niet bezorgde berichten</span>
                )
            },
        ],
        []
    );    
    
    const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
        () => {
            let items: ICommandBarItemProps[] = [];
            items.push({
                key: 'selectCound',
                text: `${getSelectedCount()} geselecteerd`,
                disabled: true,
            });
            items.push(
                {
                    key: 'opnieuwBezorgen',
                    text: 'Opnieuw bezorgen',
                    iconProps: {iconName: 'Send', className: 'icon'},
                    split: false,
                    ariaLabel: 'Opnieuw bezorgen',
                    disabled: getSelectedCount() === 0,
                    onClick: () => onOpnieuwBezorgen(selectedKeys),
                }
            );
            items.push({
                key: 'handmatigAfhandelen',
                text: 'Handmatig afhandelen',
                iconProps: {iconName: 'EditContact', className: 'icon'},
                split: false,
                ariaLabel: 'Handmatig afhandelen',
                disabled: getSelectedCount() === 0,
                onClick: () => onHandmatigAfhandelen(selectedKeys),
            });
            items.push(
                {
                    key: 'opnieuwPerEmailBezorgen',
                    text: 'Email versturen en afhandelen',
                    iconProps: {iconName: 'MailForward', className: 'icon'},
                    split: false,
                    ariaLabel: 'Email versturen en afhandelen',
                    disabled: getSelectedCount() === 0,
                    onClick: () => onOpnieuwPerEmailBezorgen(selectedKeys),
                }
            );
            items.push({
                key: 'refresh',
                text: 'Vernieuwen',
                iconProps: { iconName: 'Refresh', className: 'icon' },
                split: false,
                ariaLabel: 'Vernieuwen',
                onClick: () => setRefetch(true),
            });
            
            return items;
        },
        [getSelectedCount]
    );

    function onEndpointVanClicked(id: number): any {
        setEndpointInfoRequest({endpointType: EndpointType.Ontvangst, endpointId: id});
    }

    function onEndpointNaarClicked(id: number): any {
        setEndpointInfoRequest({endpointType: EndpointType.Bezorging, endpointId: id});
    }

    return (
        <div className="afhandelen flex-grow flex-container flex-column">
            {updateStatus === 'pending' 
                ? (<div className="afhandelen-spinner"><Spinner size={SpinnerSize.large} label={spinnerText} /></div>)
            : (
                <div className="afhandelen-content flex-grow flex-container flex-column" >
                    <div className="afhandelen-content-action"><CommandBar items={commandBarItems} farItems={commandBarItemsFar}
                                                                            styles={commandBarStyles}/></div>
                    <div className="afhandelen-content-table flex-grow">
                        <BerichtBezorgingenTable items={items}
                                                 fetchStatus={fetchStatus}
                                                 statusFilter={Berichtstatus.Geen}
                                                 onSelectionChanged={onSelectionChanged}
                                                 onVanClicked={onEndpointVanClicked}
                                                 onNaarClicked={onEndpointNaarClicked}
                        />
                    </div>
                </div>
                )}
            {handmatigAfhandelenShowDialog && (
                <HandmatigAfhandelenDialog
                    title="Handmatig afhandelen"
                    subText={[
                        `Weet u zeker dat u de geselecteerde berichten wilt markeren als handmatige afgehandeld?`,
                        `Er zijn ${getSelectedCount()} berichten geselecteerd.`,
                    ]}
                    onCancel={() => setHandmatigAfhandelenShowDialog(false)}
                    onOk={handleHandmatigAfhandelen}
                />
            )}
            {EndpointInfoRequest &&
                <OhkModal  title="Endpoint informatie"
                           isOpen={!!EndpointInfoRequest}
                           onClose={() => setEndpointInfoRequest(undefined)}
                           onDismiss={() => setEndpointInfoRequest(undefined)}
                           responsiveMode={ResponsiveMode.large}>
                    <EndpointInformation endpointInfoRequest={EndpointInfoRequest}></EndpointInformation>
                </OhkModal>
            }
        </div>
    )
};

export default AfhandelenNietBezorgd