import { IStateMachine, RootState } from '../../index';
import { IMutatielogItem } from '../../../interfaces/mutatielogItem';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { invokeFetch } from '../../../services/apiClient';

interface IMutatielogItemState extends  IStateMachine {
    item?: IMutatielogItem
}

const initState: IMutatielogItemState = {
    item: undefined,
    status: 'idle',
    error: ''
};

const fetchMutatielogItem = createAsyncThunk<IMutatielogItem, string, { state: RootState }>(
    'mutatielog/item',
    async (id, thunkApi) => {
        return await invokeFetch<IMutatielogItem>(thunkApi, 'GET', `/mutatielog/${id}`)
    }
);

export const slice = createSlice({
    name: 'mutatielogitem/item',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchMutatielogItem.pending, (state, action) => {
            state.status = 'pending';
            state.item = undefined;
            state.error = ''
        });
        builder.addCase(fetchMutatielogItem.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.item = action.payload
        });
        builder.addCase(fetchMutatielogItem.rejected, (state, action) => {
            state.status = 'failed';
            state.item = undefined;    
            state.error = action.error.message || ''
        })
    }
});

export {fetchMutatielogItem}

export default slice.reducer