import { RootState } from '../index'

const getAannemers = (state: RootState) => state.aannemers.data;
const getAannemer = (state: RootState, id: number) => state.aannemers.data.list.find(b => b.id === id);
const getUpsertAannemer = (state: RootState) => state.aannemers.upsert;
const getAannemerEndpointsBezorgen = (state: RootState) => state.aannemers.endpointsBezorgen;
const getAannemerEndpointsOntvangen = (state: RootState) => state.aannemers.endpointsOntvangen;


export { getAannemers, getAannemer, getUpsertAannemer, getAannemerEndpointsBezorgen, getAannemerEndpointsOntvangen };
