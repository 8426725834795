import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { useIsAuthenticated } from '@azure/msal-react';

import Main from './Main';
import NotFound from './components/NotFound';
import Login from './containers/Login';
import Callback from './containers/Callback';
import Aannemers from './containers/pages/Aannemers';
import Aannemer from 'containers/pages/Aannemers/Aannemer';
import Instellingen from 'containers/pages/Instelllingen';
import Dashboard from 'containers/pages/Dashboard';
import BerichtBezorgingen from './containers/pages/BerichtBezorgingen/BerichtBezorgingen';
import AfhandelenNietBezorgd from './containers/pages/AfhandelenNietBezorgd/AfhandelenNietBezorgd';
import Mutatielog from './containers/pages/Mutatielog/Mutatielog';
import MutatieLogItem from './containers/pages/Mutatielog/MutatielogItem/MutatieLogItem';
import { Importeren } from './containers/pages/Aannemers/Importeren/Importeren';
import BusinessRules from './containers/pages/BusinessRules';

const AppRouter = () => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <Router>
            <Switch>
                <Route path="/login" render={() => (isAuthenticated ? <Redirect to="/callback"/> : <Login/>)}/>
                <Route path="/callback" component={Callback}/>
                <Route
                    render={() =>
                        isAuthenticated ? (
                            <Main>
                                <Switch>
                                    <Route path="/" exact render={() => <Redirect to="/dashboard"/>}/>
                                    <Route path="/corporatie" exact component={Instellingen}/>
                                    <Route path="/aannemers" exact component={Aannemers}/>
                                    <Route path="/aannemers/importeren" exact component={Importeren}/>
                                    <Route path="/aannemers/:aannemerId" exact component={Aannemer}/>
                                    <Route path="/berichten/:status" exact component={BerichtBezorgingen}/>
                                    <Route path="/berichten/" component={BerichtBezorgingen}/>
                                    <Route path="/businessrules/" component={BusinessRules}/>
                                    <Route path="/dashboard" exact component={Dashboard}/>
                                    <Route path="/afhandelen/nietbezorgd" exact component={AfhandelenNietBezorgd}/>
                                    <Route path="/mutatielog" exact component={Mutatielog}/>
                                    <Route path="/mutatielog/:mutatielogId" exact component={MutatieLogItem}/>
                                    <Route component={NotFound}/>
                                </Switch>
                            </Main>
                        ) : (
                            <Redirect to="/login"/>
                        )
                    }
                />
            </Switch>
        </Router>
    )
};

export default AppRouter
