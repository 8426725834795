import React, { useEffect, useState } from 'react';
import './EndpointInformation.scss';
import { EndpointInfoRequest } from 'interfaces/EndpointInfoRequest';
import { EndpointInfo } from 'interfaces/EndpointInfo';
import { MsalClient } from 'services/MsalClient';
import { store } from 'store';
import { EndpointType } from 'enums/endpointType';
import { useHistory } from 'react-router-dom';
import { Link } from '@fluentui/react';

interface IProps {
    endpointInfoRequest?: EndpointInfoRequest
}

const EndpointInformation = ({endpointInfoRequest} : IProps) => {
    const [endpointInfo, setEndpointInfo] = useState<EndpointInfo>();
    const history = useHistory();
    
    useEffect(() => {
        if (!endpointInfoRequest) {
            return;
        }
        const fetchData = async () => {
            const endpointsuffix = endpointInfoRequest?.endpointType === EndpointType.Ontvangst ? 'van' : 'naar';
            const apiEndpoint = store.getState().app.configuration.apiEndpoint;
            const endpoint = `${apiEndpoint}/berichtbezorgingen/endpointinfo/${endpointsuffix}/${endpointInfoRequest?.endpointId}`;
            const token = await MsalClient.getApiToken();
            const response = await fetch(endpoint, {
                method: 'Get',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            const json = await response.json();
            setEndpointInfo(json);
        };

        fetchData().catch(console.error);
    },
    [endpointInfoRequest]);
    
    return (
        <div className="endpoint-information">
            <div className="endpoint-information-header">
                
            </div>
            {!!endpointInfo && (
                <div className="endpoint-information-content">
                    <div className="endpoint-information-row">
                        <div className="endpoint-information-row-label">
                            {endpointInfo.endpointTypeDto === EndpointType.Ontvangst ? 'Van' : 'Naar'}
                        </div>
                        <div className="endpoint-information-row-value">
                            <Link onClick={event => {
                                !!endpointInfo?.vanAannemerId
                                    ? history.push(`/aannemers/${endpointInfo?.vanAannemerId}`)
                                    : history.push(`/corporatie`)
                            }}>{endpointInfo.van}</Link>
                            
                        </div>
                    </div>
                    <div className="endpoint-information-row">
                        <div className="endpoint-information-row-label">Naam Endpoint</div>
                        <div className="endpoint-information-row-value">{endpointInfo.naamEndpoint}</div>
                    </div>
                    <div className="endpoint-information-row">
                        <div className="endpoint-information-row-label">URL Endpoint</div>
                        <div className="endpoint-information-row-value">{endpointInfo.urlEndpoint}</div>
                    </div>
                    <div className="endpoint-information-row">
                        <div className="endpoint-information-row-label">Gebruikersnaam</div>
                        <div className="endpoint-information-row-value">{endpointInfo.gebruikersnaamEndpoint}</div>
                    </div>
                    <div className="endpoint-information-row">
                        <div className="endpoint-information-row-label">Geblokkeerd</div>
                        <div className="endpoint-information-row-value">{endpointInfo.geblokkeerd}</div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default EndpointInformation;
