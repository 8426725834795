import * as React from 'react'
import { DetailsList, IColumn, Selection, SelectionMode } from '@fluentui/react/lib/DetailsList'
import {
  FontSizes,
  IDetailsListStyles,
  IScrollablePaneStyles,
  IStackStyles,
  ITextStyles,
  MessageBar,
  MessageBarType,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Sticky,
  StickyPositionType,
  Text
} from '@fluentui/react'


export interface IDetailsListDocumentsExampleState {
  selectionData: Array<any> | [];
}

interface SelectableFluentTableProps {
  columns: Array<IColumn>;
  data: Array<any> | [];
  onSelectionChanged: (selectedData: Array<any> | []) => void;
  header?: string;
}

export class SelectableFluentUiTable extends React.Component<SelectableFluentTableProps, IDetailsListDocumentsExampleState> {

  private _selection: Selection

  constructor(props) {
    super(props)

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionData: this._getSelectionDetails()
        })
      },
      getKey: this._getKey
    })

    this.state = {
      selectionData: this._getSelectionDetails()
    }
  }

  private _getSelectionDetails() {
    return this._selection.getSelection()
  }

  public render() {
    this.props.onSelectionChanged(this.state.selectionData)

    const textStyles: Partial<ITextStyles> = {
      root: {
        fontWeight: '600',
        fontSize: FontSizes.size20
      }
    }

    const scrollableSTyles: Partial<IScrollablePaneStyles> = {
      root: {
        height: '90%',
        backgroundColor: '#fff'
      }
    }

    const stackStyles: IStackStyles = {
      root: {
        paddingLeft: 20,
        paddingTop: 20
      }
    }

    const styles: Partial<IDetailsListStyles> = {
      root: {
        paddingTop: '0px',
        height: '100%'
      }
    }

    return (
      <div style={{ height: '100%', width: '100%' }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={scrollableSTyles}>
          {this.props.header && <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            <Stack styles={stackStyles}><Text styles={textStyles}>{this.props.header}</Text></Stack>
          </Sticky>}
          <DetailsList
            items={this.props.data}
            columns={this.props.columns}
            selectionMode={SelectionMode.single}
            isHeaderVisible={true}
            selection={this._selection}
            selectionPreservedOnEmptyClick={true}
            enterModalSelectionOnTouch={true}
            styles={styles}
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              return (
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}
                        stickyBackgroundColor="transparent">
                  <div>{defaultRender && defaultRender(headerProps)}</div>
                </Sticky>
              )
            }}
          />
          {this.props.data.length === 0 && <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            <MessageBar messageBarType={MessageBarType.info}>Er zijn geen items om weer te geven.</MessageBar>
          </Sticky>}
        </ScrollablePane>
      </div>
    )
  }

  private _getKey(item: any): string {
    return item.key
  }
}
