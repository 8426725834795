import React from 'react'
import { Toggle, IToggleProps } from '@fluentui/react'

import { TOGGLE_ON, TOGGLE_OFF } from 'lib/constants/labels'

const FluentToggle: React.FC<IToggleProps> = props => {
  return <Toggle onText={TOGGLE_ON} offText={TOGGLE_OFF} {...props} />
}

export default FluentToggle
