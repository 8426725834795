import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStateMachine } from 'store'
import { RootState } from 'store/rootReducer'
import { invokeFetchDownload } from 'services/apiClient'
import { IBlob } from 'interfaces/blob'

interface IBlobsState extends IStateMachine {}

const initState: IBlobsState = {
  status: 'idle',
  error: '',
};

export interface IFetchBlobArgs {
  id: string,
  fileName: string
}

const fetchBlob = createAsyncThunk<IBlob, IFetchBlobArgs, { state: RootState }>('blobs/fetchById', async (fetchBlobArgs, thunkApi) => {
  return await invokeFetchDownload(thunkApi, 'GET', `/berichtbezorgingen/files/${fetchBlobArgs.id}`, fetchBlobArgs.fileName);
});

export const slice = createSlice({
  name: 'berichten/data',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchBlob.pending, (state, action) => {
      state.status = 'pending';
      state.error = ''
    });
    builder.addCase(fetchBlob.fulfilled, (state, _) => {
      state.status = 'succeeded'
    });
    builder.addCase(fetchBlob.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || ''
    })
  },
});

export { fetchBlob }

export default slice.reducer
