import { EndpointProtocolType } from 'interfaces/endpointProtocolType';

export function GetEndPointOntvangen(protocol: EndpointProtocolType, soapApiEndpoint: string, restApiEndpoint31: string) {
    // const { soapApiEndpoint, restApiEndpoint31 } = useTypedSelector(getConfiguration);
    switch (protocol) {
        case EndpointProtocolType.SOAP:
            return soapApiEndpoint;
        case EndpointProtocolType.REST:
            return restApiEndpoint31;
        default:
            return "Onbekend protocol";
    }
}