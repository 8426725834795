import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import './index.scss'
import { INavLinkGroup, INavStyles, IRenderFunction, IRenderGroupHeaderProps, Nav, Text } from '@fluentui/react'

const navStyles: Partial<INavStyles> = {
  root: {
    paddingTop: '20px',
    height: '100%',
    // width: 201,
    boxSizing: 'border-box',
    border: '1px solid #eee',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  group: {
    width: '100%',
    h3: {
      paddingLeft: '5px',
    },
  },
}

const onRenderGroupHeader: IRenderFunction<IRenderGroupHeaderProps> = props =>
  props ? (
    <Text variant="large" styles={{ root: { fontWeight: 'bold' } }} as="h3">
      {props.name}
    </Text>
  ) : null

const Navbar: React.FC = () => {
  const history = useHistory()
  const navLinkGroups: INavLinkGroup[] = useMemo(
    () => [
      {
        name: 'Monitoren',
        links: [
          { name: 'Dashboard', key: 'Dashboard', onClick: () => history.push('/dashboard'), automationId: 'nav-dashboard' },
          { name: 'Alle berichten', key: 'Berichten', onClick: () => history.push('/berichten'), automationId: 'nav-berichten' },
        ],
      } as INavLinkGroup,
      {
        name: 'Afhandelen',
        links: [
            { name: 'Niet bezorgde berichten', key: 'AfhandelenNietBezorgeBerichten', onClick: () => history.push('/afhandelen/nietbezorgd'), automationId: 'nav-afhandelen-nietbezorgd' },
        ],
      } as INavLinkGroup,
      {
        name: 'Beheren',
        links: [
          { name: 'Aannemers', key: 'Aannemers', onClick: () => history.push('/aannemers'), automationId: 'nav-aannemers' },
          { name: 'Corporatie', key: 'corporatie', onClick: () => history.push('/corporatie'), automationId: 'nav-corporatie' },
          { name: 'Business rules', key: 'BusinessRules', onClick: () => history.push('/businessrules'), automationId: 'nav-business_rules' },
          { name: 'Mutatielog', key: 'mutatielog', onClick: () => history.push('/mutatielog'), automationId: 'nav-mutatielog' },
        ],
      } as INavLinkGroup,
    ],
    [history]
  )

  function onRenderLink(link: any, originalRender: any): any {
    return <div data-test={link.automationId}>{originalRender(link)}</div>;
  }

  return <Nav className="nav" styles={navStyles} data-test="data-navbar" onRenderGroupHeader={onRenderGroupHeader} onRenderLink={onRenderLink} groups={navLinkGroups} />
}

export default Navbar
