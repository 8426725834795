import React from 'react'
import { Checkbox, ICheckboxStyles, ITextFieldStyles, TextField } from '@fluentui/react'
export interface IReadonlyFieldProps {
  title: string
  value: any
  multiline?: boolean
  labelWidth?: number
  fieldWidth?: number | string
  placeholder?: string
  styles?: Partial<ITextFieldStyles>
}
interface IReadonlyCheckboxProps {
  title: string
  checked: boolean | undefined
}

const createReadonlyStyles = (labelWidth?: number | string | undefined, fieldWidth?: number | string | undefined) => {
  return {
    wrapper: {
      borderBottom: '1px solid #efefef',
      wordWrap: 'wrap',
    },
    subComponentStyles: {
      label: {
        root: {
          width: labelWidth ?? 'auto',
          fontWeight: 'normal',
          height: 'auto'
        },
      },
    },
    field: {
      fontWeight: 600,
      width: fieldWidth ?? 'auto',
      overflow: 'hidden',
    },
    suffix: {
      background: '',
    },
  } as Partial<ITextFieldStyles>
}

const cbLabelStyles: Partial<ICheckboxStyles> = {
  root: {
    borderBottom: '1px solid #efefef',
    padding: '10px 0 10px 12px',
  },
  text: {
    color: 'rgb(50, 49, 48)',
    width: 315,
  },
}

export const ReadOnlyCheckBox: React.FC<IReadonlyCheckboxProps> = (props: IReadonlyCheckboxProps) => {
  return <Checkbox checked={props.checked} label={props.title} boxSide="end" disabled styles={cbLabelStyles}></Checkbox>
}

export const ReadOnlyDate: React.FC<IReadonlyFieldProps> = (props: IReadonlyFieldProps) => {
  const value = props.value ? new Date(props.value).toLocaleDateString() : ''
  return <OhkReadOnlyField title={props.title} value={value} />
}

export const OhkReadOnlyField: React.FC<IReadonlyFieldProps> = (props: IReadonlyFieldProps) => {
  return (
    <TextField
      autoComplete="off"
      styles={props.styles ?? createReadonlyStyles(props.labelWidth ?? 165, props.fieldWidth ?? '100%')}
      label={props.title}
      underlined
      readOnly
      value={props.value}
      multiline={props.multiline}
      resizable={false}
      placeholder={props.placeholder ?? '---'}
    />
  )
}

export default OhkReadOnlyField
