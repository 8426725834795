import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from 'store';
import pivotStyles from 'styles/pivotStyles';
import { CommandBar, ICommandBarItemProps, IStackStyles, Pivot, PivotItem, Stack } from '@fluentui/react';
import { getAannemer, getAannemers } from 'store/selectors/aannemers';
import { fetchAannemer } from 'store/actions/aannemers/data';
import commandBarStyles from 'styles/commandBarStyles';
import Details from './Details';
import Add from '../Add';
import Werkwijzes from './Werkwijzes';
import { Endpoints } from './endpoints';
import { fetchAannemerEndpointsBezorgen } from 'store/actions/aannemers/endpointsBezorgen';
import { fetchAannemerEndpointsOntvangen } from 'store/actions/aannemers/endpointsOntvangen';
import { GlnCodeType } from 'interfaces/aannemerGlnType';

enum tabs {
  Algemeen = 'Algemeen',
  Endpoints = 'Endpoints',
}

interface RouterParams {
  aannemerId: string
}

const Aannemer: React.FC<{}> = props => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { aannemerId } = useParams<RouterParams>();
  const [refetch, setRefetch] = useState(false);
  const [addItem, setAddItem] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs.Algemeen);
  const aannemer = useTypedSelector(state => getAannemer(state, +aannemerId));
  const { status: fetchStatus } = useTypedSelector(getAannemers);

  useEffect(() => {
    if (aannemerId) {
      dispatch(fetchAannemer(aannemerId));
    }
  }, [dispatch, aannemerId]);

  useEffect(() => {
    if (refetch && aannemerId) {
      dispatch(fetchAannemer(aannemerId));
      if(selectedTab === tabs.Endpoints) {
        dispatch(fetchAannemerEndpointsBezorgen(aannemerId.toString()));
        dispatch(fetchAannemerEndpointsOntvangen(aannemerId.toString()));
      }
      setRefetch(false)
    }
  }, [dispatch, refetch, aannemerId]);

  const commandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'terug',
        iconProps: { iconName: 'Back', className: 'icon' },
        onClick: () => history.goBack(),
        split: true,
      },
      {
        key: 'pagetitle',
        onRender: () => (
            <div className="aannemer-title page-title">{aannemer && aannemer.naam}</div>
        )
      },
    ],
    [history]
  );

  const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
      () => [
        {
          key: 'refresh',
          text: 'Vernieuwen',
          iconProps: { iconName: 'Refresh', className: 'icon' },
          split: false,
          ariaLabel: 'Vernieuwen',
          onClick: () => setRefetch(true),
        },
      ],
      [setRefetch, setAddItem]
  );
  
  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedTab(item.props.itemKey as tabs);
    }
  };
  
  return (
    <div style={rootStyles}>
      <div style={headerStyles}>
        <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles} />
        <div style={headerContentStyles}>
          <Pivot styles={pivotStyles} headersOnly={true} selectedKey={selectedTab} onLinkClick={handleLinkClick} >
            <PivotItem
              itemKey={tabs.Algemeen}
              headerText={tabs.Algemeen}
              headerButtonProps={{
                'data-order': 1,
              }}
            />
            {aannemer?.glnCodeType === GlnCodeType.Ketenstandaard && 
              <PivotItem itemKey={tabs.Endpoints} headerText="Endpoints"/>
            }
          </Pivot>
        </div>
      </div>
      {fetchStatus !== 'pending' && aannemer && selectedTab === tabs.Algemeen && (
        <div role="tabpanel" style={tabpanelStyles}>
          <Stack horizontal wrap tokens={{ childrenGap: 16 }} styles={stackStyles}>
            <Details aannemer={aannemer} />
            <Werkwijzes aannemer={aannemer} />
          </Stack>
        </div>
          
      )}
      {selectedTab === tabs.Endpoints && (
          <div role="tabpanel" style={tabpanelStyles}>
            <div className="aannemer-endpoints scrollbar-auto flex-container flex-column flex-gap-8 padding-8">
              <Endpoints aannemerId={+aannemerId}></Endpoints>
            </div>
          </div>
      )}
      {addItem && <Add onCancel={() => setAddItem(false)} />}
    </div>
  )
};

export default Aannemer


const rootStyles: React.CSSProperties = {
  display: 'inline-flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  height: '100%',
};

const headerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  width: '100%',
  borderBottom: '1px solid #efefef',
  boxShadow: '#efefef 0px 0px 9px -1px',
};

const headerContentStyles: React.CSSProperties = {
  margin: 14,
};

const tabpanelStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
  overflowY: 'auto',
};

const stackStyles: IStackStyles = {
  inner: {
    display: 'block',
    height: '100%',
    width: '100%',
    padding: 10,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
};