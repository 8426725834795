import { combineReducers } from '@reduxjs/toolkit'
import StatistiekenPerType from './statistiekenPerType'
import endpoinsts from './endpoints'
import statistiekenPerStatus from './statistiekenPerStatus';

export default combineReducers({
  StatistiekenPerType,
  endpoinsts,
  statistiekenPerStatus
})
