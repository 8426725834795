import React from 'react'
import { Image } from '@fluentui/react'
import './navigationBar.scss'

const NavigationBar = () => {
    return (
        <div className="callback-toolbar">
            <div className="callback-toolbar-container">
                <div className="callback-toolbar-left">
                    <div className="callback-toolbar-item">
                        <Image src={`${process.env.PUBLIC_URL}/logo.png`} height={38} style={{paddingLeft: 6}} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavigationBar