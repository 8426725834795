import React from 'react'
import { ThemeProvider } from '@fluentui/react'
import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'

import './App.css'
import './styles/flexbox.css'
import './styles/styles.scss'
import AppRouter from 'AppRouter'
import { useSelector } from 'react-redux'
import { getConfiguration } from 'store/selectors/app'
import primaryTheme from 'themes/primary'

function App() {
  const appConfiguration = useSelector(getConfiguration);

  // MSAL configuration
  const configuration: Configuration = {
    auth: {
      clientId: appConfiguration.clientId,
      redirectUri: appConfiguration.redirectUri,
    },
  };

  const pca = new PublicClientApplication(configuration);

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider applyTo="body" theme={primaryTheme}>
        <AppRouter />
      </ThemeProvider>
    </MsalProvider>
  )
}

export default App
