import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { DefaultButton, IColumn, Link, ResponsiveMode } from '@fluentui/react';
import InputCard from 'components/InputCard';
import { getPropertyName } from 'lib/interfaceUtils';
import { EndpointOntvangen } from 'interfaces/endpointOntvangen';
import { AddEndpointOntvangen } from './AddEndpointOntvangen';
import OhkModal from '../../OhkModal';
import { EditEndpointOntvangen } from './EditEndpointOntvangen';
import { booleanToDutchString } from 'lib/internationalization';
import OhkTable from '../../OhkTable';
import { EndpointProtocolTypeLabelMap } from 'interfaces/endpointProtocolType';

interface IProps {
    endpointsOntvangen: EndpointOntvangen[];
    titelEnkelvoud: string;
    titelMeervoud: string;
    isCorporatie: boolean;
    aannemerId?: number;
    onAddItem: (item: EndpointOntvangen) => Promise<EndpointOntvangen>;
    onEditItem: (item: EndpointOntvangen) => Promise<EndpointOntvangen>;
    onDeleteItem: (item: EndpointOntvangen) => Promise<boolean>;
    reset: () => void;
    error?: string
    status?: string
}

export const EndpointsOntvangen = ({endpointsOntvangen, titelEnkelvoud, titelMeervoud, isCorporatie,
                                                 onAddItem, onEditItem, onDeleteItem, reset, error, status, aannemerId} : IProps) => {
    const [addItem, setAddItem] = useState(false);
    const [editItem, setEditItem] = useState<EndpointOntvangen>();

    function onClickNew(): void {
        reset();
        setAddItem(true);
    }

    function handleItemInvoked(endpointOntvangen: EndpointOntvangen): any {
        reset();
        setEditItem(endpointOntvangen);
    }

    async function onAddItemSave(item: EndpointOntvangen): Promise<EndpointOntvangen> {
        return onAddItem(item);
    }



    const onEditItemSave = useCallback(
        (item: EndpointOntvangen) => {
            const result = onEditItem(item);
            result.then((success) => {
                setEditItem(undefined);
                return success;
            }).catch((error) => {
                    console.log(error);
                }
            );
            return result;
        }, []);

    async function onDeleteItemSave(item: EndpointOntvangen): Promise<boolean> {
        try {
            const result = await onDeleteItem(item);
            if(result) {
                setEditItem(undefined);
            }
            return result;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
    
    

    const handleGetKey = (item): string => {
        return (item as EndpointOntvangen).id.toString()
    };

    const handleColumnClick = () => null;

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: getPropertyName<EndpointOntvangen>('naam'),
                name: 'Naam',
                fieldName: getPropertyName<EndpointOntvangen>('naam'),
                minWidth: 80,
                maxWidth: 200,
                isResizable: true,
                data: 'string',
                onRender: (item: EndpointOntvangen) => (
                    <Link key={item.id} onClick={() => handleItemInvoked(item)}>
                        <span>{item.naam}</span>
                    </Link>
                ),
            },
            {
                key: getPropertyName<EndpointOntvangen>('protocol'),
                name: 'Protocol',
                fieldName: getPropertyName<EndpointOntvangen>('protocol'),
                minWidth: 80,
                maxWidth: 100,
                isResizable: false,
                data: 'number',
                onRender: (item: EndpointOntvangen) => (
                    <div>{EndpointProtocolTypeLabelMap[item.protocol]}</div>
                )
            },
            {
                key: getPropertyName<EndpointOntvangen>('gebruikersnaam'),
                name: 'Gebruikersnaam',
                fieldName: getPropertyName<EndpointOntvangen>('gebruikersnaam'),
                minWidth: 80,
                maxWidth: 300,
                isResizable: false,
                data: 'string',
            },
            {
                key: getPropertyName<EndpointOntvangen>('geblokkeerd'),
                name: 'Geblokkeerd',
                fieldName: getPropertyName<EndpointOntvangen>('geblokkeerd'),
                minWidth: 80,
                maxWidth: 100,
                isResizable: false,
                data: 'boolean',
                onRender: (item: EndpointOntvangen) => (<span>{booleanToDutchString(item.geblokkeerd)}</span>)
            },
        ],[handleColumnClick]);

    return (
        <div className="flex-container flex-column">
            <InputCard className="flex-grow max-width-1200" title={titelMeervoud} style={{ minWidth: 500 }} >
                <div className={"flex-grow"} style={tableStyles}>
                    <OhkTable
                        disableSort={false}
                        columns={columns}
                        items={endpointsOntvangen}
                        loading={false}
                        onGetKey={handleGetKey}
                    />
                </div>
                <div className="endpoint-Ontvangen-actions flex-container" style={endpointOntvangenActionsStyles}>
                    <DefaultButton text="Toevoegen Endpoint" onClick={onClickNew} />
                </div>
                {addItem &&
                    <OhkModal title={titelEnkelvoud}
                              isOpen={addItem}
                              onClose={() => setAddItem(false)}
                              responsiveMode={ResponsiveMode.medium}
                    >
                        <AddEndpointOntvangen
                            onClose={() => setAddItem(false)}
                            onAddItemSave={onAddItemSave}
                            error={error}
                            status={status}></AddEndpointOntvangen>
                    </OhkModal>
                }
                {editItem &&
                    <OhkModal title={titelEnkelvoud}
                              onClose={() => setEditItem(undefined)}
                              isOpen={!!editItem}
                              responsiveMode={ResponsiveMode.xLarge}>
                        <EditEndpointOntvangen endpointEdit={editItem}
                                               onEditItemSave={onEditItemSave}
                                               onDeleteItem={onDeleteItemSave}
                                               onClose={() => setEditItem(undefined)}
                                               error={error}
                                               status={status}
                        ></EditEndpointOntvangen>
                    </OhkModal>
                }
            </InputCard>
        </div>
    )
};

const tableStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flex: 'flex-grow',
    height: '100%',
    width: '100%',
};

const endpointOntvangenActionsStyles: React.CSSProperties = {
    marginTop: '16px',
    justifyContent: 'flex-end',
    padding: '8px',
    alignItems: 'center',
};