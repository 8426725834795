import * as y from 'yup';

export interface IInstellingen {
  id: number
  endpointBezorgingIdFactuurBerichten?: number
  endpointBezorgingIdStatusBerichten?: number
}

export interface IUpdateInstellingenForm {
  endpointBezorgingIdFactuurBerichten?: number
  endpointBezorgingIdStatusBerichten?: number
}

export const updateInstellingenSchema = y.object({
  endpointBezorgingIdFactuurBerichten: y.number().optional().nullable(),
  endpointBezorgingIdStatusBerichten: y.number().optional().nullable(),
});
