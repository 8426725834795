import { MsalClient } from 'services/MsalClient'
import { RootState } from 'store'
import { setGenericError } from 'store/actions/app/genericError'

export const invokeFetch = async <T>(
  thunkApi: any,
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  uri: string,
  body?: any,
  odata: boolean = true,
  headers: any = {},
  jsonBody: boolean = true,
): Promise<T> => {
  const state = thunkApi.getState() as RootState;
  const { apiEndpoint } = state.app.configuration;
  const endpoint = `${apiEndpoint}${uri}`;
  if (jsonBody) {
    body = body && JSON.stringify(body);
      headers = {
          ...headers,
          'Content-Type': 'application/json',
      };
  }

  const token = await MsalClient.getApiToken();
  const resp = await fetch(endpoint, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    body: body,
  });

  if (method === 'DELETE' && resp.ok) return {} as T;

  if (resp.status === 204) return {} as T;

  const json = await resp.json();

  if (resp.ok) {
    return method === 'GET' && odata && json.hasOwnProperty('value') ? (json.value as T) : (json as T)
  } else if (resp.status === 404) {
    thunkApi.dispatch(
      setGenericError({
        title: 'Dit record is niet beschikbaar',
        subText:
          'Het gevraagde record is niet gevonden of u beschikt niet over de juiste machtigingen om deze weer te geven.',
       
      })
    )
  }
  else if (resp.status >= 400 && resp.status < 500) {
      thunkApi.dispatch(
          setGenericError({
              title: json.title ?? 'Validatie fout(en)',
              subText: json.detail ?? json.error ?? (json.errors ? JSON.stringify(json.errors) : json)
          })
      )
  }  
  else if (resp.status === 500) {
    thunkApi.dispatch(
        
      setGenericError({
        title: json.title ?? 'Internal server error',
        subText: json.detail ?? json.error ?? (json.errors ? JSON.stringify(json.errors) : json)
        
      })
    )
  }
  else {
      thunkApi.dispatch(
          setGenericError({
              title: json.title ?? 'Onverwachte server fout.',
              subText: json.detail ?? json.error ?? (json.errors ? JSON.stringify(json.errors) : json)
          })
      )
  }

  return Promise.reject(json.detail || json.error || json);
};

export const invokeFetchDownload = async (
    thunkApi: any,
    method: 'GET',
    uri: string,
    fileName: string
): Promise<any> => {
    
    const state = thunkApi.getState() as RootState; 
    const { apiEndpoint } = state.app.configuration;
    const endpoint = `${apiEndpoint}${uri}`;

    const token = await MsalClient.getApiToken();
    const resp = await fetch(endpoint, {
        method: method,
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    if (resp.status === 204) return {} as any;

    if (resp.ok) {
        // download the file
        const blob = await resp.blob();
        const blobURL = URL.createObjectURL(blob);
        const el = document.createElement('a');
        el.href = blobURL;
        if (fileName) {
            // fileName has standard the extension .xml
            // If blob.type (mime type) is not xml, then we need to change the extension
            // to the mime type extension, text/html -> .html
            if(!blob.type.includes('.xml')) {
                // We only try to add the proper extension for the most common mime types
                var mimeExtension = '';  
                switch (blob.type) {
                    case 'text/html':
                        mimeExtension = '.html';
                        break;
                    case 'text/plain':
                        mimeExtension = '.txt';
                        break;
                    case 'application/pdf':
                        mimeExtension = '.pdf';
                        break;
                    case 'application/json':
                        mimeExtension = '.json';
                        break;
                }
                fileName = fileName + mimeExtension;
            }
            el.download = fileName
        }
        document.body.appendChild(el);
        el.click();
        return {} as any;
        
    } else if (resp.status === 404) {
        thunkApi.dispatch(
            setGenericError({
                title: 'Dit record is niet beschikbaar',
                subText:
                    'Het gevraagde record is niet gevonden of u beschikt niet over de juiste machtigingen om deze weer te geven.'
            })
        )
    } else if (resp.status === 500) {
        thunkApi.dispatch(
            setGenericError({
                title: 'Internal server error',
                subText: '',
            })
        )
    }
    else {
        thunkApi.dispatch(
            setGenericError({
                title: 'Unknown server error',
                subText: '',
            })
        )
    }

    return Promise.reject();
};
