import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { store } from 'store'

export const graphScopes = ['openid', 'email', 'offline_access', 'profile', 'User.Read']

export class MsalClient extends PublicClientApplication {
  private static instance: MsalClient

  private constructor(configuration: Configuration) {
    super(configuration)
  }

  public static getInstance(): MsalClient {
    this.initializeInstance()
    return this.instance
  }

  private static initializeInstance() {
    if (!this.instance) {
      const cfg = store.getState().app.configuration

      this.instance = new MsalClient({
        auth: {
          clientId: cfg.clientId,
          redirectUri: cfg.redirectUri,
        },
      })
    }
  }

  private static async getToken(graphToken: boolean = false): Promise<string> {
    const accounts = this.instance.getAllAccounts();
    const account = accounts[0];
    const apiScopes = store.getState().app.configuration.apiScopes;
    const scopes = graphToken ? graphScopes.concat(apiScopes) : apiScopes;

    const resp = await this.instance.acquireTokenSilent({
      scopes: scopes,
      account,
    })

    return resp.accessToken
  }

  public static async getApiToken(): Promise<string> {
    this.initializeInstance()
    return this.getToken()
  }

  public static async getGraphToken(): Promise<string> {
    this.initializeInstance()
    return this.getToken(true)
  }
}
