export enum OpdrachtSoort {
  INS = 'INS',
  MUT = 'MUT',
  REP = 'REP',
}

export const OpdrachtSoortLabelMap: { [key in OpdrachtSoort]: string } = {
  [OpdrachtSoort.INS]: 'Inspectie',
  [OpdrachtSoort.MUT]: 'Mutatie',
  [OpdrachtSoort.REP]: 'Reparatie',
}
