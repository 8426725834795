import React, { useEffect } from 'react';
import './Uploaden.scss';
import { DefaultButton, MessageBar, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import { useAppDispatch, useTypedSelector } from 'store';
import {
    fileUploadImportAannemers,
    IUploadImportFileArgs,
    resetFileUploadImportAannemers
} from 'store/actions/importAannemers/data';
import { getImportAannemers } from 'store/selectors/importAannemersUploadFile';
import { UploadPreview } from './UploadPreview/UploadPreview';

interface IProps {
    uploadFile: File | undefined;
    onFinished: (resultaat: boolean) => void;
    onUploadFileChanged: (uploadFile: File | undefined) => void;
}

export const Uploaden = ({onFinished, uploadFile, onUploadFileChanged}: IProps) => {
    const dispatch = useAppDispatch();
    const uploadIcon = {iconName: 'Upload'};
    const {importFileRows, status, error} = useTypedSelector(getImportAannemers);

    useEffect(() => {
        onFinished(false);
        
        if (!uploadFile || importFileRows ! === undefined || importFileRows.length > 0) 
        {
            return;
        }
        const fetchUploadFile = dispatch(fileUploadImportAannemers({file: uploadFile} as IUploadImportFileArgs));
        return () => {
            fetchUploadFile.abort();
        };
    }, [dispatch, uploadFile]);
    
    useEffect(() => {
        if (status === 'succeeded' && importFileRows && importFileRows.length > 0) {
            onFinished(true);
        } else {
            onFinished(false);
        }
    }, [importFileRows, status]);
        


    const handleUploaden = (): any => {
        // click on hidden file input
        document.getElementById('fileUpload')?.click();
    };
    const handleOnFilesSelected = (event): any => {
        dispatch(resetFileUploadImportAannemers());
        onUploadFileChanged(event.target.files[0]);
        // setUploadFile(event.target.files[0]);
    };

    return (
        <div className="uploaden">
            <div className="uploaden-info title">
                Kies het Excel bestand met de te importeren aannemers.
            </div>
            <div className="uploaden-file">
                <input type="file" id="fileUpload" name="file" onChange={handleOnFilesSelected} hidden={true}
                       accept=".xlsx"/>
                <DefaultButton primary text="Kies bestand om te importeren" iconProps={uploadIcon}
                               onClick={handleUploaden} disabled={status === 'pending'}/>
                <div className="uploaden-file-info">
                    {uploadFile?.name}
                </div>
            </div>
            {status === 'pending' && (
                <Spinner size={SpinnerSize.large}/>
            )}
            {status === 'succeeded' && (
                <div className="uploaden-preview">
                    <div className="uploaden-preview-header title">Inhoud bestand</div>
                    <div className="uploaden-preview-data">
                        <UploadPreview importFileRows={importFileRows}/>
                    </div>
                    <div className="uploaden-preview-next title">
                        Klik op volgende om de gegevens te controleren (er wordt nog niets geïmporteerd).
                    </div>
                </div>
            )}
            {status === 'failed' && (
                <div className="uploaden-error">
                    <MessageBar messageBarType={MessageBarType.error} >
                        {error}
                    </MessageBar>
                </div>
            )}
        </div>
    );
};
