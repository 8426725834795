import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { invokeFetch } from 'services/apiClient'
import { IStateMachine, RootState } from 'store'
import { AfhandelenHandmatigRequest, AfhandelenOpnieuwBezorgenRequest } from '../../../interfaces/berichtbezorging';


interface IAfhandelenHandmatigRequest {
    request: AfhandelenHandmatigRequest
}

interface IOpnieuwBezorgenRequest {
    request: AfhandelenOpnieuwBezorgenRequest
}

const updateHandmatigAfhandelen = createAsyncThunk<boolean, IAfhandelenHandmatigRequest, { state: RootState }>(
    'afhandelen/handmatig',
    async ({ request }, thunkApi) => {
        return await invokeFetch<boolean>(thunkApi, 'POST', `/afhandelen/handmatig`, request, false)
    }
)

const updateOpnieuwBezorgen = createAsyncThunk<boolean, IOpnieuwBezorgenRequest, { state: RootState }>(
    'afhandelen/opnieuwbezorgen',
    async ({ request }, thunkApi) => {
        return await invokeFetch<boolean>(thunkApi, 'POST', `/afhandelen/opnieuwbezorgen`, request, false)
    }
)

const updateOpnieuwBezorgenPerEmail = createAsyncThunk<boolean, IOpnieuwBezorgenRequest, { state: RootState }>(
    'afhandelen/opnieuwperemail',
    async ({ request }, thunkApi) => {
        return await invokeFetch<boolean>(thunkApi, 'POST', `/afhandelen/opnieuwperemail`, request, false)
    }
)

const initState: IStateMachine = {
    status: 'idle',
    error: '',
}

export const slice = createSlice({
    name: 'afhandelen/updating',
    initialState: initState,
    reducers: {
        resetAfhandelenUpdating: state => {
            state.status = initState.status
            state.error = ''
        },
        afhandelenUpdatingSetPending: state => {
            state.status = 'pending'
            state.error = ''
        }
    },
    extraReducers: builder => {
        builder.addCase(updateHandmatigAfhandelen.pending, (state, _) => {
            state.status = 'pending'
            state.error = ''
        })
        builder.addCase(updateHandmatigAfhandelen.fulfilled, (state, _) => {
            state.status = 'succeeded'
        })
        builder.addCase(updateHandmatigAfhandelen.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message ?? ''
        })
    },
})

export const { resetAfhandelenUpdating, afhandelenUpdatingSetPending } = slice.actions;
export {updateHandmatigAfhandelen, updateOpnieuwBezorgen, updateOpnieuwBezorgenPerEmail}
export default slice.reducer
