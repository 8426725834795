export enum Berichtstatus {
  Geen = 0,
  Onbestelbaar = 1,
  InBehandeling = 2,
  Bezorgd = 3,
  NietBezorgd = 4,
  HandmatigAfgehandeld = 5,
}

export const berichtstatusLabelMap: { [key in Berichtstatus] } = {
  [Berichtstatus.Geen]: '',
  [Berichtstatus.Onbestelbaar]: 'Onbestelbaar',
  [Berichtstatus.InBehandeling]: 'In behandeling',
  [Berichtstatus.Bezorgd]: 'Bezorgd',
  [Berichtstatus.NietBezorgd]: 'Niet bezorgd',
  [Berichtstatus.HandmatigAfgehandeld]: 'Handmatig afgehandeld',
}
