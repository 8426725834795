import { IPivotStyleProps, IPivotStyles } from '@fluentui/react'

const pivotStyles = (props: IPivotStyleProps): Partial<IPivotStyles> => ({
  linkIsSelected: {
    ':before': {
      height: '3px',
      right: 0,
      left: 0,
      borderRadius: '10px',
    },
  },
});

export default pivotStyles
