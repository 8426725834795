import * as React from 'react';
import './StatusTabs.scss';
import { Pivot, PivotItem } from '@fluentui/react';
import pivotStyles from 'styles/pivotStyles';
import { Constants } from 'lib/constants/icons';
import { useMemo } from 'react';

const StatusTabs = ({statusFilter, berichtCount, onStatusFilterChanged}) => {

    function onLinkClick(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>): any  {
        onStatusFilterChanged(item?.props?.itemKey);
    }
    
    let selectedKey = useMemo(() => {
        return statusFilter.toString();
    }, [statusFilter]);
    
    
    return (
        <Pivot styles={pivotStyles} headersOnly={true} onLinkClick={onLinkClick} selectedKey={selectedKey}>
            <PivotItem itemKey='0' headerText='Alle berichten' itemIcon={Constants.IconBerichtStatusAlles} itemCount={berichtCount.alle} />
            <PivotItem itemKey='1' headerText='Onbestelbaar' itemIcon={Constants.IconBerichtStatusOnbestelbaar} itemCount={berichtCount.onbestelbaar} />
            <PivotItem itemKey='2' headerText='In behandeling' itemIcon={Constants.IconBerichtStatusInBehandeling} itemCount={berichtCount.inBehandeling} />
            <PivotItem itemKey='4' headerText='Niet bezorgd' itemIcon={Constants.IconBerichtStatusNietBezorgd} itemCount={berichtCount.nietBezorgd} />
            <PivotItem itemKey='3' headerText='Bezorgd' itemIcon={Constants.IconBerichtStatusBezorgd} itemCount={berichtCount.bezorgd} />
            <PivotItem itemKey='5' headerText='Handmatig afgehandeld' itemIcon={Constants.IconBerichtStatusHandmatigAfgehandeld} itemCount={berichtCount.handmatigAfgehandeld} />
        </Pivot>

        )
}

export default StatusTabs;