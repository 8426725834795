import React, { useCallback, useEffect, useMemo } from 'react';
import {
    DefaultButton,
    Dropdown,
    IButtonStyles,
    IconButton,
    IDropdownOption,
    IStackStyles,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    Stack,
} from '@fluentui/react';
import InputCard from 'components/InputCard';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useTypedSelector } from 'store';
import { getPropertyName } from 'lib/interfaceUtils';
import { IInstellingen, IUpdateInstellingenForm, updateInstellingenSchema } from 'interfaces/instellingen';
import { getUpsertInstellingen } from 'store/selectors/instellingen';
import { resetUpsertInstellingen, updateInstellingen } from 'store/actions/instellingen/upsert';
import { EndpointBezorgen } from 'interfaces/endpointBezorgen';


const stackStyles: IStackStyles = {
  inner: {
    display: 'block',
    height: '100%',
    width: '100%',
    padding: 10,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
};

const resetButtonStyles: IButtonStyles = {
  icon: {
    fontSize: 14,
  },
};

interface IDetails {
    instellingen: IInstellingen;
    endpointsBezorgen: EndpointBezorgen[];
}

const Details: React.FC<IDetails> = ({ instellingen, endpointsBezorgen }) => {
  const dispatch = useAppDispatch();

  const { status, error } = useTypedSelector(getUpsertInstellingen);
   

  const defaultValues: IUpdateInstellingenForm = useMemo(
    () => ({
      ...instellingen,
    }),
    [instellingen]
  );

  const {
    handleSubmit,
    control,
    formState,
    reset,
    setValue,
    errors: formErrors,
    watch,
  } = useForm<IUpdateInstellingenForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: yupResolver(updateInstellingenSchema),
  });

  useEffect(() => {
    dispatch(resetUpsertInstellingen())
  }, [dispatch]);

  const endpointsBezorgenOptions: IDropdownOption[] = useMemo(() => {
    return endpointsBezorgen.map(
      endpoint =>
        ({
          key: endpoint.id,
          text: endpoint.naam + (endpoint.geblokkeerd ? ' [geblokkeerd]' : ''),
        } as IDropdownOption)
    )
  }, [endpointsBezorgen]);

  const watchFields = watch();

  const dataHasChanged = useMemo((): boolean => {
    return (
      defaultValues.endpointBezorgingIdStatusBerichten !== watchFields.endpointBezorgingIdStatusBerichten ||
      defaultValues.endpointBezorgingIdFactuurBerichten !== watchFields.endpointBezorgingIdFactuurBerichten
    )
  }, [defaultValues, watchFields]);

  const onSave = useCallback(
    (data: IUpdateInstellingenForm) => {
      dispatch(resetUpsertInstellingen());
      dispatch(updateInstellingen(data))
    },
    [dispatch, instellingen]
  );

  const handleSave = () => {
    handleSubmit(onSave)()
  };

  return (
    <>
      <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={stackStyles}>
        <InputCard title="Toewijzen endpoints voor bezorgen Status & Factuur" style={{ minWidth: 500, maxWidth: 500 }}>
          {error && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
              {error}
            </MessageBar>
          )}
          <form onSubmit={handleSubmit(onSave)}>
            <Controller
              name={getPropertyName<IUpdateInstellingenForm>('endpointBezorgingIdStatusBerichten')}
              control={control}
              render={({ onBlur, value }) => (
                <Dropdown
                  placeholder="Kies een optie"
                  label="Endpoints statusberichten"
                  options={endpointsBezorgenOptions}
                  selectedKey={value}
                  onRenderLabel={(props, defaultRender) =>
                    defaultRender ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {defaultRender(props)}
                        <IconButton
                          iconProps={{ iconName: 'Cancel' }}
                          styles={resetButtonStyles}
                          onClick={() => setValue('endpointBezorgingIdStatusBerichten', null)}
                        />
                      </div>
                    ) : null
                  }
                  errorMessage={formErrors.endpointBezorgingIdStatusBerichten?.message}
                  onChange={(_, option) => {
                    setValue('endpointBezorgingIdStatusBerichten', option!.key);
                    onBlur()
                  }}
                />
              )}
            />
            <Controller
              name={getPropertyName<IUpdateInstellingenForm>('endpointBezorgingIdFactuurBerichten')}
              control={control}
              render={({ onBlur, value }) => (
                <Dropdown
                  placeholder="Kies een optie"
                  label="Endpoints factuurberichten"
                  options={endpointsBezorgenOptions}
                  selectedKey={value}
                  onRenderLabel={(props, defaultRender) =>
                    defaultRender ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {defaultRender(props)}
                        <IconButton
                          iconProps={{ iconName: 'Cancel' }}
                          styles={resetButtonStyles}
                          onClick={() => setValue('endpointBezorgingIdFactuurBerichten', null)}
                        />
                      </div>
                    ) : null
                  }
                  errorMessage={formErrors.endpointBezorgingIdFactuurBerichten?.message}
                  onChange={(_, option) => {
                    setValue('endpointBezorgingIdFactuurBerichten', option!.key);
                    onBlur()
                  }}
                />
              )}
            />
            <input type="submit" style={{ visibility: 'hidden' }} />
          </form>
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }} styles={{ root: { marginTop: 10 } }}>
            <DefaultButton
              styles={{ root: { visibility: dataHasChanged ? 'default' : 'hidden' } }}
              disabled={status === 'pending'}
              onClick={() => reset(defaultValues)}
              text="Annuleren"
            />
            <DefaultButton
              styles={{ root: { visibility: dataHasChanged ? 'default' : 'hidden' } }}
              primary
              allowDisabledFocus
              disabled={!formState.isValid}
              onClick={handleSave}
            >
              {status === 'pending' ? <Spinner size={SpinnerSize.small} /> : 'Opslaan'}
            </DefaultButton>
          </Stack>
        </InputCard>
      </Stack>
    </>
  )
};

export default Details
