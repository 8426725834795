import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import IGraphUserProfile from 'interfaces/graphUserProfile'
import { getImageInitialsFromName } from 'lib/graph'
import { MsalClient } from 'services/MsalClient'

const fetchProfile = createAsyncThunk<IGraphUserProfile>('profile/fetch', async (_, __) => {
  const token = await MsalClient.getGraphToken()
  const resp = await fetch('https://graph.microsoft.com/v1.0/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return (await resp.json()) as IGraphUserProfile
})

interface IGraphUserProfileState extends IGraphUserProfile {
  shortName: string
}

interface IProfileState {
  data: IGraphUserProfileState
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
}

const initState: IProfileState = {
  data: {
    id: '',
    displayName: '',
    givenName: '',
    jobTitle: '',
    mail: '',
    surname: '',
    shortName: '##',
  },
  status: 'idle',
  error: null,
}

export const slice = createSlice({
  name: 'profile',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProfile.pending, (state, action) => {
      state.status = 'pending'
    })
    builder.addCase(fetchProfile.fulfilled, (state, { payload }) => {
      const shortName = getImageInitialsFromName(payload.givenName)

      const profile = {
        id: payload.id,
        displayName: payload.displayName,
        givenName: payload.givenName,
        jobTitle: payload.jobTitle,
        mail: payload.mail,
        surname: payload.surname,
        shortName: shortName,
      }

      state.status = 'succeeded'
      state.data = {
        ...initState.data,
        ...profile,
      }
    })
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || null
    })
  },
})

export { fetchProfile }

export default slice.reducer
