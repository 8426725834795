import { RootState } from '../index'

const getMe = (state: RootState) => state.app.me
const getMeStatus = (state: RootState) => state.app.me.status
const getMeError = (state: RootState) => state.app.me.error

const getConfiguration = (state: RootState) => state.app.configuration
const getApiEndpoint = (state: RootState) => state.app.configuration.apiEndpoint

const getGenericError = (state: RootState) => state.app.genericError

const getIsInitialized = (state: RootState) => state.app.configuration.initialized

export { getMe, getMeStatus, getMeError, getConfiguration, getApiEndpoint, getGenericError, getIsInitialized }
