import React from 'react'
import { Text } from '@fluentui/react'

interface IRowProps {
  title?: string
}

const Row: React.FC<IRowProps> = ({ children, title }) => {
  
  return (
    <div style={rootStyles}>
      {title && <Text variant="large" style={titleStyles}>{title}</Text>}
      <div style={contentStyles}>{children}</div>
    </div>
  )
}
export default Row

const margin = '25px'
const contentStyles: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  gap: margin,
}

const rootStyles: React.CSSProperties = {
  margin: margin,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}

const titleStyles: React.CSSProperties = {
  paddingBottom: '8px',
  paddingLeft: '4px',
}