import React, { useMemo } from 'react';
import { AannemerGeimporteerd } from 'interfaces/importAannemersImportResponse';
import { IColumn } from '@fluentui/react';
import { getPropertyName } from 'lib/interfaceUtils';
import OhkTable from 'components/OhkTable';

interface IProps {
    aannemers: AannemerGeimporteerd[]
}

export const ResultaatData = ({aannemers} : IProps) => {
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: getPropertyName<AannemerGeimporteerd>('naam'),
                name: 'Naam aannemer',
                fieldName: getPropertyName<AannemerGeimporteerd>('naam'),
                data: 'string',
                minWidth: 120,
                maxWidth: 250
            },
            {
                key: getPropertyName<AannemerGeimporteerd>('referentie'),
                name: 'Externe referentie',
                fieldName: getPropertyName<AannemerGeimporteerd>('referentie'),
                data: 'string',
                minWidth: 120,
                maxWidth: 250
            },
            {
                key: getPropertyName<AannemerGeimporteerd>('email'),
                name: 'Email-adres opdracht',
                fieldName: getPropertyName<AannemerGeimporteerd>('email'),
                data: 'string',
                minWidth: 120,
                maxWidth: 250
            },
            {
                key: getPropertyName<AannemerGeimporteerd>('glnCode'),
                name: 'GLN-code Ketenstandaard',
                fieldName: getPropertyName<AannemerGeimporteerd>('glnCode'),
                data: 'string',
                minWidth: 120,
                maxWidth: 250
            },
            {
                key: getPropertyName<AannemerGeimporteerd>('okCode'),
                name: 'Code Onderhoudsketen',
                fieldName: getPropertyName<AannemerGeimporteerd>('okCode'),
                data: 'string',
                minWidth: 150,
                maxWidth: 250
            }
        ], []);
    function handleGetKey(item): string {
        return (item as AannemerGeimporteerd).aannemerId.toString();
    }
    
    return (
        <div className="resultaat-data">
            <OhkTable columns={columns} items={aannemers} loading={false} onGetKey={handleGetKey}></OhkTable>
        </div>
    )
};
