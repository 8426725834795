import {
    EndpointBezorgen,
    ICreateEndpointBezorgenForm,
    IUpdateEndpointBezorgenForm
} from 'interfaces/endpointBezorgen';
import { IStateMachine, RootState } from '../../index';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { invokeFetch } from 'services/apiClient';

interface ICorporatieEndpointsBezorgen extends IStateMachine {
    endpointsBezorgen: EndpointBezorgen[];
}

const initState: ICorporatieEndpointsBezorgen = {
    endpointsBezorgen: [],
    status: 'idle',
    error: '',
};

const fetchCorporatieEndpointsBezorgen = createAsyncThunk<EndpointBezorgen[], undefined, { state: RootState; }>(
    'instellingen/endpointsBezorgen/fetch',
    async (_, thunkApi) => {
        return await invokeFetch<EndpointBezorgen[]>(thunkApi, 'GET', '/instellingen/endpointsBezorgen');
    }
);

const fetchCorporatieEndpointBezorgen = createAsyncThunk<EndpointBezorgen, string, { state: RootState; }>(
    'instellingen/endpointsBezorgen/fetchById',
    async (id, thunkApi) => {
        return await invokeFetch<EndpointBezorgen>(thunkApi, 'GET', `/instellingen/endpointsBezorgen/${id}`);
    }
);

interface ICreateEndpointBezorgenArgs {
    data: ICreateEndpointBezorgenForm;
}

const createCorporatieEndpointsBezorgen = createAsyncThunk<EndpointBezorgen, ICreateEndpointBezorgenArgs, { state: RootState; }>(
    'instellingen/endpointsBezorgen/create',
    async ({ data }, thunkApi) => {
        return await invokeFetch<EndpointBezorgen>(thunkApi, 'POST', `/instellingen/endpointsbezorgen`, data)
    }
);

interface IUpdateEndpointBezorgenArgs {
    data: IUpdateEndpointBezorgenForm;
}

const updateCorporatieEndpointsBezorgen = createAsyncThunk<EndpointBezorgen, IUpdateEndpointBezorgenArgs, { state: RootState; }>(
    'instellingen/endpointsBezorgen/update',
    async ({ data }, thunkApi) => {
        return await invokeFetch<EndpointBezorgen>(thunkApi, 'PUT', `/instellingen/endpointsbezorgen/${data.id}`, data)
    }
);

const deleteCorporatieEndpointsBezorgen = createAsyncThunk<EndpointBezorgen, string, { state: RootState; }>(
    'instellingen/endpointsBezorgen/delete',
    async (id, thunkApi) => {
        return await invokeFetch<EndpointBezorgen>(thunkApi, 'DELETE', `/instellingen/endpointsbezorgen/${id}`)
    }
);

export const slice = createSlice({
    name: 'instellingen/endpointsBezorgen',
    initialState: initState,
    reducers: {
        resetCorporatieEndpointsBezorgen: state => {
            state.status = initState.status;
            state.error = '';
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCorporatieEndpointsBezorgen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(fetchCorporatieEndpointsBezorgen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsBezorgen = action.payload;
        });
        builder.addCase(fetchCorporatieEndpointsBezorgen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });

        builder.addCase(createCorporatieEndpointsBezorgen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(createCorporatieEndpointsBezorgen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsBezorgen.push(action.payload);
        });
        builder.addCase(createCorporatieEndpointsBezorgen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });

        builder.addCase(updateCorporatieEndpointsBezorgen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(updateCorporatieEndpointsBezorgen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsBezorgen = state.endpointsBezorgen.map(b => b.id === action.payload.id ? action.payload : b);
        });
        builder.addCase(updateCorporatieEndpointsBezorgen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });


        builder.addCase(deleteCorporatieEndpointsBezorgen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(deleteCorporatieEndpointsBezorgen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsBezorgen = state.endpointsBezorgen.filter(b => b.id !== action.payload.id);
        });
        builder.addCase(deleteCorporatieEndpointsBezorgen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });
    }
});

export const { resetCorporatieEndpointsBezorgen } = slice.actions;
export { fetchCorporatieEndpointsBezorgen, fetchCorporatieEndpointBezorgen, createCorporatieEndpointsBezorgen ,
    updateCorporatieEndpointsBezorgen, deleteCorporatieEndpointsBezorgen};
export default slice.reducer;
