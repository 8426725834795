import React, { useEffect } from 'react'
import Row from './Row'
import Counter from 'components/Counter'
import { useAppDispatch, useTypedSelector } from 'store'
import { fetchStatistiekenPerType } from 'store/actions/dashboard/statistiekenPerType'
import { getStatistiekenPerType } from 'store/selectors/dashboard'
import { Spinner, SpinnerSize } from '@fluentui/react'
import { IDateRange } from '../../../components/DateRangeSelector/IDateRange';
import { DashboardDateRange } from '../../../interfaces/dashboard';

interface IProps {
  dateRange: IDateRange
}

const CounterPerType: React.FC<IProps> = props => {
  const {dateRange} = props;
  const dispatch = useAppDispatch()
  const { data, status } = useTypedSelector(getStatistiekenPerType)

  useEffect(() => {
    var dateRange = { datumVan: props.dateRange.start, datumTm: props.dateRange.end} as DashboardDateRange;
    const fetchPromise = dispatch(fetchStatistiekenPerType(dateRange))

    return () => {
      fetchPromise.abort()
    }
  }, [dispatch, dateRange])

  return (
    <Row title="Berichten per type">
      {status === 'pending' ? (
        <Spinner size={SpinnerSize.large} />
      ) : (
        <>
          <Counter number={data.aantalOpdrachtBerichten} text={'Opdrachtberichten'} />
          <Counter number={data.aantalStatusBerichten} text={'Statusberichten'} />
          <Counter number={data.aantalFactuurBerichten} text={'Factuurberichten'} />
        </>
      )}
    </Row>
  )
}

export default CounterPerType
