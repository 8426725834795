import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IStateMachine, RootState } from '../../index';
import { invokeFetch } from 'services/apiClient';
import { BerichtBezorgingRequest, IBerichtbezorging } from 'interfaces/berichtbezorging';
import { Berichtstatus } from 'enums/berichtStatus';

interface IBerichtbezoringState extends IStateMachine {
    list: Array<IBerichtbezorging>
}

const initState: IBerichtbezoringState = {
    list: [],
    status: 'idle',
    error: '',
}

const fetchBerichtbezorging = createAsyncThunk<Array<IBerichtbezorging>, Berichtstatus, { state: RootState }>(
    'berichtbezorging/fetch',
    async (status: Berichtstatus, thunkApi) => {
        return await invokeFetch<Array<IBerichtbezorging>>(thunkApi, 'GET', `/berichtbezorgingen/${status}`)
    }
)

const fetchBerichtbezorgingMetFilter = createAsyncThunk<Array<IBerichtbezorging>, BerichtBezorgingRequest, { state: RootState }>(
    'berichtbezorging/fetchMetFilter',
    async (request, thunkApi) => {
        var body = {DatumVan: request.datumVan, DatumTm: request.datumTm};
        return await invokeFetch<Array<IBerichtbezorging>>(
            thunkApi,
            'POST',
            `/berichtbezorgingen`,
            body,
            false
        )
    }
)

export const slice = createSlice({
    name: 'berichtbezorging/data',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchBerichtbezorging.pending, (state, action) => {
            state.status = 'pending'
            state.error = ''
        })
        builder.addCase(fetchBerichtbezorging.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.list = action.payload
        })
        builder.addCase(fetchBerichtbezorging.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || ''
        })

        builder.addCase(fetchBerichtbezorgingMetFilter.pending, (state, action) => {
            state.status = 'pending'
            state.error = ''
        })
        builder.addCase(fetchBerichtbezorgingMetFilter.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.list = action.payload
        })
        builder.addCase(fetchBerichtbezorgingMetFilter.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || ''
        })
    },
})

export { fetchBerichtbezorging, fetchBerichtbezorgingMetFilter }

export default slice.reducer;