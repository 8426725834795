import {
    EndpointBezorgen,
    ICreateEndpointBezorgenForm,
    IUpdateEndpointBezorgenForm
} from 'interfaces/endpointBezorgen';
import { IStateMachine, RootState } from '../../index';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { invokeFetch } from 'services/apiClient';

interface IAannemerEndpointsBezorgen extends IStateMachine {
    endpointsBezorgen: EndpointBezorgen[];
}

const initState: IAannemerEndpointsBezorgen = {
    endpointsBezorgen: [],
    status: 'idle',
    error: '',
};

const fetchAannemerEndpointsBezorgen = createAsyncThunk<EndpointBezorgen[], string, { state: RootState; }>(
    'aannemers/endpointsBezorgen/fetch',
    async (aannemerId, thunkApi) => {
        return await invokeFetch<EndpointBezorgen[]>(thunkApi, 'GET', `/aannemers/endpointsBezorgen/${aannemerId}`);
    }
);

const fetchAannemerEndpointBezorgen = createAsyncThunk<EndpointBezorgen, string, { state: RootState; }>(
    'aannemers/endpointsBezorgen/fetchById',
    async (id, thunkApi) => {
        return await invokeFetch<EndpointBezorgen>(thunkApi, 'GET', `/aannemers/endpointsBezorgen/${id}`);
    }
);

interface ICreateEndpointBezorgenArgs {
    data: ICreateEndpointBezorgenForm;
}

const createAannemerendpointsBezorgen = createAsyncThunk<EndpointBezorgen, ICreateEndpointBezorgenArgs, { state: RootState; }>(
    'aannemers/endpointsBezorgen/create',
    async ({ data }, thunkApi) => {
        return await invokeFetch<EndpointBezorgen>(thunkApi, 'POST', `/aannemers/endpointsBezorgen`, data)
    }
);

interface IUpdateEndpointBezorgenArgs {
    data: IUpdateEndpointBezorgenForm;
}

const updateAannemerendpointsBezorgen = createAsyncThunk<EndpointBezorgen, IUpdateEndpointBezorgenArgs, { state: RootState; }>(
    'aannemers/endpointsBezorgen/update',
    async ({ data }, thunkApi) => {
        return await invokeFetch<EndpointBezorgen>(thunkApi, 'PUT', `/aannemers/endpointsBezorgen/${data.id}`, data)
    }
);

const deleteAannemerendpointsBezorgen = createAsyncThunk<EndpointBezorgen, string, { state: RootState; }>(
    'aannemers/endpointsBezorgen/delete',
    async (id, thunkApi) => {
        return await invokeFetch<EndpointBezorgen>(thunkApi, 'DELETE', `/aannemers/endpointsBezorgen/${id}`)
    }
);

export const slice = createSlice({
    name: 'aannemers/endpointsBezorgen',
    initialState: initState,
    reducers: {
        resetAannemerEndpointsBezorgen: state => {
            state.status = initState.status;
            state.error = '';
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchAannemerEndpointsBezorgen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(fetchAannemerEndpointsBezorgen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsBezorgen = action.payload;
        });
        builder.addCase(fetchAannemerEndpointsBezorgen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });

        builder.addCase(createAannemerendpointsBezorgen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(createAannemerendpointsBezorgen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsBezorgen.push(action.payload);
        });
        builder.addCase(createAannemerendpointsBezorgen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });

        builder.addCase(updateAannemerendpointsBezorgen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(updateAannemerendpointsBezorgen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsBezorgen = state.endpointsBezorgen.map(b => b.id === action.payload.id ? action.payload : b);
        });
        builder.addCase(updateAannemerendpointsBezorgen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });


        builder.addCase(deleteAannemerendpointsBezorgen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(deleteAannemerendpointsBezorgen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsBezorgen = state.endpointsBezorgen.filter(b => b.id !== action.payload.id);
        });
        builder.addCase(deleteAannemerendpointsBezorgen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });
    }
});

export const { resetAannemerEndpointsBezorgen } = slice.actions;
export { fetchAannemerEndpointsBezorgen, fetchAannemerEndpointBezorgen, createAannemerendpointsBezorgen ,
    updateAannemerendpointsBezorgen, deleteAannemerendpointsBezorgen};
export default slice.reducer;
