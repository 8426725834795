import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { EndpointOntvangen, endpointOntvangenSchema, ICreateEndpointOntvangenForm } from 'interfaces/endpointOntvangen';
import { getPropertyName } from 'lib/interfaceUtils';
import {
    DefaultButton,
    Icon,
    IIconStyles,
    IStyle,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    TextField
} from '@fluentui/react';
import { useTypedSelector } from 'store';
import { getConfiguration } from 'store/selectors/app';
import '../Common/EndpointProtocolStyle.scss';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { EndpointProtocolType, EndpointProtocolTypeLabelMap } from '../../../interfaces/endpointProtocolType';
import { GetEndpointProtocolOptions } from '../Common/EndpointHelper';
import { GetEndPointOntvangen } from './EndpointOntvangenService';

interface IProps {
    onClose: () => void;
    onAddItemSave: (item: EndpointOntvangen) => Promise<EndpointOntvangen>;
    error?: string;
    status?: string;
}

export const AddEndpointOntvangen = ({onClose, onAddItemSave, error, status}: IProps) => {
    const [result, setResult] = useState<EndpointOntvangen>();
    const {soapApiEndpoint, restApiEndpoint31} = useTypedSelector(getConfiguration);

    const {handleSubmit, control, errors: formErrors, formState} = useForm<ICreateEndpointOntvangenForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(endpointOntvangenSchema),
        defaultValues: {naam: '', protocol: EndpointProtocolType.SOAP},
    });

    const handleSave = () => {
        handleSubmit(onSave)();
    };

    async function onSave(data: EndpointOntvangen) {
        onAddItemSave(data).then((result) => {
            setResult(result);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <div style={headerStyles}>
            {error && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {error}
                </MessageBar>
            )}
            {!result ? (
                <>
                    {error && (
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                            {error}
                        </MessageBar>
                    )}
                    <form onSubmit={handleSubmit(onSave)}>
                        <Controller
                            name={getPropertyName<EndpointOntvangen>('naam')}
                            control={control}
                            render={({onChange, onBlur, value}) => (
                                <TextField
                                    autoComplete="off"
                                    autoFocus={true}
                                    required={true}
                                    label="Naam"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    errorMessage={formErrors.naam?.message}
                                />
                            )}
                        />
                        <Controller
                            name={getPropertyName<EndpointOntvangen>('protocol')}
                            control={control}
                            render={({onChange, onBlur, value}) => (
                                <ChoiceGroup
                                    defaultSelectedKey={EndpointProtocolType.SOAP.toString()}
                                    options={GetEndpointProtocolOptions}
                                    required={true}
                                    onChange={(ev, option) => onChange(parseInt(option?.key ?? '0'))}
                                    label="Protocol"
                                    className="endpoint-protocol"
                                />
                            )}
                        />
                        <input type="submit" style={{visibility: 'hidden'}}/>
                    </form>
                    <div className="add-endpoints-ontvangen-actions flex-container flex-gap-16"
                         style={{justifyContent: 'space-between'}}>
                        <div className="action-left">
                        </div>
                        <div className="action-right flex-container flex-gap-16">
                            <DefaultButton onClick={onClose} text="Annuleren" disabled={status === 'pending'}/>
                            <DefaultButton primary allowDisabledFocus disabled={!formState.isValid}
                                           onClick={handleSave}>
                                {status === 'pending' ? <Spinner size={SpinnerSize.small}/> : 'Opslaan'}
                            </DefaultButton>

                        </div>
                    </div>
                </>
            ) : (
                <ResultUI endpointOntvangen={result} soapApiEndpoint={soapApiEndpoint} restApiEndpoint31={restApiEndpoint31} />
            )}
        </div>
    );
};


interface IResultProps {
    endpointOntvangen?: EndpointOntvangen;
    soapApiEndpoint: string;
    restApiEndpoint31: string;
}


const ResultUI = ({endpointOntvangen, soapApiEndpoint, restApiEndpoint31}: IResultProps) => {
    return (
        <div>
            <div>
                <MessageBar key="warning" messageBarType={MessageBarType.warning} isMultiline={true}>
                    Let op: u kunt het wachtwoord hierna niet meer opvragen.
                </MessageBar>
            </div>
            <TextField autoComplete="off" required={true} label="Naam" value={endpointOntvangen?.naam} readOnly/>
            <TextField
                autoComplete="off"
                label="Protocol"
                value={EndpointProtocolTypeLabelMap[endpointOntvangen?.protocol || EndpointProtocolType.SOAP]}
                readOnly
                styles={{suffix: suffixStyles}}
            />
            <TextField
                autoComplete="off"
                label="Endpoint"
                value={GetEndPointOntvangen(endpointOntvangen?.protocol ?? EndpointProtocolType.SOAP, soapApiEndpoint, restApiEndpoint31)}
                readOnly
                styles={{suffix: suffixStyles}}
                onRenderSuffix={_ => (
                    <Icon
                        iconName="Copy"
                        styles={copyIconStyles}
                        onClick={() => {
                            const endpoint = GetEndPointOntvangen(endpointOntvangen?.protocol ?? EndpointProtocolType.SOAP, soapApiEndpoint, restApiEndpoint31);
                            return navigator.clipboard.writeText(endpoint);
                        }}
                    />
                )}
            />
            <TextField
                autoComplete="off"
                label="Gebruikersnaam"
                value={endpointOntvangen?.gebruikersnaam}
                readOnly
                styles={{
                    suffix: suffixStyles,
                }}
                onRenderSuffix={_ => (
                    <Icon
                        iconName="Copy"
                        styles={copyIconStyles}
                        onClick={() => navigator.clipboard.writeText(endpointOntvangen!.gebruikersnaam)}
                    />
                )}
            />
            <TextField
                autoComplete="off"
                label="Wachtwoord"
                value={endpointOntvangen?.wachtwoordEenmalig}
                readOnly
                styles={{
                    suffix: suffixStyles,
                }}
                onRenderSuffix={_ => (
                    <Icon
                        iconName="Copy"
                        styles={copyIconStyles}
                        onClick={() => navigator.clipboard.writeText(endpointOntvangen!.wachtwoordEenmalig)}
                    />
                )}
            />
        </div>
    );
};

const copyIconStyles: Partial<IIconStyles> = {
    root: {
        cursor: 'pointer',
    },
};

const suffixStyles: IStyle = {
    userSelect: 'none',
};

const headerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    width: '100%',
    borderBottom: '1px solid #efefef',
    boxShadow: '#efefef 0px 0px 9px -1px',
};
