import { IStateMachine, RootState } from '../../index';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { invokeFetch } from 'services/apiClient';
import {
    EndpointOntvangen,
    ICreateEndpointOntvangenForm,
    IUpdateEndpointOntvangenForm
} from 'interfaces/endpointOntvangen';


interface ICorporatieEndpointsOntvangen extends IStateMachine {
    endpointsOntvangen: EndpointOntvangen[];
}

const initState: ICorporatieEndpointsOntvangen = {
    endpointsOntvangen: [],
    status: 'idle',
    error: '',
};

const fetchCorporatieEndpointsOntvangen = createAsyncThunk<EndpointOntvangen[], undefined, { state: RootState; }>(
    'instellingen/endpointsOntvangen/fetch',
    async (_, thunkApi) => {
        return await invokeFetch<EndpointOntvangen[]>(thunkApi, 'GET', '/instellingen/endpointsOntvangen');
    }
);

interface ICreateEndpointOntvangenArgs {
    data: ICreateEndpointOntvangenForm;
}

const createCorporatieEndpointsOntvangen = createAsyncThunk<EndpointOntvangen, ICreateEndpointOntvangenArgs, { state: RootState; }>(
    'instellingen/endpointsOntvangen/create',
    async ({ data }, thunkApi) => {
        return await invokeFetch<EndpointOntvangen>(thunkApi, 'POST', `/instellingen/endpointsOntvangen`, data)
    }
);

interface IUpdateEndpointOntvangenArgs {
    data: IUpdateEndpointOntvangenForm;
}

const updateCorporatieEndpointsOntvangen = createAsyncThunk<EndpointOntvangen, IUpdateEndpointOntvangenArgs, { state: RootState; }>(
    'instellingen/endpointsOntvangen/update',
    async ({ data }, thunkApi) => {
        return await invokeFetch<EndpointOntvangen>(thunkApi, 'PUT', `/instellingen/endpointsOntvangen/${data.id}`, data)
    }
);

const deleteCorporatieEndpointsOntvangen = createAsyncThunk<EndpointOntvangen, string, { state: RootState; }>(
    'instellingen/endpointsOntvangen/delete',
    async (id, thunkApi) => {
        return await invokeFetch<EndpointOntvangen>(thunkApi, 'DELETE', `/instellingen/endpointsOntvangen/${id}`)
    }
);

export const slice = createSlice({
    name: 'instellingen/endpointsOntvangen',
    initialState: initState,
    reducers: {
        resetCorporatieEndpointsOntvangen: state => {
            state.status = initState.status;
            state.error = '';
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCorporatieEndpointsOntvangen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(fetchCorporatieEndpointsOntvangen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsOntvangen = action.payload;
        });
        builder.addCase(fetchCorporatieEndpointsOntvangen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });

        builder.addCase(createCorporatieEndpointsOntvangen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(createCorporatieEndpointsOntvangen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsOntvangen.push(action.payload);
        });
        builder.addCase(createCorporatieEndpointsOntvangen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });

        builder.addCase(updateCorporatieEndpointsOntvangen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(updateCorporatieEndpointsOntvangen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsOntvangen = state.endpointsOntvangen.map(b => b.id === action.payload.id ? action.payload : b);
        });
        builder.addCase(updateCorporatieEndpointsOntvangen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });


        builder.addCase(deleteCorporatieEndpointsOntvangen.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(deleteCorporatieEndpointsOntvangen.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.endpointsOntvangen = state.endpointsOntvangen.filter(b => b.id !== action.payload.id);
        });
        builder.addCase(deleteCorporatieEndpointsOntvangen.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });
    }
});

export const { resetCorporatieEndpointsOntvangen } = slice.actions;
export { fetchCorporatieEndpointsOntvangen, createCorporatieEndpointsOntvangen,
    updateCorporatieEndpointsOntvangen, deleteCorporatieEndpointsOntvangen };
export default slice.reducer;