import React, { useMemo } from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import commandBarStyles from 'styles/commandBarStyles';
import CounterPerType from './CounterPerType';
import CounterPerStatusEnTotaal from './CounterPerStatusEnTotaal';
import DateRangeSelector from 'components/DateRangeSelector/DateRangeSelector';
import { IDateRange } from 'components/DateRangeSelector/IDateRange';
import StatistiekOntvangstEndpoints from './StatistiekOntvangstEndpoints';
import StatistiekBezorgenEndpoints from './StatistiekBezorgenEndpoints';
import { useTypedSelector } from 'store';
import { selectDateFilter } from 'store/selectors/dateFilter';
import { setDateFilterRange } from 'store/actions/dateFilter/dateFiler';
import { useDispatch } from 'react-redux';
import { DateFilterRange } from 'interfaces/dateFilterRange';


const Dashboard: React.FC<{}> = props => {
    const dispatch = useDispatch();
    const dateFilterRange = useTypedSelector(selectDateFilter);
    const dateRange = {
        dateRangeType: dateFilterRange.dateRangeType,
        start: new Date(dateFilterRange.start),
        end: new Date(dateFilterRange.end)
    } as IDateRange;
    const setDateRange = (range: IDateRange) => {
        var dateFilterRange = {
            dateRangeType: range.dateRangeType,
            start: range.start.toISOString(),
            end: range.end.toISOString()
        } as DateFilterRange
        dispatch(setDateFilterRange(dateFilterRange));
    }
    
    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'pagetitle',
                onRender: () => (
                    <div className="mutatielog-title page-title">Dashboard</div>
                )
            },
        ],
        []
    );

    const onRefresh = () => {
        setDateRange({...dateRange});
    };
    const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'refresh',
                text: 'Vernieuwen',
                iconProps: { iconName: 'Refresh', className: 'icon' },
                split: false,
                ariaLabel: 'Vernieuwen',
                onClick: onRefresh,
            },
        ],
        [setDateRange, dateRange]
    );

    function onDateRangeChanged(dateRange: IDateRange) {
        setDateRange({...dateRange});
    }

    return (
        <div className="dashboard" style={dashboardStyles}>
            <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles}/>
            <div className="dashboard-daterange" style={dateRangeStyles}>
                <DateRangeSelector dateRange={dateRange} onDateRangeChange={onDateRangeChanged}></DateRangeSelector>
            </div>
            <div className="dashboard-content" style={contentStyles}>
                <CounterPerStatusEnTotaal dateRange={dateRange}/>
                <CounterPerType dateRange={dateRange} />
                <StatistiekOntvangstEndpoints dateRange={dateRange} />
                <StatistiekBezorgenEndpoints dateRange={dateRange} />
            </div>
        </div>
  )
};
export default Dashboard

const dashboardStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
};

const contentStyles: React.CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
};

const dateRangeStyles: React.CSSProperties = { 
    paddingTop: '16px',
    paddingLeft: '25px',
};
