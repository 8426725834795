import { IconButton, IIconProps, IModalProps, Modal } from '@fluentui/react';
import * as React from 'react';
import './index.scss';

interface IOhkModalProps extends IModalProps {
    title: string;
    onClose?: () => void;
}

const OhkModal = ({title, children, onClose, responsiveMode, ...other} : IOhkModalProps) => {
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    function onCloseButtonClicked(): any {
        if (onClose) {
            onClose();
        }
    }

    return (
        <Modal {...other}  >
            <div className="ohk-modal flex-container flex-column padding-16" style={{width: '800px'}}>
                <div className="ohk-modal-header flex-container">
                    <div className="ohk-modal-header-title page-title">{title}</div>
                    <div className="ohk-modal-header-actions">
                        {!!onClose &&
                            <IconButton
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={onCloseButtonClicked}/>
                        } 
                    </div>
                </div>
                <div className="ohk-modal-content flex-grow flex-container flex-column">
                    {children}
                </div>
            </div>
        </Modal>
    );
};

export default OhkModal;