import { combineReducers } from '@reduxjs/toolkit'
import auth from './auth'
import configuration from './configuration'
import genericError from './genericError'
import me from './me'

export default combineReducers({
  auth,
  configuration,
  genericError,
  me,
})
