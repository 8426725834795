import { IStateMachine } from '../../index';
import { DateHelper } from 'lib/date.helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateFilterRange } from 'interfaces/dateFilterRange';

interface IDateFilterState extends IStateMachine {
    dateRange: DateFilterRange;
}

const initState: IDateFilterState = {
    dateRange: {
        dateRangeType: 1,
        start: DateHelper.formDateToUTCIgnoringTimezoneWithoutTime(new Date())?.toISOString(),
        end: DateHelper.formDateToUTCIgnoringTimezoneWithoutTime(new Date())?.toISOString(),
    } as DateFilterRange,
    status: 'idle',
    error: '',
}

export const dateFilterSlice = createSlice({
    name: 'dateFilter',
    initialState: initState,
    reducers: {
        setDateFilterRange(state, action: PayloadAction<DateFilterRange>) {
            state.dateRange = {
                dateRangeType: action.payload.dateRangeType,
                start: action.payload.start,
                end: action.payload.end,
            };
        }
    }
});

export const { setDateFilterRange } = dateFilterSlice.actions
export default dateFilterSlice.reducer