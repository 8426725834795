export const getImageInitialsFromName = (name: string): string => {
  let initials = '##'
  if (name) {
    const items = name.split(' ')
    initials = items[0][0].toUpperCase()
    initials = initials + (items.length > 1 ? items[items.length - 1][0].toUpperCase() : '#')
  }

  return initials
}
