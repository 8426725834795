import React, { useEffect } from 'react'
import {
  DefaultButton,
  IContextualMenuProps,
  IStackStyles,
  IStackTokens,
  MessageBar,
  Stack,
  Text,
  MessageBarType,
  Panel,
  PanelType,
} from '@fluentui/react'

const stackTokens: IStackTokens = { childrenGap: 10 }
const stackStyles: IStackStyles = {
  root: {
    marginTop: 20,
  },
}

interface IQuickAddProps {
  entityName: string
  isOpen: boolean
  onCancel: () => void
  onSave: () => void
  onSaveAndCreateAnother?: () => void
  saving: boolean
  error?: string
  warning?: string
  renderOkButton?: boolean
}

const QuickAdd: React.FC<IQuickAddProps> = ({
  entityName,
  isOpen,
  onCancel,
  onSave,
  onSaveAndCreateAnother,
  saving,
  error,
  warning,
  renderOkButton,
  children,
}) => {
  const menuProps: IContextualMenuProps | undefined = React.useMemo(() => {
    if (!onSaveAndCreateAnother) return undefined

    return {
      items: [
        {
          key: 'opslaanEnNieuweMaken',
          text: 'Opslaan en nieuwe maken',
          onClick: onSaveAndCreateAnother,
        },
      ],
    }
  }, [onSaveAndCreateAnother])

  const footerContent = React.useCallback(
    () => (
      <Stack horizontal wrap tokens={stackTokens}>
        {renderOkButton ? (
          <DefaultButton
            text="Ok"
            primary
            split
            splitButtonAriaLabel="See 2 options"
            aria-roledescription="split button"
            onClick={onCancel}
          />
        ) : (
          <>
            <DefaultButton
              text="Opslaan"
              type="submit"
              primary
              split
              splitButtonAriaLabel="See 2 options"
              aria-roledescription="split button"
              menuProps={menuProps}
              onClick={onSave}
              disabled={saving}
            />
            <DefaultButton text="Annuleren" onClick={onCancel} />
          </>
        )}
      </Stack>
    ),
    [onSave, onCancel, renderOkButton]
  )

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        onSave()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])

  return (
    <div className="quickAddWrapper">
      <Panel
        isOpen={isOpen}
        onDismiss={onCancel}
        type={PanelType.medium}
        isFooterAtBottom={true}
        onRenderFooterContent={footerContent}
        closeButtonAriaLabel="Sluiten"
        headerText={`Snelle invoer: ${entityName}`}
      >
        <Stack styles={stackStyles} tokens={stackTokens}>
          {error && (
            <MessageBar key={error} messageBarType={MessageBarType.error} isMultiline={true}>
              {error}
            </MessageBar>
          )}
          {warning && (
            <MessageBar key={warning} messageBarType={MessageBarType.warning} isMultiline={true}>
              {warning}
            </MessageBar>
          )}
          <Text variant="mediumPlus">{entityName} Details</Text>
          <Stack tokens={stackTokens}>{children}</Stack>
        </Stack>
      </Panel>
    </div>
  )
}

export default QuickAdd
