import * as y from 'yup';
import * as m from '../lib/constants/validationMessages';

export interface EndpointBezorgen {
    id: number;
    naam: string;
    protocol: number;
    endpointUrl: string;
    gebruikersnaam: string;
    wachtwoord: string;
    geblokkeerd: boolean;
    aannemerId: number;
}

export interface ICreateEndpointBezorgenForm {
    naam: string
    protocol: number;
    gebruikersnaam?: string
    wachtwoord?: string
    endpointUrl?: string
    aannemerId?: number;
}

export interface IUpdateEndpointBezorgenForm {
    id: number
    naam: string
    protocol: number;
    gebruikersnaam: string
    wachtwoord: string
    endpointUrl: string
    geblokkeerd: boolean
}

export const endpointBezorgenSchema = y.object({
    naam: y.string().required(m.REQUIRED).max(100, m.STR_MAX_LENGTH_100),
    protocol: y.number().required(m.REQUIRED).oneOf([1, 2], "Protocol moet 1 (SOAP) of 2 (REST) zijn"),
    gebruikersnaam: y.string().required(m.REQUIRED).max(100, m.STR_MAX_LENGTH_100),
    wachtwoord: y.string().required(m.REQUIRED).max(100, m.STR_MAX_LENGTH_100),
    endpointUrl: y.string().required(m.REQUIRED).max(200, m.STR_MAX_LENGTH_200)
        .when('x', (x: any, schema) => {
            // URL validation does not accept localhost and ports, therefore skip the validation during development.
            if (process.env.NODE_ENV !== 'development') {
                return schema.url(m.URL).typeError(m.URL);
            }
        }),
});


export const endpointBezorgenUpdateSchema = y.object({
    naam: y.string().required(m.REQUIRED).max(100, m.STR_MAX_LENGTH_100),
    protocol: y.number().required(m.REQUIRED).oneOf([1, 2], "Protocol moet 1 (SOAP) of 2 (REST) zijn"),
    gebruikersnaam: y.string().required(m.REQUIRED).max(100, m.STR_MAX_LENGTH_100),
    wachtwoord: y.string().max(100, m.STR_MAX_LENGTH_100),
    endpointUrl: y.string().required(m.REQUIRED).max(200, m.STR_MAX_LENGTH_200)
        .when('x', (x: any, schema) => {
            // URL validation does not accept localhost and ports, therefore skip the validation during development.
            if (process.env.NODE_ENV !== 'development') {
                return schema.url(m.URL).typeError(m.URL);
            }
        }),
});

