import { combineReducers } from '@reduxjs/toolkit'
import appReducer from './actions/app'
import aannemersReducer from './actions/aannemers'
import deelnemersReducer from './actions/deelnemers'
import profileReducer from './actions/profile'
import werkwijzesReducer from './actions/werkwijzes'
import instellingenReducer from './actions/instellingen'
import berichtbezorgingReducer from './actions/berichtbezorging'
import blobsReducer from './actions/blobs'
import dashboardReducer from './actions/dashboard'
import mutatielogReducer from './actions/mutatielog'
import mutatielogItemReducer from './actions/mutatielogItem'
import corporatieReducer from './actions/corporatie'
import aannemersImportReducer from './actions/importAannemers'
import dateFilterReducer from './actions/dateFilter/dateFiler'

const rootReducer = combineReducers({
  app: appReducer,
  aannemers: aannemersReducer,
  deelnemers: deelnemersReducer,
  profile: profileReducer,
  werkwijzes: werkwijzesReducer,
  instellingen: instellingenReducer,
  berichtbezorgingen: berichtbezorgingReducer,
  blobs: blobsReducer,
  dashboard: dashboardReducer,
  mutatielog: mutatielogReducer,
  mutatielogItem: mutatielogItemReducer,
  corporatie: corporatieReducer,
  importAannemers: aannemersImportReducer,
  dateFilter: dateFilterReducer,
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
