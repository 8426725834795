import { Callout, ICalloutContentStyles, Link, Stack, Text } from '@fluentui/react'
import React from 'react'

import IGraphUserProfile from 'interfaces/graphUserProfile'
import { useMsal } from '@azure/msal-react'

const calloutStyles: Partial<ICalloutContentStyles> = {
  root: {
    width: '250px',
    maxWidth: '400px',
    height: '125px',
    backgroundColor: '#fff',
    top: '48px !important',
    right: '0px !important',
  },
}

interface IProfileCalloutProps {
  visible: boolean
  onDismiss: () => void
  targetRef: React.RefObject<Element>
  profile: IGraphUserProfile
}

const ProfileCallout: React.FC<IProfileCalloutProps> = ({ visible, onDismiss, targetRef, profile }) => {
  const { instance } = useMsal()

  const logout = () => {
    instance.logout()
  }

  return (
    <>
      {visible && (
        <Callout onDismiss={onDismiss} setInitialFocus styles={calloutStyles} target={targetRef}>
          <Stack styles={{ root: { margin: 10, textAlign: 'right' } }} tokens={{ childrenGap: 5 }}>
            <Text
              variant="medium"
              styles={{ root: { fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden' } }}
            >
              {profile.displayName}
            </Text>
            <Text variant="medium" styles={{ root: { textOverflow: 'ellipsis', overflow: 'hidden' } }}>
              {profile.mail}
            </Text>
            <Link styles={{ root: { textAlign: 'right' } }} onClick={logout}>
              Uitloggen
            </Link>
          </Stack>
        </Callout>
      )}
    </>
  )
}

export default ProfileCallout
