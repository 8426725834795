import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStateMachine, RootState } from 'store'
import { ICreateWerkwijzeForm, IWerkwijze } from 'interfaces/werkwijze'
import { invokeFetch } from 'services/apiClient'

const initState: IStateMachine = {
  status: 'idle',
  error: '',
}

interface ICreateWerkwijzeArgs {
  aannemerId: number
  data: ICreateWerkwijzeForm
}

const createWerkwijze = createAsyncThunk<IWerkwijze, ICreateWerkwijzeArgs, { state: RootState }>(
  'werkwijzes/create',
  async ({ aannemerId, data }, thunkApi) => {
    return await invokeFetch<IWerkwijze>(thunkApi, 'POST', `/aannemers/${aannemerId}/werkwijzes`, {
      ...data,
    } as IWerkwijze)
  }
)

interface IUpdateWerkwijzeArgs {
  id: number
  aannemerId: number
  data: ICreateWerkwijzeForm
}

const updateWerkwijze = createAsyncThunk<IWerkwijze, IUpdateWerkwijzeArgs, { state: RootState }>(
  'werkwijzes/update',
  async ({ id, aannemerId, data }, thunkApi) => {
    return await invokeFetch<IWerkwijze>(thunkApi, 'PATCH', `/aannemers/${aannemerId}/werkwijzes/${id}/`, {
      id,
      ...data,
    } as IWerkwijze)
  }
)

export const slice = createSlice({
  name: 'werkwijzes/upsert',
  initialState: initState,
  reducers: {
    resetUpsertWerkwijze: state => {
      state.error = initState.error
      state.status = initState.status
    },
  },
  extraReducers: builder => {
    builder.addCase(createWerkwijze.pending, (state, action) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(createWerkwijze.fulfilled, (state, action) => {
      state.status = 'succeeded'
    })
    builder.addCase(createWerkwijze.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(updateWerkwijze.pending, (state, action) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(updateWerkwijze.fulfilled, (state, action) => {
      state.status = 'succeeded'
    })
    builder.addCase(updateWerkwijze.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })
  },
})

export const { resetUpsertWerkwijze } = slice.actions
export { createWerkwijze, updateWerkwijze }

export default slice.reducer
