export enum WerkwijzeOptie {
  Portaal = 'Portaal',
  DICO = 'DICO',
  Email = 'Email',
  Geen = 'Geen',
}

export const WerkwijzeOptieLabelMap: { [key in WerkwijzeOptie]: string } = {
  [WerkwijzeOptie.Portaal]: 'Portaal',
  [WerkwijzeOptie.DICO]: 'DICO',
    [WerkwijzeOptie.Email]: 'Email',
  [WerkwijzeOptie.Geen]: 'Geen',
}
