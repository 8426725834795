import React  from 'react';
import './Zoeken.scss';
import { ComboBox, IComboBox, IComboBoxOption, SearchBox } from '@fluentui/react';
import { BerichtZoekTerm } from './BerichtZoekTerm';
import { ZoekVelden } from './ZoekVelden';

interface IProps {
    zoekTerm: BerichtZoekTerm;
    onZoekTermChanged: (zoekTerm: BerichtZoekTerm) => void;
}

const options: IComboBoxOption[] = [
    { key: ZoekVelden.opdrachtnummer, text: ZoekVelden.opdrachtnummer.toString() },
]

export const Zoeken = ({zoekTerm, onZoekTermChanged} : IProps) => {

    const onZoeken = (term: string | undefined): any => {
        onZoekTermChanged({zoekTerm: term + '', veld: zoekTerm.veld});
    };
    const onComboxChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): any => {
        let key = option?.key as ZoekVelden;
        onZoekTermChanged({zoekTerm: zoekTerm.zoekTerm, veld: key});
    };
    
    return (
        <div className="zoeken flex-container flex-gap-16">
            <div className="zoeken-veld">
                <ComboBox
                    selectedKey={zoekTerm.veld}
                    options={options}
                    onChange={onComboxChange}
                />
            </div>
            <div className="zoeken-waarde">
                <SearchBox placeholder="Zoeken" onSearch={onZoeken} onChange={(_, newValue) => onZoeken(newValue)} />
            </div>
        </div>
    )
};

