import React, { useCallback, useMemo, useState } from "react";
import { useTypedSelector } from 'store';
import {
    DefaultButton,
    ITextFieldStyles,
    IToggleStyles,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    TextField
} from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import {
    EndpointOntvangen,
    endpointOntvangenUpdateSchema,
    IUpdateEndpointOntvangenForm
} from 'interfaces/endpointOntvangen';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPropertyName } from 'lib/interfaceUtils';
import FluentToggle from 'components/FluentToggle';
import ConfirmDialog from 'components/ConfirmDialog';
import { getConfiguration } from 'store/selectors/app';
import '../Common/EndpointProtocolStyle.scss';
import { EndpointProtocolTypeLabelMap } from 'interfaces/endpointProtocolType';
import { GetEndPointOntvangen } from './EndpointOntvangenService';

interface IProps {
    endpointEdit: EndpointOntvangen
    onEditItemSave: (item: EndpointOntvangen) => void;
    onDeleteItem: (item: EndpointOntvangen) => Promise<boolean>;
    onClose: () => void;
    error?: string
    status?: string
}

export const EditEndpointOntvangen = ({endpointEdit, onEditItemSave, onDeleteItem, onClose, error, status}:  IProps) => {
    const [deleteItem, setDeleteItem] = useState(false);
    const { soapApiEndpoint, restApiEndpoint31 } = useTypedSelector(getConfiguration);
    
    const defaultValues = useMemo(
        () => ({
            id: endpointEdit?.id,
            naam: endpointEdit?.naam,
            geblokkeerd: endpointEdit?.geblokkeerd ?? false,
        }),
        [endpointEdit]
    );
    
    const {handleSubmit, control, reset, errors: formErrors, formState, setValue, register} = useForm<IUpdateEndpointOntvangenForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(endpointOntvangenUpdateSchema),
        context: {
            endpointOntvangen: endpointEdit
        },
        defaultValues
    });

    const handleSave = () => {
        handleSubmit(onSave)();
    };

    const onSave = useCallback(
        (data: EndpointOntvangen) => {
            onEditItemSave(data);
        },
        []
    );

    const handleDelete = useCallback(() => {
        if (endpointEdit) {
            const result = onDeleteItem(endpointEdit);
            result.then((result) => {
                setDeleteItem(false);
            });
        }
    }, [endpointEdit]);

    function onAnnuleren(): any {
        reset();
        onClose();
    }

    return (
        <div className="flex-container flex-column flex-grow">
            <div style={headerStyles}>
                {error && (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        {error}
                    </MessageBar>
                )}
                {endpointEdit && (
                    <div className="flex-container flex-column flex-grow" style={headerContentStyles}>
                        <form onSubmit={handleSubmit(onSave)}>
                            <input hidden  {...register(getPropertyName<IUpdateEndpointOntvangenForm>('id'))} />
                            <Controller
                                name={getPropertyName<IUpdateEndpointOntvangenForm>('naam')}
                                control={control}
                                render={({ onChange, onBlur, value }) => (
                                    <TextField
                                        autoComplete="off"
                                        autoFocus={true}
                                        required={true}
                                        label="Naam"
                                        styles={labelStyles}
                                        underlined
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        errorMessage={formErrors.naam?.message}
                                    />
                                )}
                            />
                            <TextField
                                autoComplete="off"
                                styles={labelStyles}
                                label="Protocol"
                                underlined
                                readOnly
                                value={EndpointProtocolTypeLabelMap[endpointEdit.protocol]}
                            />                            
                            <TextField
                                autoComplete="off"
                                styles={labelStyles}
                                label="Endpoint"
                                underlined
                                readOnly
                                value={GetEndPointOntvangen(endpointEdit.protocol, soapApiEndpoint, restApiEndpoint31)}
                            />
                            <TextField
                                autoComplete="off"
                                styles={labelStyles}
                                label="Gebruikersnaam"
                                underlined
                                readOnly
                                value={endpointEdit.gebruikersnaam}
                            />
                            <TextField
                                autoComplete="off"
                                styles={labelStyles}
                                label="Wachtwoord"
                                underlined
                                readOnly
                                value="*****************"
                            />

                            <Controller
                                name={getPropertyName<IUpdateEndpointOntvangenForm>('geblokkeerd')}
                                control={control}
                                render={({ onBlur, value }) => (
                                    <FluentToggle
                                        label="Geblokkeerd"
                                        inlineLabel
                                        styles={toggleStyles}
                                        checked={value}
                                        onChange={(_, checked) => {
                                            setValue('geblokkeerd', checked || false);
                                            onBlur();
                                        }}
                                    />
                                )}
                            />
                            <input type="submit" style={{ visibility: 'hidden' }} />
                        </form>
                        <div className="edit-endpoints-ontvangen-actions flex-container flex-gap-16" style={{justifyContent: 'space-between'}}>
                            <div className="action-left">
                                <DefaultButton text="Verwijderen"
                                               onClick={() => setDeleteItem(true)}
                                               iconProps={{ iconName: 'Delete', className: 'icon' }} />
                            </div>
                            <div className="action-right flex-container flex-gap-16">
                                <DefaultButton onClick={onAnnuleren} text="Annuleren" />
                                <DefaultButton primary allowDisabledFocus disabled={!formState.isValid} onClick={handleSave}>
                                    {status === 'pending' ? <Spinner size={SpinnerSize.small} /> : 'Opslaan'}
                                </DefaultButton>

                            </div>
                        </div>
                        {deleteItem && (
                            <ConfirmDialog
                                title="Endpoint verwijderen"
                                subText={[
                                    `Weet u zeker dat u het endpoint wilt verwijderen?`
                                ]}
                                onCancel={() => setDeleteItem(false)}
                                onOk={handleDelete}
                                loading={status === 'pending'}
                            />
                        )}

                    </div>
                )}
            </div>
        </div>
    );
};

const headerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    width: '100%',
    borderBottom: '1px solid #efefef',
    boxShadow: '#efefef 0px 0px 9px -1px',
};
const headerContentStyles: React.CSSProperties = {
    margin: 14,
};

const labelWidth = 175;

const labelStyles: Partial<ITextFieldStyles> = {
    wrapper: {
        borderBottom: '1px solid #efefef',
    },
    subComponentStyles: {
        label: {
            root: {width: labelWidth},
        },
    },
};

const toggleStyles: Partial<IToggleStyles> = {
    label: {
        width: labelWidth,
        paddingLeft: 12,
    },
    root: {
        borderBottom: '1px solid #efefef',
        height: 32,
    },
};