import * as y from 'yup'
import * as m from 'lib/constants/validationMessages'
import { IWerkwijze } from './werkwijze'
import { AppDispatch } from 'store'
import { fetchDeelnemer } from 'store/actions/deelnemers/fetch'
import { unwrapResult } from '@reduxjs/toolkit'

export interface IAannemer {
  id: number
  naam: string
  gln: string
  aadDomainName: string
  aadTenantId: string
  geblokkeerd: boolean
  opdrachtEmailAdres?: string;
  referentie?: string;
  glnCodeType?: string;
  werkwijzes?: IWerkwijze[]
}

export interface IAddAannemerFormContext {
  aannemers: Array<IAannemer>
}

// const testUniqueDomain = (
//   value: string | undefined,
//   { options }: { options: ValidateOptions<Record<string, any>> }
// ): boolean => {
//   const ctx = options.context
//   if (!ctx) return false
//   if (!value) return true // allow empty domain
//
//   const aannemerId = ctx['aannemerId'] as number
//   const aannemers = (ctx['aannemers'] || []) as Array<IAannemer>
//   return !aannemers.some(aannemer => {
//     return aannemerId
//       ? aannemer.aadDomainName === value && aannemer.id !== aannemerId
//       : aannemer.aadDomainName === value
//   })
// }

const validateDomain = async (value: string | undefined, _) => {
  if (!value) return true // allow empty domain

  const url = `https://login.microsoftonline.com/${value}/.well-known/openid-configuration`
  const resp = await fetch(url)

  return resp.ok
}

export const aannemerSchema = y.object({
  naam: y.string().required(m.REQUIRED).max(100, m.STR_MAX_LENGTH_100),
  gln: y
    .string()
    .matches(/\d{13}/, m.GLN)
    .required(m.REQUIRED)
    .test('validateGLN', 'Er bestaat al een aannemer met deze GLN', (value, { options }) => {
      const ctx = options.context
      if (!ctx || !value) return false

      const aannemers = (ctx['aannemers'] || []) as Array<IAannemer>
      return !aannemers.some(x => x.gln === value)
    })
    .test('lookupGLN', 'Deze GLN is niet bekend bij ketenstandaard', async (value, { options }) => {
      const ctx = options.context
      if (!ctx || !value || value.length !== 13) return false

      const dispatch = ctx['dispatch'] as AppDispatch
      return dispatch(fetchDeelnemer({ gln: value }))
        .then(unwrapResult)
        .then(deelnemer => Boolean(deelnemer !== null && deelnemer.bedrijfsnaam))
    }),
  aadDomainName: y
    .string()
    .max(64, 'Domeinnaam mag maximaal 64 karakters bevatten')
    // .test('validateUniqueDomain', 'Er bestaat al een aannemer met deze domeinnaam', testUniqueDomain)
    .test('validateDomain', 'Domeinnaam is niet bekend bij Microsoft', validateDomain),
})

export interface IUpdateAannemerForm {
  aadDomainName: string;
  opdrachtEmailAdres?: string;
  referentie?: string;
  geblokkeerd: boolean;
}

export const updateAannemerSchema = y.object({
  geblokkeerd: y.boolean().required(m.REQUIRED),
  aadDomainName: y
    .string()
    .max(64, 'Domeinnaam mag maximaal 64 karakters bevatten')
    // .test('validateUniqueDomain', 'Er bestaat al een aannemer met deze domeinnaam', testUniqueDomain)
    .test('validateDomain', 'Domeinnaam is niet bekend bij Microsoft', validateDomain),
})
