import React from 'react'
import { Text } from '@fluentui/react'
import { TOGGLE_ON, TOGGLE_OFF } from 'lib/constants/labels'

interface IBooleanColumnProps {
  value: boolean
}

const BooleanColumn: React.FC<IBooleanColumnProps> = ({ value }) => {
  return (
    <span>
      <Text>{value ? TOGGLE_ON : TOGGLE_OFF}</Text>
    </span>
  )
}

export default BooleanColumn
