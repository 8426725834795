import React, { useCallback, useMemo, useState } from "react";
import {
    DefaultButton,
    ITextFieldStyles,
    IToggleStyles,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    TextField
} from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import {
    EndpointBezorgen,
    endpointBezorgenUpdateSchema,
    IUpdateEndpointBezorgenForm
} from 'interfaces/endpointBezorgen';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPropertyName } from 'lib/interfaceUtils';
import FluentToggle from 'components/FluentToggle';
import ConfirmDialog from 'components/ConfirmDialog';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { GetEndpointProtocolOptions } from '../Common/EndpointHelper';
import '../Common/EndpointProtocolStyle.scss'

interface IProps {
    endpointEdit: EndpointBezorgen
    onEditItemSave: (item: EndpointBezorgen) => void;
    onDeleteItem: (item: EndpointBezorgen) => Promise<boolean>;
    onClose: () => void;
    error?: string
    status?: string
}

export const EditEndpointBezorgen = ({endpointEdit, onEditItemSave, onDeleteItem, onClose, error, status}:  IProps) => {
    const [deleteItem, setDeleteItem] = useState(false);

    const defaultValues = useMemo(
        () => ({
            ...endpointEdit,
            wachtwoord: '',
        }),
        [endpointEdit]
    );
    
    const {handleSubmit, control, reset, errors: formErrors, formState, setValue, register} = useForm<IUpdateEndpointBezorgenForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(endpointBezorgenUpdateSchema),
        context: {
            endpointBezorgen: endpointEdit
        },
        defaultValues
    });

    const handleSave = () => {
        handleSubmit(onSave)();
    };

    const onSave = useCallback(
        (data: EndpointBezorgen) => {
            onEditItemSave(data);
        },
        []
    );

    const handleDelete = useCallback(() => {
        if (endpointEdit) {
            const result = onDeleteItem(endpointEdit);
            result.then((result) => {
                setDeleteItem(false);
            });
        }
    }, [endpointEdit]);

    function onAnnuleren(): any {
        reset();
        onClose();
    }

    return (
        <div className="flex-container flex-column flex-grow">
            <div style={headerStyles}>
                {error && (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        {error}
                    </MessageBar>
                )}
                {endpointEdit && (
                    <div className="flex-container flex-column flex-grow" style={headerContentStyles}>
                        <form onSubmit={handleSubmit(onSave)}>
                            <input hidden  {...register(getPropertyName<IUpdateEndpointBezorgenForm>('id'))} />
                            <Controller
                                name={getPropertyName<IUpdateEndpointBezorgenForm>('naam')}
                                control={control}
                                render={({ onChange, onBlur, value }) => (
                                    <TextField
                                        autoComplete="off"
                                        autoFocus={true}
                                        required={true}
                                        label="Naam"
                                        styles={labelStyles}
                                        underlined
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        errorMessage={formErrors.naam?.message}
                                    />
                                )}
                            />
                            <Controller
                                name={getPropertyName<EndpointBezorgen>('protocol')}
                                control={control}
                                render={({onChange, onBlur, value}) => (
                                    <ChoiceGroup
                                        defaultSelectedKey={value.toString()}
                                        options={GetEndpointProtocolOptions}
                                        required={true}
                                        onChange={(ev, option) => {
                                            onChange(parseInt(option?.key ?? '0'));
                                        }}
                                        label="Protocol"
                                        className='endpoint-protocol endpoint-protocol-edit'
                                    />
                                )}
                            />
                            <Controller
                                name={getPropertyName<IUpdateEndpointBezorgenForm>('endpointUrl')}
                                control={control}
                                render={({ onChange, onBlur, value }) => (
                                    <TextField
                                        autoComplete="off"
                                        required={true}
                                        label="Endpoint URL"
                                        styles={labelStyles}
                                        underlined
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        errorMessage={formErrors.endpointUrl?.message}
                                    />
                                )}
                            />
                            <Controller
                                name={getPropertyName<IUpdateEndpointBezorgenForm>('gebruikersnaam')}
                                control={control}
                                render={({ onChange, onBlur, value }) => (
                                    <TextField
                                        autoComplete="off"
                                        required={true}
                                        label="Gebruikersnaam"
                                        styles={labelStyles}
                                        underlined
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        errorMessage={formErrors.gebruikersnaam?.message}
                                    />
                                )}
                            />
                            <Controller
                                name={getPropertyName<IUpdateEndpointBezorgenForm>('wachtwoord')}
                                control={control}
                                render={({ onChange, onBlur, value }) => (
                                    <TextField
                                        autoComplete="off"
                                        required={true}
                                        label="Wachtwoord"
                                        styles={labelStyles}
                                        underlined
                                        type="password"
                                        canRevealPassword
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        errorMessage={formErrors.wachtwoord?.message}
                                        placeholder='*****************'
                                    />
                                )}
                            />
                            <Controller
                                name={getPropertyName<IUpdateEndpointBezorgenForm>('geblokkeerd')}
                                control={control}
                                render={({ onBlur, value }) => (
                                    <FluentToggle
                                        label="Geblokkeerd"
                                        inlineLabel
                                        styles={toggleStyles}
                                        checked={value}
                                        onChange={(_, checked) => {
                                            setValue('geblokkeerd', checked || false);
                                            onBlur();
                                        }}
                                    />
                                )}
                            />
                            <input type="submit" style={{ visibility: 'hidden' }} />
                        </form>
                        <div className="edit-endpoints-bezorgen-actions flex-container flex-gap-16" style={{justifyContent: 'space-between'}}>
                            <div className="action-left">
                                <DefaultButton text="Verwijderen"
                                               onClick={() => setDeleteItem(true)}
                                               iconProps={{ iconName: 'Delete', className: 'icon' }} />
                            </div>
                            <div className="action-right flex-container flex-gap-16">
                                <DefaultButton onClick={onAnnuleren} text="Annuleren" />
                                <DefaultButton primary allowDisabledFocus disabled={!formState.isValid} onClick={handleSave}>
                                    {status === 'pending' ? <Spinner size={SpinnerSize.small} /> : 'Opslaan'}
                                </DefaultButton>

                            </div>
                        </div>
                        {deleteItem && (
                            <ConfirmDialog
                                title="Endpoint verwijderen"
                                subText={[
                                    `Weet u zeker dat u het endpoint wilt verwijderen?`
                                ]}
                                onCancel={() => setDeleteItem(false)}
                                onOk={handleDelete}
                                loading={status === 'pending'}
                            />
                        )}

                    </div>
                )}
            </div>
        </div>
    );
};


const headerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    width: '100%',
    borderBottom: '1px solid #efefef',
    boxShadow: '#efefef 0px 0px 9px -1px',
};
const headerContentStyles: React.CSSProperties = {
    margin: 14,
};

const labelWidth = 175;

const labelStyles: Partial<ITextFieldStyles> = {
    wrapper: {
        borderBottom: '1px solid #efefef',
    },
    subComponentStyles: {
        label: {
            root: {width: labelWidth},
        },
    },
};

const toggleStyles: Partial<IToggleStyles> = {
    label: {
        width: labelWidth,
        paddingLeft: 12,
    },
    root: {
        borderBottom: '1px solid #efefef',
        height: 32,
    },
};