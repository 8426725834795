import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStateMachine } from 'store'
import { RootState } from 'store/rootReducer'
import { invokeFetch } from 'services/apiClient'
import { DashboardDateRange, IDashboardStatistiekDetail } from 'interfaces/dashboard'

interface IDashboardState extends IStateMachine {
  data: IDashboardStatistiekDetail
}

const initState: IDashboardState = {
  data: {
    aantalFactuurBerichten: 0,
    aantalOpdrachtBerichten: 0,
    aantalStatusBerichten: 0,
  },
  status: 'idle',
  error: '',
}

const fetchStatistiekenPerType = createAsyncThunk<IDashboardStatistiekDetail, DashboardDateRange, { state: RootState }>(
  'dashboard/StatistiekenPerType/fetch',
  async (dashboardDateRange, thunkApi) => {
    var body = {DatumVan: dashboardDateRange.datumVan, DatumTm: dashboardDateRange.datumTm};
    return await invokeFetch<IDashboardStatistiekDetail>(
      thunkApi,
      'POST',
        `/dashboard/statistieken/type`,
        body,
      false
    )
  }
)

export const slice = createSlice({
  name: 'dashboard/StatistiekenPerType',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStatistiekenPerType.pending, (state, action) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(fetchStatistiekenPerType.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(fetchStatistiekenPerType.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })
  },
})

export { fetchStatistiekenPerType }

export default slice.reducer
