import React, { useEffect, useState } from 'react';
import { peekResult, useAppDispatch, useTypedSelector } from 'store';
import {
    EndpointBezorgen,
    ICreateEndpointBezorgenForm,
    IUpdateEndpointBezorgenForm
} from 'interfaces/endpointBezorgen';
import { unwrapResult } from '@reduxjs/toolkit';
import { getAannemerEndpointsBezorgen, getAannemerEndpointsOntvangen } from 'store/selectors/aannemers';
import {
    createAannemerendpointsBezorgen,
    deleteAannemerendpointsBezorgen,
    fetchAannemerEndpointsBezorgen,
    resetAannemerEndpointsBezorgen,
    updateAannemerendpointsBezorgen,
} from 'store/actions/aannemers/endpointsBezorgen';
import { EndpointsBezorgen } from 'components/Endpoints/EndpointsBezorgen/EndpointsBezorgen';
import { EndpointsOntvangen } from 'components/Endpoints/EndpointsOntvangen/EndpointsOntvangen';
import {
    createAannemerEndpointsOntvangen,
    deleteAannemerEndpointsOntvangen,
    fetchAannemerEndpointsOntvangen,
    resetAannemerEndpointsOntvangen,
    updateAannemerEndpointsOntvangen
} from 'store/actions/aannemers/endpointsOntvangen';
import {
    EndpointOntvangen,
    ICreateEndpointOntvangenForm,
    IUpdateEndpointOntvangenForm
} from 'interfaces/endpointOntvangen';

interface IProps {
    aannemerId: number;
}

export const Endpoints = ({aannemerId}: IProps) => {
    const dispatch = useAppDispatch();
    const [refetch, setRefetch] = useState(false);
    const {status: statusBezorgen, endpointsBezorgen, error: errorBezorgen} = useTypedSelector(getAannemerEndpointsBezorgen);
    const {status: statusOntvangen, endpointsOntvangen, error: errorOntvangen} = useTypedSelector(getAannemerEndpointsOntvangen);

    useEffect(() => {
        dispatch(fetchAannemerEndpointsBezorgen(aannemerId.toString()));
        dispatch(fetchAannemerEndpointsOntvangen(aannemerId.toString()));

    }, [dispatch]);

    useEffect(() => {
        if (refetch) {
            dispatch(fetchAannemerEndpointsBezorgen(aannemerId.toString()));
            dispatch(fetchAannemerEndpointsOntvangen(aannemerId.toString()));
            setRefetch(false);
        }
    }, [dispatch, refetch]);

    // Endpoints Bezorgen
    async function onAddItemBezorgen(item: ICreateEndpointBezorgenForm) {
        item.aannemerId = aannemerId;
        return dispatch(createAannemerendpointsBezorgen({data: item})).then(unwrapResult);
    }

    async function onEditItemBezorgen(item: IUpdateEndpointBezorgenForm): Promise<EndpointBezorgen> {
        await onResetBezorgen();
        return dispatch(updateAannemerendpointsBezorgen({ data: item })).then(unwrapResult);
    }

    async function onDeleteItemBezorgen(item: EndpointBezorgen): Promise<boolean> {
        const result = await dispatch(deleteAannemerendpointsBezorgen(item.id.toString())).then(peekResult);
        if (result) {
            dispatch(fetchAannemerEndpointsBezorgen(aannemerId.toString()));
        }
        return result;
    }

    async function onResetBezorgen() {
        await dispatch(resetAannemerEndpointsBezorgen());
    }

    // Endpoints ontvangen
    async function onAddItemOntvangen(item: ICreateEndpointOntvangenForm) {
        item.aannemerId = aannemerId;
        return dispatch(createAannemerEndpointsOntvangen({data: item})).then(unwrapResult);
    }

    async function onEditItemOntvangen(item: IUpdateEndpointOntvangenForm): Promise<EndpointOntvangen> {
        await onResetOntvangen();
        return dispatch(updateAannemerEndpointsOntvangen({ data: item })).then(unwrapResult);
    }

    async function onDeleteItemOntvangen(item: EndpointOntvangen): Promise<boolean> {
        const result = await dispatch(deleteAannemerEndpointsOntvangen(item.id.toString())).then(peekResult);
        if (result) {
            dispatch(fetchAannemerEndpointsOntvangen(aannemerId.toString()));
        }
        return result;
    }

    async function onResetOntvangen() {
        await dispatch(resetAannemerEndpointsOntvangen());
    }


    return (
        <>
            <EndpointsBezorgen endpointsBezorgen={endpointsBezorgen}
                               titelEnkelvoud="Endpoint voor bezorgen DICO berichten (ERP aannemer)"
                               titelMeervoud="Endpoints voor bezorgen DICO berichten (ERP aannemer)"
                               isCorporatie={true}
                               onAddItem={onAddItemBezorgen}
                               onEditItem={onEditItemBezorgen}
                               onDeleteItem={onDeleteItemBezorgen}
                               reset={onResetBezorgen}
                               error={errorBezorgen}
                               status={statusBezorgen}
                               aannemerId={aannemerId}
            ></EndpointsBezorgen>
            <EndpointsOntvangen endpointsOntvangen={endpointsOntvangen}
                                titelEnkelvoud="Endpoint voor ontvangen DICO berichten van aannemer"
                                titelMeervoud="Endpoints voor ontvangen DICO berichten van aannemer"
                                isCorporatie={true}
                                onAddItem={onAddItemOntvangen}
                                onEditItem={onEditItemOntvangen}
                                onDeleteItem={onDeleteItemOntvangen}
                                reset={onResetOntvangen}
                                error={errorOntvangen}
                                status={statusOntvangen}
            ></EndpointsOntvangen>
        </>
    )
};
