import React, { useMemo } from 'react'
import OhkTable from '../../../../components/OhkTable'
import { IColumn, Link } from '@fluentui/react'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { IBusinessRulesConfig } from '../models/businessRules'
import { stringToLocaleString } from '../../../../lib/internationalization'
import BooleanColumn from '../../../../components/BooleanColumn'

interface BusinessRulesProps {
  items: IBusinessRulesConfig[];
  setShowBusinessRulesSelectionPopup: (showBusinessRulesSelectionPopup: boolean) => void;
  onEditBusinessRule: (businessRuleId: number) => void;
  isFetching: boolean;
}

const BusinessRulesTable: React.FC<BusinessRulesProps> = ({
                                                            items,
                                                            setShowBusinessRulesSelectionPopup,
                                                            onEditBusinessRule,
                                                            isFetching
                                                          }) => {
  const handleSorting = () => null

  const columns: IColumn[] = useMemo(() =>
      [
        {
          key: getPropertyName<IBusinessRulesConfig>('codeNaam'),
          name: 'Code',
          fieldName: getPropertyName<IBusinessRulesConfig>('codeNaam'),
          minWidth: 50,
          maxWidth: 60,
          isResizable: true,
          data: 'string',
          isSorted: true,
          isSortedDescending: false,
          onRender: (item: IBusinessRulesConfig) => (
            <Link onClick={() => {
                onEditBusinessRule(item.id)
            }}>
              {item.codeNaam}
            </Link>
          )
        },
        {
          key: getPropertyName<IBusinessRulesConfig>('naam'),
          name: 'Business rule',
          fieldName: getPropertyName<IBusinessRulesConfig>('naam'),
          minWidth: 250,
          maxWidth: 300,
          isResizable: true,
          data: 'string'
        },
        {
          key: getPropertyName<IBusinessRulesConfig>('sectie'),
          name: 'Sectie',
          fieldName: getPropertyName<IBusinessRulesConfig>('sectie'),
          minWidth: 60,
          maxWidth: 80,
          isResizable: true,
          data: 'string'
        },
        {
          key: getPropertyName<IBusinessRulesConfig>('datumGewijzigd'),
          name: 'Datum gewijzigd',
          fieldName: getPropertyName<IBusinessRulesConfig>('datumGewijzigd'),
          minWidth: 100,
          maxWidth: 110,
          isResizable: true,
          data: 'date',
          onRender: (item: IBusinessRulesConfig) => (
            <span>{item.datumGewijzigd && stringToLocaleString(item.datumGewijzigd)}</span>
          )
        },
        {
          key: getPropertyName<IBusinessRulesConfig>('actief'),
          name: 'Actief',
          fieldName: getPropertyName<IBusinessRulesConfig>('actief'),
          minWidth: 50,
          maxWidth: 70,
          isResizable: true,
          data: 'boolean',
          onRender: (item: IBusinessRulesConfig) => <BooleanColumn value={item.actief} />
        }
      ],
    [handleSorting])

  const handleGetKey = (item): string => {
    return (item).key
  }

  const handleItemInvoked = () => {
    setShowBusinessRulesSelectionPopup(true)
  }

  return (
    <OhkTable
      disableSort={false}
      columns={columns}
      items={items}
      loading={isFetching}
      onGetKey={handleGetKey}
      onItemInvoked={handleItemInvoked}
    />
  )
}

export default BusinessRulesTable