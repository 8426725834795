import React, { useMemo } from 'react';
import { ImportAannemerValidatieProbleem } from 'interfaces/importAannemersControleResponse';
import { IColumn } from '@fluentui/react';
import { getPropertyName } from 'lib/interfaceUtils';
import OhkTable from 'components/OhkTable';

interface IProps {
    problemen: ImportAannemerValidatieProbleem[]
}

export const ValidatieProblemen = ({problemen} : IProps) => {
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: getPropertyName<ImportAannemerValidatieProbleem>('naam'),
                name: 'Naam aannemer',
                fieldName: getPropertyName<ImportAannemerValidatieProbleem>('naam'),
                data: 'string',
                minWidth: 120,
                maxWidth: 250,
                isResizable: true
            },
            {
                key: getPropertyName<ImportAannemerValidatieProbleem>('referentie'),
                name: 'Externe referentie',
                fieldName: getPropertyName<ImportAannemerValidatieProbleem>('referentie'),
                data: 'string',
                minWidth: 120,
                maxWidth: 150,
                isResizable: true
            },
            {
                key: getPropertyName<ImportAannemerValidatieProbleem>('glnCode'),
                name: 'GLN-code ketenstandaard',
                fieldName: getPropertyName<ImportAannemerValidatieProbleem>('glnCode'),
                data: 'string',
                minWidth: 120,
                maxWidth: 150,
                isResizable: true
            },
            {
                key: getPropertyName<ImportAannemerValidatieProbleem>('email'),
                name: 'Emailadres opdracht',
                fieldName: getPropertyName<ImportAannemerValidatieProbleem>('email'),
                data: 'string',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true
            },
            {
                key: getPropertyName<ImportAannemerValidatieProbleem>('validatieProbleem'),
                name: 'Validatie probleem',
                fieldName: getPropertyName<ImportAannemerValidatieProbleem>('validatieProbleem'),
                data: 'string',
                minWidth: 150,
                maxWidth: 250,
                isResizable: true,
                isMultiline: true
            }
        ], []);

    function handleGetKey(item): string {
        return (item as ImportAannemerValidatieProbleem).key;
    }

    return (
        <div className="validatie-problemen">
            <OhkTable columns={columns} items={problemen} loading={false} onGetKey={handleGetKey}></OhkTable>
        </div>
    );
};
