import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStateMachine } from 'store'
import { RootState } from 'store/rootReducer'
import { invokeFetch } from 'services/apiClient'
import { IEndpointPrestatie } from 'interfaces/applicatiePrestatie'
import { DashboardDateRange } from 'interfaces/dashboard';

interface IDashboardState extends IStateMachine {
  endpointOntvangst: IEndpointPrestatie[]
  endpointBezorgen: IEndpointPrestatie[]
}

const initState: IDashboardState = {
  endpointOntvangst: [],
  endpointBezorgen: [],
  status: 'idle',
  error: '',
};

const fetchEndpointsOntvangenPrestatie = createAsyncThunk<Array<IEndpointPrestatie>, DashboardDateRange, { state: RootState }>(
  'dashboard/statistieken/endpoints/ontvangst',
  async (dashboardDateRange, thunkApi) => {
    var body = {DatumVan: dashboardDateRange.datumVan, DatumTm: dashboardDateRange.datumTm};
    return await invokeFetch<Array<IEndpointPrestatie>>(
      thunkApi,
      'POST',
        `/dashboard/statistieken/endpointontvangst`,
        body,
      false
    )
  }
);

const fetchEndpointsBezorgenPrestatie = createAsyncThunk<Array<IEndpointPrestatie>, DashboardDateRange, { state: RootState }>(
    'dashboard/statistieken/endpoints/bezorgen',
    async (dashboardDateRange, thunkApi) => {
      var body = {DatumVan: dashboardDateRange.datumVan, DatumTm: dashboardDateRange.datumTm};
      return await invokeFetch<Array<IEndpointPrestatie>>(
          thunkApi,
          'POST',
          `/dashboard/statistieken/endpointbezorgen`,
          body,
          false
      )
    }
);


export const slice = createSlice({
  name: 'dashboard/statistieken/endpoints',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchEndpointsOntvangenPrestatie.pending, (state, action) => {
      state.status = 'pending';
      state.error = '';
      state.endpointOntvangst = [];
    });
    builder.addCase(fetchEndpointsOntvangenPrestatie.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.endpointOntvangst = action.payload;
    });
    builder.addCase(fetchEndpointsOntvangenPrestatie.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || ''
    })

    builder.addCase(fetchEndpointsBezorgenPrestatie.pending, (state, action) => {
      state.status = 'pending';
      state.error = '';
      state.endpointBezorgen = [];
    });
    builder.addCase(fetchEndpointsBezorgenPrestatie.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.endpointBezorgen = action.payload;
    });
    builder.addCase(fetchEndpointsBezorgenPrestatie.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || ''
    })
    
    
  },
});

export { fetchEndpointsOntvangenPrestatie, fetchEndpointsBezorgenPrestatie }

export default slice.reducer
