import React from 'react'
import {Controller} from 'react-hook-form'
import {Toggle} from '@fluentui/react'
import {TOGGLE_ON, TOGGLE_OFF} from 'lib/constants/labels'

interface IVgtFieldProps {
  name: string
  label: string
  control: any
  checked?: boolean
  showOnOffText: boolean
}

const OhkToggle: React.FC<IVgtFieldProps> = props => {
  return (
    <div>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.checked ?? false}
        render={({onChange, value}) => (
          <Toggle label={props.label}  inlineLabel onText={props.showOnOffText ? TOGGLE_ON : ''} offText={props.showOnOffText ? TOGGLE_OFF : ''}
                  onChange={(e, checked) => {
                    onChange(checked)
                  }}
                  checked={!!value}
                  styles={{
                    root: {padding: '0 0 0 12px'},
                    label: {width: '200px', fontWeight: 'normal'},
                  }}
          />
        )}
      />
    </div>
  )
}

export default OhkToggle
