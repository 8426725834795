import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from 'store';
import pivotStyles from 'styles/pivotStyles';
import { CommandBar, ICommandBarItemProps, Pivot, PivotItem } from '@fluentui/react';
import { getCorporatieEndpointsBezorgen, getInstellingen } from 'store/selectors/instellingen';
import commandBarStyles from 'styles/commandBarStyles';
import { fetchInstellingen } from 'store/actions/instellingen/data';
import Details from './Details';
import { fetchCorporatieEndpointsBezorgen } from 'store/actions/instellingen/endpointsBezorgen';
import { Endpoints } from './endpoints';

enum tabs {
  Instellingen = 'Instellingen',
  Endpoints = 'Endpoints',
}

const Instellingen: React.FC<{}> = props => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [refetch, setRefetch] = useState(false);

  const { status: fetchStatus, instellingen } = useTypedSelector(getInstellingen);
  const { endpointsBezorgen  } = useTypedSelector(getCorporatieEndpointsBezorgen);

  useEffect(() => {
    const fetchInstellingenPromise = dispatch(fetchInstellingen());
    const fetchEndpointsBezorgenPromise = dispatch(fetchCorporatieEndpointsBezorgen());

    return () => {
      fetchInstellingenPromise.abort();
      fetchEndpointsBezorgenPromise.abort();
    }
  }, [dispatch]);

  useEffect(() => {
    if (refetch) {
      dispatch(fetchInstellingen());
      dispatch(fetchCorporatieEndpointsBezorgen());
      setRefetch(false)
    }
  }, [dispatch, refetch]);

  const commandBarItems: ICommandBarItemProps[] = useMemo(
      () => [
        {
          key: 'pagetitle',
          onRender: () => (
              <span className="page-title">Corporatie</span>
          )
        },
      ],
      []
  );
  
  const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'refresh',
        text: 'Vernieuwen',
        iconProps: { iconName: 'Refresh', className: 'icon' },
        split: false,
        ariaLabel: 'Vernieuwen',
        onClick: () => setRefetch(true),
      },
    ],
    [history, setRefetch]
  );

  return (
    <div className="corporatie" style={rootStyles}>
      <div style={headerStyles}>
        <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles} />
        <div style={headerContentStyles}>
          <Pivot styles={pivotStyles} headersOnly={false}>
            <PivotItem
              itemKey={tabs.Instellingen}
              headerText={'Instellingen'}
              headerButtonProps={{
                'data-order': 1,
              }}
            >
              {fetchStatus !== 'pending' && (
                <div>
                  <Details instellingen={instellingen} endpointsBezorgen={endpointsBezorgen} />
                </div>
              )}
            </PivotItem>
            <PivotItem headerText="Endpoints" itemKey={tabs.Endpoints}>
              <div className="corporatie-endpoints scrollbar-auto flex-container flex-column">
                <Endpoints></Endpoints>
              </div>
            </PivotItem>
            
          </Pivot>
        </div>
      </div>
    </div>
  )
};
export default Instellingen

const rootStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  height: '100%',
};

const headerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  width: '100%',
  borderBottom: '1px solid #efefef',
  boxShadow: '#efefef 0px 0px 9px -1px',
  overflow: 'hidden',
};

const headerContentStyles: React.CSSProperties = {
  margin: 14,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
};