import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { IMutatielog } from 'interfaces/mutatielog';
import { fetchMutatielog } from 'store/actions/mutatielog/data';
import { getMutatielog } from 'store/selectors/mutatielog';
import { CommandBar, IColumn, ICommandBarItemProps, Link } from '@fluentui/react';
import { getPropertyName } from 'lib/interfaceUtils';
import './Mutatielog.scss';
import { stringToLocaleString } from 'lib/internationalization';
import commandBarStyles from 'styles/commandBarStyles';
import OhkTable from 'components/OhkTable';

const Mutatielog = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [refetch, setRefetch] = useState(false);

    const { list: items, status: fetchStatus } = useTypedSelector(getMutatielog);

    useEffect(() => {
        const fetchPromise = dispatch(fetchMutatielog());

        return () => {
            fetchPromise.abort()
        }
    }, [dispatch]);

    useEffect(() => {
        if (refetch) {
            dispatch(fetchMutatielog());
            setRefetch(false)
        }
    }, [dispatch, refetch]);

    const handleGetKey = (item): string => {
        return (item as IMutatielog).id.toString()
    };

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'pagetitle',
                onRender: () => (
                    <div className="mutatielog-title page-title">Mutatielog</div>
                )
            },
        ],
        []
    );

    const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'refresh',
                text: 'Vernieuwen',
                iconProps: { iconName: 'Refresh', className: 'icon' },
                split: false,
                ariaLabel: 'Vernieuwen',
                onClick: () => setRefetch(true),
            },
        ],
        [history, setRefetch]
    );

    const handleItemInvoked = useCallback(
        (id: string) => {
            history.push(`/mutatielog/${id}`)
        },
        [history]
    );

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: getPropertyName<IMutatielog>('datum'),
                name: 'Datum',
                fieldName: getPropertyName<IMutatielog>('datum'),
                minWidth: 80,
                maxWidth: 120,
                isResizable: false,
                data: 'string',
                onRender: (item: IMutatielog) => (
                    <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
                        <span>{item.datum && stringToLocaleString(item.datum)}</span>
                    </Link>
                ),
            },
            {
                key: getPropertyName<IMutatielog>('onderdeel'),
                name: 'Onderdeel',
                fieldName: getPropertyName<IMutatielog>('onderdeel'),
                minWidth: 100,
                maxWidth: 140,
                isResizable: false,
                data: 'string',
            },
            {
                key: getPropertyName<IMutatielog>('mutatieType'),
                name: 'Mutatie type',
                fieldName: getPropertyName<IMutatielog>('mutatieType'),
                minWidth: 80,
                maxWidth: 100,
                isResizable: false,
                data: 'string',
            },
            {
                key: getPropertyName<IMutatielog>('gebruikersNaam'),
                name: 'Gebruikersnaam',
                fieldName: getPropertyName<IMutatielog>('gebruikersNaam'),
                minWidth: 100,
                maxWidth: 200,
                isResizable: false,
                data: 'string',
            },
            {
                key: getPropertyName<IMutatielog>('gebruikersEmail'),
                name: 'Gebruikersemail',
                fieldName: getPropertyName<IMutatielog>('gebruikersEmail'),
                minWidth: 100,
                maxWidth: 200,
                isResizable: false,
                data: 'string',
            },
        ],
        []
    );
    
    
    return (
        <div className="mutatielog flex-grow flex-container flex-column">
            <div className="mutatielog-header flex-container">
                <div className="mutatielog-content-action flex-grow">
                    <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles}/>
                </div>
            </div>
            <div className="mutatielog-content flex-grow flex-container flex-column" >
                <div className="mutatielog-content-table flex-grow">
                    <div className="mutatielog-table">
                        <OhkTable
                            disableSort={false}
                            columns={columns}
                            items={items}
                            loading={fetchStatus === 'pending'}
                            onGetKey={handleGetKey}
                            onItemInvoked={handleItemInvoked}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Mutatielog;