import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IAannemer, IUpdateAannemerForm } from 'interfaces/aannemer'
import { invokeFetch } from 'services/apiClient'
import { IStateMachine, RootState } from 'store'

interface ICreateAannemerArgs {
  data: IAannemer
}

const createAannemer = createAsyncThunk<IAannemer, ICreateAannemerArgs, { state: RootState }>(
  'aannemers/create',
  async ({ data }, thunkApi) => {
    return await invokeFetch<IAannemer>(thunkApi, 'POST', `/aannemers`, data)
  }
)

interface IUpdateAannemerArgs {
  id: string
  data: IUpdateAannemerForm
}

const updateAannemer = createAsyncThunk<IAannemer, IUpdateAannemerArgs, { state: RootState }>(
  'aannemers/update',
  async ({ id, data }, thunkApi) => {
    // if email is empty, set it to undefined
    if (data.opdrachtEmailAdres === '') {
        data.opdrachtEmailAdres = undefined;
    }
    return await invokeFetch<IAannemer>(thunkApi, 'PATCH', `/aannemers/${id}`, data)
  }
)

const initState: IStateMachine = {
  status: 'idle',
  error: '',
}

export const slice = createSlice({
  name: 'aannemers/upsert',
  initialState: initState,
  reducers: {
    resetUpsertAannemer: state => {
      state.status = initState.status
      state.error = ''
    },
  },
  extraReducers: builder => {
    builder.addCase(createAannemer.pending, (state, _) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(createAannemer.fulfilled, (state, _) => {
      state.status = 'succeeded'
    })
    builder.addCase(createAannemer.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(updateAannemer.pending, (state, _) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(updateAannemer.fulfilled, (state, _) => {
      state.status = 'succeeded'
    })
    builder.addCase(updateAannemer.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })
  },
})

export const { resetUpsertAannemer } = slice.actions

export { createAannemer, updateAannemer }

export default slice.reducer
