import { IStateMachine, RootState } from '../../index';
import { IMutatielog } from '../../../interfaces/mutatielog';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { invokeFetch } from '../../../services/apiClient';


interface IMutatielogState extends  IStateMachine {
    list: Array<IMutatielog>
}

const initState: IMutatielogState = {
    list: [],
    status: 'idle',
    error: '',
}

const fetchMutatielog = createAsyncThunk<Array<IMutatielog>, undefined, { state: RootState }>(
    'mutatielog/fetch',
    async (_, thunkApi) => {
        return await invokeFetch<Array<IMutatielog>>(thunkApi, 'GET', `/mutatielog`)
    }
)

export const slice = createSlice({
    name: 'mutatielog/data',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchMutatielog.pending, (state, action) => {
            state.status = 'pending'
            state.error = ''
        })
        builder.addCase(fetchMutatielog.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.list = action.payload
        })
        builder.addCase(fetchMutatielog.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || ''
        })        
    }
});


export {fetchMutatielog}

export default slice.reducer;