import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStateMachine } from 'store'
import { RootState } from 'store/rootReducer'
import { invokeFetch } from 'services/apiClient'
import { IInstellingen } from 'interfaces/instellingen'
import { updateInstellingen } from './upsert'

interface IInstellingenState extends IStateMachine {
  instellingen: IInstellingen
}

const initState: IInstellingenState = {
  instellingen: {
    id: 0,
  },
  status: 'idle',
  error: '',
};

const fetchInstellingen = createAsyncThunk<IInstellingen, undefined, { state: RootState }>(
  'instellingen/fetch',
  async (_, thunkApi) => {
    return await invokeFetch<IInstellingen>(thunkApi, 'GET', '/instellingen/instellingen')
  }
);

export const slice = createSlice({
  name: 'instellingen/data',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchInstellingen.pending, (state, action) => {
      state.status = 'pending';
      state.error = ''
    });
    builder.addCase(fetchInstellingen.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.instellingen = action.payload
    });
    builder.addCase(fetchInstellingen.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || ''
    });

    builder.addCase(updateInstellingen.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.instellingen = action.payload
    })
  },
});

export { fetchInstellingen }

export default slice.reducer
