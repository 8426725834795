import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import commandBarStyles from 'styles/commandBarStyles';
import { useAppDispatch, useTypedSelector } from 'store';
import { fetchMutatielogItem } from 'store/actions/mutatielogItem/data';
import { getMutatielogItem } from 'store/selectors/mutatielogItem';
import InputCard from 'components/InputCard';
import  './MutatieLogItem.scss'
import { stringToLocaleString } from 'lib/internationalization';


interface RouterParams {
    mutatielogId: string
}

const MutatieLogItem = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();    
    const { mutatielogId } = useParams<RouterParams>();
    const [refetch, setRefetch] = useState(false);
    const mutatielogItem = useTypedSelector(state => getMutatielogItem(state).item);

    useEffect(() => {
        if (mutatielogId) {
            dispatch(fetchMutatielogItem(mutatielogId))
        }
    }, [dispatch, mutatielogId]);

    useEffect(() => {
        if (refetch && mutatielogId) {
            dispatch(fetchMutatielogItem(mutatielogId));
            setRefetch(false)
        }
    }, [dispatch, refetch, mutatielogId]);    
    
    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'terug',
                iconProps: { iconName: 'Back', className: 'icon' },
                onClick: () => history.goBack(),
                split: true,
            },
            {
                key: 'pagetitle',
                onRender: () => (
                    <div className="mutatielog-title page-title">Mutatielog details</div>
                )
            },
        ],
        [history]
    );

    const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'refresh',
                text: 'Vernieuwen',
                iconProps: { iconName: 'Refresh', className: 'icon' },
                split: false,
                ariaLabel: 'Vernieuwen',
                onClick: () => setRefetch(true),
            },
        ],
        [setRefetch]
    );

    return (
        <div className="mutatielogitem flex-grow flex-container flex-column">
            <div className="mutatielogitem-header flex-container">
                <div className="mutatielogitem-content-action flex-grow">
                    <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles}/>
                </div>
            </div>
            <div className="mutatielogitem-content flex-grow flex-container flex-column">
                <InputCard title="Mutatie log" className="max-width-1200" style={{minWidth: 500, minHeight: 100, flexGrow: 0, marginBottom: 8}}
                           contentStyle={{flexGrow: 0}}>
                    <div className="mutatielogitem-row">
                        <div className="mutatielogitem-row-label">Datum</div>
                        <div className="mutatielogitem-row-value">{stringToLocaleString(mutatielogItem?.datum)}</div>
                    </div>
                    <div className="mutatielogitem-row">
                        <div className="mutatielogitem-row-label">Onderdeel</div>
                        <div className="mutatielogitem-row-value">{mutatielogItem?.onderdeel}</div>
                    </div>
                    <div className="mutatielogitem-row">
                        <div className="mutatielogitem-row-label">Mutatie type</div>
                        <div className="mutatielogitem-row-value">{mutatielogItem?.mutatieType}</div>
                    </div>
                    <div className="mutatielogitem-row">
                        <div className="mutatielogitem-row-label">Gebruikersnaam</div>
                        <div className="mutatielogitem-row-value">{mutatielogItem?.gebruikersNaam}</div>
                    </div>
                    <div className="mutatielogitem-row">
                        <div className="mutatielogitem-row-label">Gebruikersemail</div>
                        <div className="mutatielogitem-row-value">{mutatielogItem?.gebruikersEmail}</div>
                    </div>
                </InputCard>
                <InputCard title="Mutatie details" className="max-width-1200" style={{minWidth: 500, minHeight: 300}} contentStyle={{flexGrow: 1}}>
                    <table>
                        <thead>
                        <tr>
                            <th>Veld</th>
                            <th>Oude waarde</th>
                            <th>Nieuwe waarde</th>
                        </tr>
                        </thead>
                        <tbody>
                        {mutatielogItem?.details.map(detail =>
                            <tr key={detail.index}>
                                <td className={detail.newValue !== detail.oldValue ? 'verschil' : 'zelfde'}>{detail.property}</td>
                                <td className={detail.newValue !== detail.oldValue ? 'verschil' : 'zelfde'}>{detail.oldValue}</td>
                                <td className={detail.newValue !== detail.oldValue ? 'verschil' : 'zelfde'}>{detail.newValue}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </InputCard>
            </div>

        </div>
    );
};

export default MutatieLogItem;