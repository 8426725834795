import React from "react"
import './DateRangeSelector.scss'
import FluentDatePicker from '../FluentDatePicker';
import { Dropdown, DropdownMenuItemType, IDatePickerStyles, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import { DateRangeType } from './DateRangeType';
import { IDateRange } from './IDateRange';
import { DateHelper } from '../../lib/date.helper';

const DateRangeSelector = ({dateRange, onDateRangeChange}) => {
    
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 200 },
    };

    const pickerStyles: Partial<IDatePickerStyles> = {
        textField: { width: 140 },
    }

    const options: IDropdownOption[] = [
        { key: DateRangeType.Vandaag, text: 'Vandaag' },
        { key: DateRangeType.DezeWeek, text: 'Deze week' },
        { key: DateRangeType.DezeMaand, text: 'Deze maand' },
        { key: DateRangeType.DitJaar, text: 'Dit jaar' },
        { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
        { key: DateRangeType.Gisteren, text: 'Gisteren' },
        { key: DateRangeType.VorigeWeek, text: 'Vorige week' },
        { key: DateRangeType.VorigeMaand, text: 'Vorige maand' },
        { key: DateRangeType.VorigeJaar, text: 'Vorig jaar' },
        { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },
        { key: DateRangeType.Laatste3Maanden, text: 'Laatste 3 maanden' },
        { key: DateRangeType.Laatste6Maanden, text: 'Laatste 6 maanden' },
        { key: DateRangeType.Laatste12Maanden, text: 'Laatste 12 maanden' },
        { key: 'divider_3', text: '-', itemType: DropdownMenuItemType.Divider },
        { key: DateRangeType.AnderePeriode, text: 'Andere periode' },
    ];

    const onFormatDate = (date?: Date): string => {
        return !date ? '' : date.getDate() + '-' + (date.getMonth() + 1) + '-' + (date.getFullYear() );
    };
    
   
    function SetDateRange(dateRangeType: DateRangeType) {
        
        let today =  toUtcDate(new Date()) as Date;
        let range = {dateRangeType: dateRangeType, start: today, end: today } as IDateRange;
        const dayOfTheWeek = getDayFirstDayIsMonday(today.getDay());
        switch (dateRangeType) {
            case DateRangeType.Vandaag:
                range.start = today;
                range.end = today;
                break;
            case DateRangeType.DezeWeek:
                const thisWeekStart = toUtcDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfTheWeek));
                const thisWeekEnd = toUtcDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfTheWeek + 6));
                range.start = thisWeekStart;
                range.end = thisWeekEnd;
                break;
            case DateRangeType.DezeMaand:
                const thisMonthStart = toUtcDate(new Date(today.getFullYear(), today.getMonth(), 1));
                const thisMonthEnd = toUtcDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
                range.start = thisMonthStart;
                range.end = thisMonthEnd;
                break;
            case DateRangeType.DitJaar:
                const thisYearStart = toUtcDate(new Date(today.getFullYear(), 0, 1));
                const thisYearEnd = toUtcDate(new Date(today.getFullYear(), 11, 31));
                range.start = thisYearStart;
                range.end = thisYearEnd;
                break;
            case DateRangeType.Gisteren:
                const yesterday = today;
                yesterday.setDate(today.getDate() - 1);
                const yesterdayUTC = toUtcDate(yesterday); 
                range.start = yesterdayUTC;
                range.end = yesterdayUTC;
                break;
            case DateRangeType.VorigeWeek:
                const lastWeekStart = toUtcDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfTheWeek - 7));
                const lastWeekEnd =toUtcDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfTheWeek - 1));
                range.start = lastWeekStart;
                range.end = lastWeekEnd;
                break;
            case DateRangeType.VorigeMaand:
                const lastMonthStart = toUtcDate(new Date(today.getFullYear(), today.getMonth() - 1, 1));
                const lastMonthEnd = toUtcDate(new Date(today.getFullYear(), today.getMonth(), 0));
                range.start = lastMonthStart;
                range.end = lastMonthEnd;
                break;
            case DateRangeType.VorigeJaar:
                const lastYearStart = toUtcDate(new Date(today.getFullYear() - 1, 0, 1));
                const lastYearEnd = toUtcDate(new Date(today.getFullYear(), 0, 0));
                range.start = lastYearStart;
                range.end = lastYearEnd;
                break;
            case DateRangeType.Laatste3Maanden:
                const last3MonthsStart = toUtcDate(new Date(today.getFullYear(), today.getMonth() - 2, 1));
                const last3MonthsEnd = toUtcDate(new Date(today.getFullYear(), today.getMonth(), 0));
                range.start = last3MonthsStart;
                range.end = last3MonthsEnd;
                break;
            case DateRangeType.Laatste6Maanden:
                const last6MonthsStart = toUtcDate(new Date(today.getFullYear(), today.getMonth() - 5, 1));
                const last6MonthsEnd = toUtcDate(new Date(today.getFullYear(), today.getMonth(), 0));
                range.start = last6MonthsStart;
                range.end = last6MonthsEnd;
                break;
            case DateRangeType.Laatste12Maanden:
                const last12MonthsStart = toUtcDate(new Date(today.getFullYear(), today.getMonth() - 11, 1));
                const last12MonthsEnd = toUtcDate(new Date(today.getFullYear(), today.getMonth(), 0));
                range.start = last12MonthsStart;
                range.end = last12MonthsEnd;
                break;
            case DateRangeType.AnderePeriode:
                break;
        }
        
        dateRange.dateRangeType = dateRangeType;
        if (dateRangeType !== DateRangeType.AnderePeriode)  {
            dateRange.start = range.start;
            dateRange.end = range.end;
        }
        
        // dateRange = {...dateRange};
        onDateRangeChange({...dateRange});
    }
    
    function getDayFirstDayIsMonday(dayOfWeek: number): number {
        switch (dayOfWeek) {
            case 0:
                return 6;
            default:
                return dayOfWeek - 1;
        }
    }
    
    function toUtcDate(date: Date): Date {
        return DateHelper.formDateToUTCIgnoringTimezoneWithoutTime(date) as Date;
    }

    const onChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        SetDateRange(item?.key as DateRangeType);
    };

    function onStartDateChanged(date: Date | null | undefined): any {
        dateRange.start = date;
        onDateRangeChange(dateRange);
    }

    function onEndDateChanged(date: Date | null | undefined): any {
        dateRange.end = date;
        onDateRangeChange(dateRange);
    }

    return (
        <div className="dateRange flex-container flex-gap-16">
            <div className="dateRange-select">
                <Dropdown
                    placeholder="Selecteer een periode"
                    options={options}
                    styles={dropdownStyles}
                    selectedKey={dateRange ? dateRange.dateRangeType : DateRangeType.DezeWeek}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={onChange}
                />
            </div>
            <div className="dateRange-customrange flex-container">
                <div className="dateRange-customrange-van">
                    <FluentDatePicker value={dateRange.start}
                                      onSelectDate={onStartDateChanged}
                                      styles={pickerStyles}
                                      formatDate={onFormatDate}
                                      disabled={dateRange.dateRangeType !== DateRangeType.AnderePeriode}></FluentDatePicker>
                </div>
                <div className="dateRange-customrange-tm">
                    <FluentDatePicker value={dateRange.end}
                                      onSelectDate={onEndDateChanged}
                                      styles={pickerStyles}
                                      formatDate={onFormatDate}
                                      disabled={dateRange.dateRangeType !== DateRangeType.AnderePeriode}></FluentDatePicker>
                </div>
            </div>
        </div>
    )
}

export default DateRangeSelector;