import React from 'react'
import { Text } from '@fluentui/react'
import primaryTheme from 'themes/primary'

interface ICounterProps {
  number: number
  text: string
  borderBottomColor?: string
  onClick?: () => void
}

const Counter: React.FC<ICounterProps> = ({ number, text, borderBottomColor, onClick }) => {


    return (
        <div
            style={{
                ...styles,
                borderBottom: borderBottomColor ? `5px solid ${borderBottomColor}` : borderStyle,
                userSelect: 'none',
                boxShadow: primaryTheme.semanticColors.cardShadow,
                cursor: onClick ? 'pointer' : 'default',
            }}
            onClick={onClick}
        >
            <Text
                variant="xxLarge"
                styles={{
                    root: {
                        fontWeight: 'bold',
                    },
                }}
            >
                {number}
            </Text>
            <Text variant="medium" styles={{root: {textAlign: 'center', paddingTop: '4px'}}}>{text}</Text>
        </div>
    )
}

export default Counter

const borderStyle = '1px solid #efefef'
const styles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px',
    maxHeight: '150px',
    minWidth: '120px',
    maxWidth: '250px',
    boxSizing: 'border-box',
    flex: 1,
    margin: '4px',

}

