import React from 'react'
import {DefaultButton} from '@fluentui/react'
import './callbackLoggedInNotKnow.scss'

interface popupProps {
    name: string;
    email: string;
    handleLogout: () => void
}

const CallbackLoggedInNotKnow: React.FC<popupProps> = ({name, email, handleLogout}) => {

    return (
        <div className='callback-logged-in-not-known'>
            <h3>U heeft geen toegang tot het portaal van Onderhoudsketen.</h3>

            <h4>Volg de volgende stappen om het probleem op te lossen:</h4>
            <ol>
                <li>Controleer of u met het juiste Microsoft account bent ingelogd. Indien nee, kunt u
                    hieronder uitloggen en opnieuw inloggen met het juiste account.
                </li>
                <li>Vraag uw systeembeheerder of u toegang heeft tot de bedrijfsapplicatie
                    "Onderhoudsketen".
                </li>
                <li>Registreer een melding bij DataBalk en informeer ons over dit probleem.</li>
            </ol>
            <p>U bent ingelogd als:</p>
            <p>Naam: {name} <br/> Email: {email} </p>
            <p>Klik op de onderstaande button om uit te loggen en met een andere account in te loggen.</p>
            <DefaultButton primary onClick={handleLogout} text="Uitloggen"/>
        </div>
    )
};

export default CallbackLoggedInNotKnow;