import React, { useEffect } from 'react';
import './Controleren.scss';
import { MessageBar, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import { useAppDispatch, useTypedSelector } from 'store';
import { getImportAannemers } from 'store/selectors/importAannemersUploadFile';
import { controleerImportAannemers } from 'store/actions/importAannemers/data';
import { ValidatieProblemen } from './ValidatieProblemen/ValidatieProblemen';

interface IProps {
    onFinished: (resultaat: boolean) => void;
}

export const Controleren = ({onFinished}: IProps) => {
    const dispatch = useAppDispatch();
    const {importValidatieResult, importFileRows, status, error} = useTypedSelector(getImportAannemers);

    useEffect(() => {
        if (!!importValidatieResult) {
            return;
        }
        dispatch(controleerImportAannemers(importFileRows)).then();
    }, []);    
    
    useEffect(() => {
        if (status === 'succeeded' && importValidatieResult && importValidatieResult.success) {
            onFinished(true);
        } else {
            onFinished(false);
        }
    }, [importValidatieResult, status]);

    return (
        <div className="controleren">
            {status === 'pending' && <div className="controleren-loading">
                Bezig met controleren...
                <Spinner size={SpinnerSize.large}/>
            </div>}
            {status === 'failed' && <div className="controleren-error">
                <MessageBar messageBarType={MessageBarType.error}>
                    {error}
                </MessageBar>
            </div>}
            {status === 'succeeded' && importValidatieResult !== undefined &&
                <div className="controleren-resultaat">
                    {!!importValidatieResult.successMessage &&
                        <div className="controleren-ok">
                            <div className="controleren-ok-title title">Importbestand succesvol gevalideerd</div>
                            <MessageBar messageBarType={MessageBarType.success}>
                                {importValidatieResult.successMessage}
                            </MessageBar>
                            <div className="controleren-ok-verder title">
                                Klik op volgende om de gegevens te importeren.
                            </div>
                        </div>
                    }
                    {!importValidatieResult.success &&
                        <div className="controleren-problemen">
                            {!!importValidatieResult.errorMessage && <div className="controleren-problemen-algemeen">
                                <div className="controleren-problemen-algemeen-title title">
                                    Algemene validatie fouten importbestand
                                </div>
                                <div className="controleren-problemen-algemeen-bericht">
                                    {importValidatieResult.errorMessage}
                                </div>
                            </div>}
                            {importValidatieResult.validatieProblemen && importValidatieResult.validatieProblemen.length > 0 &&
                                <div className="controleren-problemen-data">
                                    <div className="controleren-problemen-data-title title">Validatie problemen per regel</div>
                                    <ValidatieProblemen problemen={importValidatieResult.validatieProblemen}/>
                                </div>}
                            <div className="controleren-problemen-toelichting">Los de bovenstaande problemen op en
                                probeer
                                het vervolgens opnieuw.
                            </div>
                            <br/><br/>
                        </div>
                    }

                </div>}
        </div>
    );
};
