import React, { ErrorInfo } from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import './index.css'
import { store } from './store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';

import { initializeIcons } from '@fluentui/react/lib/Icons'
import { initializeApp, IConfiguration } from './store/actions/app/configuration'

class ErrorBoundary extends React.Component {
  state = {error: null as Error | null, info: null as ErrorInfo | null};

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ error, info })
  }

  render() {
    if (this.state.error) {
      // Render fallback UI
      return <h1>Something went wrong.</h1>
    }
    return this.props.children
  }
}

const documentReady = () => {
  return new Promise(resolve => {
    if (/complete|interactive/.test(document.readyState)) {
      resolve()
    } else {
      window.addEventListener(`DOMContentLoaded`, () => resolve())
    }
  })
};

const startup = async () => {
  initializeIcons();

  let fetchStaticSettings: Promise<Response>;
  if (process.env.NODE_ENV === 'development') {
    fetchStaticSettings = fetch(process.env.PUBLIC_URL + '/settings.dev.json')
  } else {
    fetchStaticSettings = fetch(process.env.PUBLIC_URL + '/settings.json')
  }

  await documentReady();
  const fetchStaticSettingsResp = await fetchStaticSettings;
  const staticSettings: IConfiguration = await fetchStaticSettingsResp.json();
  const fetchDynamicSettingsResp = await fetch(`${staticSettings.apiEndpoint}/frontendconfig/mgmtmodule`);
  const settings: IConfiguration = {
    ...(await fetchDynamicSettingsResp.json()),
    apiEndpoint: staticSettings.apiEndpoint,
  };


  store.dispatch(initializeApp(settings));

  const render = Component => {
    ReactDOM.render(
        <ErrorBoundary>
          <Provider store={store}>
            <Component/>
          </Provider>
        </ErrorBoundary>,
        document.getElementById('root')
    )
  };
  
  render(App);

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', () => {
      const nextApp = require('./App').default;
      render(nextApp)
    })
  }
};

startup().catch(error => console.error('Error during startup: ', error));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: reg => {
//     if (reg && reg.waiting) {
//       reg.waiting.postMessage({type: 'SKIP_WAITING'});
//     }
//     window.location.reload()
//   },
// });
