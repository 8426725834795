import React, { useCallback, useEffect } from 'react'
import { TextField } from '@fluentui/react'
import QuickAdd from 'components/QuickAdd'
import { getAannemers, getUpsertAannemer } from 'store/selectors/aannemers'
import { createAannemer, resetUpsertAannemer } from 'store/actions/aannemers/upsert'
import { peekResult, useAppDispatch, useTypedSelector } from 'store'
import { aannemerSchema, IAannemer } from 'interfaces/aannemer'
import FluentToggle from 'components/FluentToggle'
import { getPropertyName } from 'lib/interfaceUtils'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { fetchDeelnemer } from 'store/actions/deelnemers/fetch'
import { unwrapResult } from '@reduxjs/toolkit'

interface IAddProps {
  onCancel: () => void
}

const defaultFormvalues: IAannemer = {
  naam: '',
  gln: '',
  geblokkeerd: false,
  id: 0,
  aadDomainName: '',
  aadTenantId: '',
}

const Add: React.FC<IAddProps> = ({ onCancel }) => {
  const dispatch = useAppDispatch()

  const { status: upsertStatus, error: upsertError } = useTypedSelector(getUpsertAannemer)
  const { list } = useTypedSelector(getAannemers)

  useEffect(() => {
    return () => {
      dispatch(resetUpsertAannemer())
    }
  }, [dispatch])

  const {
    handleSubmit,
    control,
    setValue,
    errors: formErrors,
    reset,
    getValues,
  } = useForm<IAannemer>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultFormvalues,
    resolver: yupResolver(aannemerSchema),
    context: {
      aannemers: list,
      dispatch,
    },
  })

  const onSave = (data: IAannemer) => {
    dispatch(createAannemer({ data }))
      .then(peekResult)
      .then(success => success && onCancel())
  }

  const onSaveAndCreateAnother = (data: IAannemer) => {
    dispatch(createAannemer({ data }))
      .then(peekResult)
      .then(success => success && reset(defaultFormvalues))
  }

  const handleSave = () => {
    handleSubmit(onSave)()
  }

  const handleSaveAndCreateAnother = () => {
    handleSubmit(onSaveAndCreateAnother)()
  }

  const handleGlnBlur = useCallback(() => {
    const gln = getValues().gln
    if (!gln || gln.length !== 13) return

    return dispatch(fetchDeelnemer({ gln }))
      .then(unwrapResult)
      .then(deelnemer => {
        if (deelnemer) {
          setValue('naam', deelnemer.bedrijfsnaam)
        } else {
          setValue('naam', '')
        }
      })
  }, [dispatch])

  return (
    <QuickAdd
      isOpen={true}
      onCancel={onCancel}
      entityName="Aannemer"
      onSave={handleSave}
      onSaveAndCreateAnother={handleSaveAndCreateAnother}
      saving={upsertStatus === 'pending'}
      warning={'Let op: indien u geen Azure AD domeinnaam invult, dan kan de aannemer niet inloggen in het aannemersportaal.'}
      error={upsertError}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <Controller
          name={getPropertyName<IAannemer>('gln')}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextField
              autoComplete="off"
              required={true}
              label="GLN-code"
              value={value}
              onChange={onChange}
              onBlur={() => {
                onBlur()
                handleGlnBlur()
              }}
              errorMessage={formErrors.gln?.message}
            />
          )}
        />
        <Controller
          name={getPropertyName<IAannemer>('naam')}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextField
              autoFocus
              autoComplete="off"
              required={true}
              label="Naam"
              disabled
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={formErrors.naam?.message}
            />
          )}
        />
        <Controller
          name={getPropertyName<IAannemer>('aadDomainName')}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextField
              autoComplete="off"
              label="Azure AD domeinnaam"
              value={value}
              placeholder=""
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={formErrors.aadDomainName?.message}
            />
          )}
        />
        <Controller
            name={getPropertyName<IAannemer>('referentie')}
            control={control}
            render={({ onChange, onBlur, value }) => (
                <TextField
                    autoComplete="off"
                    label="Externe referentie"
                    value={value}
                    placeholder=""
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={formErrors.referentie?.message}
                />
            )}
        />
        <Controller
            name={getPropertyName<IAannemer>('opdrachtEmailAdres')}
            control={control}
            render={({ onChange, onBlur, value }) => (
                <TextField
                    autoComplete="off"
                    label="Opdracht email-adres"
                    value={value}
                    placeholder=""
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={formErrors.opdrachtEmailAdres?.message}
                />
            )}
        />
        <Controller
          name={getPropertyName<IAannemer>('geblokkeerd')}
          control={control}
          render={({ onBlur, value }) => (
            <FluentToggle
              label="Geblokkeerd"
              checked={value}
              onChange={(_, checked) => {
                setValue('geblokkeerd', checked || false)
                onBlur()
              }}
            />
          )}
        />
        <input type="submit" style={{ visibility: 'hidden' }} />
      </form>
    </QuickAdd>
  )
}
export default Add
