export enum DateRangeType {
    Vandaag = 1,
    DezeWeek = 2,
    DezeMaand = 3,
    DitJaar = 4,
    Gisteren = 5,
    VorigeWeek = 6,
    VorigeMaand = 7,
    VorigeJaar = 8,
    Laatste3Maanden = 9,
    Laatste6Maanden = 10,
    Laatste12Maanden = 11,
    AnderePeriode = 12,
}
