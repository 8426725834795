import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IInstellingen, IUpdateInstellingenForm } from 'interfaces/instellingen'
import { invokeFetch } from 'services/apiClient'
import { IStateMachine, RootState } from 'store'

const updateInstellingen = createAsyncThunk<IInstellingen, IUpdateInstellingenForm, { state: RootState }>(
  'instellingen/update',
  async (data, thunkApi) => {
    return await invokeFetch<IInstellingen>(thunkApi, 'PUT', `/instellingen/instellingen`, data)
  }
)

const initState: IStateMachine = {
  status: 'idle',
  error: '',
}

export const slice = createSlice({
  name: 'instellingen/upsert',
  initialState: initState,
  reducers: {
    resetUpsertInstellingen: state => {
      state.status = initState.status
      state.error = ''
    },
  },
  extraReducers: builder => {
    builder.addCase(updateInstellingen.pending, (state, action) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(updateInstellingen.fulfilled, (state, action) => {
      state.status = 'succeeded'
    })
    builder.addCase(updateInstellingen.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })
  },
})

export const { resetUpsertInstellingen } = slice.actions

export { updateInstellingen }

export default slice.reducer
