import { IStateMachine, RootState } from 'store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { invokeFetch, invokeFetchDownload } from 'services/apiClient';
import { ImportUploadFileRow } from 'interfaces/importUploadFileRow';
import { IBlob } from 'interfaces/blob';
import { ImportAannemersControleResponse } from 'interfaces/importAannemersControleResponse';
import { ImportAannemersImportResponse } from 'interfaces/importAannemersImportResponse';

interface IImportAannemersState extends IStateMachine {
    importFileRows: ImportUploadFileRow[];
    importValidatieResult?: ImportAannemersControleResponse;
    importResult?: ImportAannemersImportResponse;
}

const initState: IImportAannemersState = {
    importFileRows: [],
    importValidatieResult: undefined,
    importResult: undefined,
    status: 'idle',
    error: '',
};

export interface IUploadImportFileArgs {
    file: File;
}

const fileUploadImportAannemers =
    createAsyncThunk<ImportUploadFileRow[], IUploadImportFileArgs, { state: RootState }>
    (
        'AannemersImporteren/upload',
        async (args, thunkApi) => {
            var body = new FormData();
            body.append('File', args.file, args.file.name);
            const result = await invokeFetch<ImportUploadFileRow[]>(
                thunkApi,
                'POST',
                `/AannemersImporteren/upload`,
                body,
                false,
                {},
                false
            );
            return result;
        }
    );

const downloadVoorbeeldBestand = createAsyncThunk<IBlob, undefined, { state: RootState }>
(
    'AannemersImporteren/voorbeeld',
    async (_, thunkApi) => {
    return await invokeFetchDownload(
        thunkApi, 'GET', `/AannemersImporteren/voorbeeld`,
        'ImporterenAannemers.xlsx');
});

export interface IControleAannemersImportArgs {
    importUploadFileRows: ImportUploadFileRow[];
}

const controleerImportAannemers =
    createAsyncThunk<ImportAannemersControleResponse, ImportUploadFileRow[], { state: RootState }>
    (
        'AannemersImporteren/controle',
        async (args, thunkApi) => {
            var body = args;
            const result = await invokeFetch<ImportAannemersControleResponse>(
                thunkApi,
                'POST',
                `/AannemersImporteren/controle`,
                body
            );
            return result;
        }
    );

const importAannemers =
    createAsyncThunk<ImportAannemersImportResponse, ImportUploadFileRow[], { state: RootState }>
    (
        'AannemersImporteren/import',
        async (args, thunkApi) => {
            var body = args;
            const result = await invokeFetch<ImportAannemersImportResponse>(
                thunkApi,
                'POST',
                `/AannemersImporteren/import`,
                body
            );
            return result;
        }
    );

export const slice = createSlice({
    name: 'AannemersImporteren/upload',
    initialState: initState,
    reducers: {
        resetFileUploadImportAannemers: (state) => {
            state.importFileRows = [];
            state.importValidatieResult = undefined;
            state.importResult = undefined;
            state.status = initState.status;
            state.error = '';
        }
    },
    extraReducers: builder => {
        builder.addCase(fileUploadImportAannemers.pending, (state, action) => {
            state.status = 'pending';
            state.importValidatieResult = undefined;
            state.error = ''
        });
        builder.addCase(fileUploadImportAannemers.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.importFileRows = action.payload;
            state.importValidatieResult = undefined;
        });
        builder.addCase(fileUploadImportAannemers.rejected, (state, action) => {
            state.status = 'failed';
            state.importFileRows = [];
            state.importValidatieResult = undefined;
            state.error = action.error.message || ''
        });

        builder.addCase(controleerImportAannemers.pending, (state, action) => {
            state.status = 'pending';
            state.error = ''
        });
        builder.addCase(controleerImportAannemers.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.importValidatieResult = action.payload
        });
        builder.addCase(controleerImportAannemers.rejected, (state, action) => {
            state.status = 'failed';
            state.importValidatieResult = undefined;
            state.error = action.error.message || ''
        });


        builder.addCase(importAannemers.pending, (state, action) => {
            state.status = 'pending';
            state.error = ''
        });
        builder.addCase(importAannemers.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.importResult = action.payload
        });
        builder.addCase(importAannemers.rejected, (state, action) => {
            state.status = 'failed';
            state.importResult = undefined;
            state.error = action.error.message || ''
        });
    },
});

export const { resetFileUploadImportAannemers } = slice.actions;
export { fileUploadImportAannemers, downloadVoorbeeldBestand, controleerImportAannemers, importAannemers };
export default slice.reducer;