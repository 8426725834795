import React, { useMemo, useState } from 'react'
import {
    DefaultButton,
    Dialog,
    DialogContent,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    IModalProps,
    Spinner,
    SpinnerSize,
    Text, TextField,
} from '@fluentui/react'

interface IConfirmDialogProps {
    title: string
    subText?: string[]
    onCancel: () => void
    onOk: (toelichting: string) => void
    loading?: boolean
}

const HandmatigAfhandelenDialog: React.FC<IConfirmDialogProps> = ({ title, subText = [], onCancel, onOk, loading = false }) => {
    const modalProps = useMemo(
        (): IModalProps => ({
            isBlocking: true,
        }),
        []
    )
    
    const [toelichting, setToelichting] = useState('');

    const dialogContentProps = useMemo(
        (): IDialogContentProps => ({
            type: DialogType.largeHeader,
            title: title,
        }),
        [title]
    )

    function handleOk(): void {
        onOk(toelichting);
    }

    function handleToelichtingOnChange(e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement> ): any {
        setToelichting(e.currentTarget.value);
    }
    
    function hasToelichting(): boolean {
        return toelichting.trim().length >= 4;
    }


    return (
        <Dialog
            hidden={false}
            onDismiss={onCancel}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            minWidth={500}
        >
            <DialogContent styles={{ header: { height: 0, width: 0 }, inner: { padding: 0 } }}>
                {subText.map((line, index) => (
                    <div key={index}>
                        <Text>{line}</Text>
                    </div>
                ))}
                <TextField label="Toelichting handmatige afhandeling" multiline autoAdjustHeight onChange={handleToelichtingOnChange} maxLength={300}  />
            </DialogContent>
            <DialogFooter>
                <DefaultButton primary allowDisabledFocus disabled={loading || !hasToelichting()} onClick={handleOk}>
                    {loading ? <Spinner size={SpinnerSize.small} /> : 'Ok'}
                </DefaultButton>
                <DefaultButton disabled={loading} onClick={onCancel} text="Annuleren" />
            </DialogFooter>
        </Dialog>
    )
}

export default HandmatigAfhandelenDialog
