import { combineReducers } from '@reduxjs/toolkit'
import data from './data'
import upsert from './upsert'
import endpointsBezorgen from './endpointsBezorgen'
import endpointsOntvangen from './endpointsOntvangen'

export default combineReducers({
  data,
  upsert,
  endpointsBezorgen,
    endpointsOntvangen,
})
