const locale = 'nl-NL';

// Date formatting
const opts: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

const timeOpts: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

export const stringToLocaleDateString = (date?: string): string | undefined =>
  date ? new Date(date).toLocaleDateString(locale) : undefined;

export const stringToLocaleString = (date?: Date | string): string | undefined =>
  date ? new Date(date).toLocaleString(locale, opts) : undefined;

export const stringToLocaleTimeString = (date?: string): string | undefined =>
  date ? new Date(date).toLocaleTimeString(locale, timeOpts) : undefined;

export const dateToLocaleDateString = (date?: Date): string | undefined =>
  date ? date.toLocaleDateString(locale) : undefined;

export const dateToLocaleString = (date?: Date): string | undefined =>
  date ? date.toLocaleString(locale, opts) : undefined;

export const booleanToDutchString = (value?: boolean): string | undefined => {
  if (value === undefined) {
    return undefined;
  }
  return value ? 'Ja' : 'Nee';
};

// Number formatting
const numberOpts: Intl.NumberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const numberToLocaleString = (num?: number): string | undefined =>
  num ? num.toLocaleString(locale, numberOpts) : undefined;

// String comparison
const stringCompareOpts: Intl.CollatorOptions = {
  sensitivity: 'base',
};

export const localeCompareString = (a?: string, b?: string, desc: boolean = false): number => {
  const valueA = a || '';
  const valueB = b || '';

  return desc
    ? valueB.localeCompare(valueA, locale, stringCompareOpts)
    : valueA.localeCompare(valueB, locale, stringCompareOpts)
};
