import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IAuthState {
  username?: string
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
}

const initState: IAuthState = {
  username: '',
  status: 'idle',
  error: null,
}

export const slice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    setUsername: (state, action: PayloadAction<string | undefined>) => {
      state.username = action.payload
    },
  },
})

const { setUsername } = slice.actions

export { setUsername }

export default slice.reducer
