import React, { useCallback, useEffect, useMemo } from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import QuickAdd from 'components/QuickAdd';
import { peekResult, useAppDispatch, useTypedSelector } from 'store';
import { getPropertyName } from 'lib/interfaceUtils';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createWerkwijzeSchema, ICreateWerkwijzeForm, IWerkwijze } from 'interfaces/werkwijze';
import { WerkwijzeOptie, WerkwijzeOptieLabelMap } from 'enums/werkwijzeOptie';
import { OpdrachtSoortLabelMap } from 'enums/opdrachtSoort';
import { getUpsertWerkwijze } from 'store/selectors/werkwijzes';
import { IAannemer } from 'interfaces/aannemer';
import { createWerkwijze, resetUpsertWerkwijze, updateWerkwijze } from 'store/actions/werkwijzes/upsert';
import { getAannemerEndpointsBezorgen } from 'store/selectors/aannemers';

interface IAddProps {
  aannemer: IAannemer
  werkwijze?: IWerkwijze
  onClose: () => void
}

const Add: React.FC<IAddProps> = ({ aannemer, werkwijze, onClose }) => {
  const { werkwijzes = [] } = aannemer;
  const dispatch = useAppDispatch();

  const endpointsBezorgen = useTypedSelector(getAannemerEndpointsBezorgen).endpointsBezorgen;
  const { status: upsertStatus, error: upsertError } = useTypedSelector(getUpsertWerkwijze);

  useEffect(() => {
    dispatch(resetUpsertWerkwijze())
  }, [dispatch]);

  const soortOptions: IDropdownOption[] = useMemo(() => {
    // Only return the remaining options.
    return Object.keys(OpdrachtSoortLabelMap)
      .map(key => ({
        key,
        text: OpdrachtSoortLabelMap[key],
      }))
      .filter(opt => !werkwijzes.find(w => w.opdrachtsoort === opt.key))
  }, [werkwijzes]);

  const endpointsOptions: IDropdownOption[] = useMemo(() => {
    return endpointsBezorgen.map(
      endpoint =>
        ({
          key: endpoint.id,
          text: endpoint.naam,
        } as IDropdownOption)
    )
  }, [endpointsBezorgen]);

  const werkwijzeOptions: IDropdownOption[] = useMemo(() => {
    return Object.keys(WerkwijzeOptieLabelMap).map(key => ({
      key,
      text: WerkwijzeOptieLabelMap[key],
    }))
  }, []);

  const { handleSubmit, control, setValue, errors: formErrors, formState, watch } = useForm<ICreateWerkwijzeForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: werkwijze,
    resolver: yupResolver(createWerkwijzeSchema),
  });

  const onSave = useCallback(
    (data: ICreateWerkwijzeForm) => {
      // Decide whether we should update or insert
      if (werkwijze) {
        dispatch(
          updateWerkwijze({
            id: werkwijze.id,
            aannemerId: werkwijze.aannemerId,
            data,
          })
        )
          .then(peekResult)
          .then(success => success && onClose())
      } else {
        dispatch(
          createWerkwijze({
            aannemerId: aannemer.id,
            data,
          })
        )
          .then(peekResult)
          .then(success => success && onClose())
      }
    },
    [dispatch, onClose, aannemer, werkwijze]
  );

  const watchWerkwijze = watch('werkwijze');

  return (
    <QuickAdd
      isOpen={true}
      onCancel={onClose}
      entityName="Werkwijze"
      onSave={() => handleSubmit(onSave)()}
      saving={upsertStatus === 'pending' || !formState.isValid}
      error={upsertError}
    >
      <form onSubmit={handleSubmit(onSave)}>
        {werkwijze ? (
          <>
            <Controller
              name={getPropertyName<ICreateWerkwijzeForm>('opdrachtsoort')}
              control={control}
              defaultValue={''}
              render={() => <></>}
            />
          </>
        ) : (
          <Controller
            name={getPropertyName<ICreateWerkwijzeForm>('opdrachtsoort')}
            control={control}
            defaultValue={''}
            render={({ onBlur, value }) => (
              <Dropdown
                placeholder="Kies een optie"
                label="Soort"
                required
                options={soortOptions}
                selectedKey={value}
                errorMessage={formErrors.werkwijze?.message}
                onChange={(_, option) => {
                  setValue('opdrachtsoort', option!.key);
                  onBlur()
                }}
              />
            )}
          />
        )}
        <Controller
          name={getPropertyName<ICreateWerkwijzeForm>('werkwijze')}
          control={control}
          defaultValue={''}
          render={({ onBlur, value }) => (
            <Dropdown
              placeholder="Kies een optie"
              label="Werkwijze"
              required
              options={werkwijzeOptions}
              selectedKey={value}
              errorMessage={formErrors.werkwijze?.message}
              onChange={(_, option) => {
                setValue('werkwijze', option!.key);
                setValue('endpointBezorgingId', undefined);
                onBlur()
              }}
            />
          )}
        />
        <Controller
          name={getPropertyName<ICreateWerkwijzeForm>('endpointBezorgingId')}
          control={control}
          defaultValue={''}
          render={({ onBlur, value }) => (
            <Dropdown
              placeholder="Kies een optie"
              label="Endpoint"
              required={watchWerkwijze === WerkwijzeOptie.DICO}
              disabled={!(watchWerkwijze === WerkwijzeOptie.DICO)}
              options={endpointsOptions}
              selectedKey={value}
              errorMessage={formErrors.werkwijze?.message}
              onChange={(_, option) => {
                setValue('endpointBezorgingId', option!.key);
                onBlur()
              }}
            />
          )}
        />
        <input type="submit" style={{ visibility: 'hidden' }} />
      </form>
    </QuickAdd>
  )
};
export default Add
