import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStateMachine } from 'store'
import { RootState } from 'store/rootReducer'
import { IAannemer } from 'interfaces/aannemer'
import { invokeFetch } from 'services/apiClient'
import { createAannemer, updateAannemer } from './upsert'
import { createWerkwijze, updateWerkwijze } from '../werkwijzes/upsert'

const mergeList = (items: Array<IAannemer>, item: IAannemer, preserveNestedData = true): Array<IAannemer> => {
  let newItem = item
  if (preserveNestedData) {
    const oldItem = items.find(x => x.id === item.id)
    newItem = {
      ...item,
      werkwijzes: oldItem?.werkwijzes || [],
    }
  }
  return items.filter(i => i.id !== item.id).concat([newItem])
}

interface IAannemersState extends IStateMachine {
  list: Array<IAannemer>
}

const initState: IAannemersState = {
  list: [],
  status: 'idle',
  error: '',
}

const fetchAannemers = createAsyncThunk<Array<IAannemer>, undefined, { state: RootState }>(
    'aannemers/fetch',
    async (_, thunkApi) => {
      return await invokeFetch<Array<IAannemer>>(thunkApi, 'GET', '/aannemers');
    }
)

const fetchAannemer = createAsyncThunk<IAannemer, string, { state: RootState }>(
    'aannemers/fetchById',
    async (id, thunkApi) => {
      return await invokeFetch<IAannemer>(thunkApi, 'GET', `/aannemers/${id}`, undefined, false);
    }
)

export const slice = createSlice({
  name: 'aannemers/data',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAannemers.pending, (state, action) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(fetchAannemers.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.list = action.payload
    })
    builder.addCase(fetchAannemers.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
      state.list = []
    })

    builder.addCase(fetchAannemer.pending, (state, action) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(fetchAannemer.fulfilled, (state, action) => {
      state.status = 'succeeded'
      if (action.payload) {
        state.list = state.list.filter(i => i.id !== action.payload!.id!).concat([action.payload])
      }
    })
    builder.addCase(fetchAannemer.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(createAannemer.fulfilled, (state, action) => {
      if (action.payload) {
        state.list = mergeList(state.list, action.payload)
      }
    })

    builder.addCase(updateAannemer.fulfilled, (state, action) => {
      if (action.payload) {
        state.list = mergeList(state.list, action.payload)
      }
    })

    builder.addCase(createWerkwijze.fulfilled, (state, { payload }) => {
      if (payload) {
        const aannemer = state.list.find(x => x.id === payload.aannemerId)
        const updated = {
          ...aannemer,
          werkwijzes: (aannemer?.werkwijzes || []).concat([payload]),
        } as IAannemer

        state.list = mergeList(
          state.list.filter(x => x.id !== payload.aannemerId),
          updated,
          false
        )
      }
    })

    builder.addCase(updateWerkwijze.fulfilled, (state, { payload }) => {
      if (payload) {
        const aannemer = state.list.find(x => x.id === payload.aannemerId)
        const updated = {
          ...aannemer,
          werkwijzes: (aannemer?.werkwijzes || []).filter(x => x.id !== payload.id).concat([payload]),
        } as IAannemer

        state.list = mergeList(
          state.list.filter(x => x.id !== payload.aannemerId),
          updated,
          false
        )
      }
    })
  },
})

export { fetchAannemers, fetchAannemer }

export default slice.reducer
