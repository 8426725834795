import React, {useEffect, useMemo, useState} from 'react'
import './index.scss'
import commandBarStyles from 'styles/commandBarStyles'
import {CommandBar, ICommandBarItemProps, MessageBar, Spinner, SpinnerSize, Stack} from '@fluentui/react'
import {callApi as fetchBusinessRules} from './api/BusinessRuleData'
import BusinessRulesSelectionPopup from './Popups/BusinessRulesSelectionPopup'
import {IAvailableBusinessRules, IBusinessRulesConfig} from './models/businessRules'
import BusinessRuleConfigurationPopup from './Popups/BusinessRuleConfigurationPopup'
import BusinessRulesTable from './Popups/BusinessRulesTable'

const BusinessRules = () => {
    const [isFetching, setIsFetching] = useState(true)
    const [businessData, setBusinessData] = useState<IBusinessRulesConfig[]>()
    const [showBusinessRulesSelectionPopup, setShowBusinessRulesSelectionPopup] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)
    const [availableBusinessRules, setAvailableBusinessRules] = useState<IAvailableBusinessRules[]>([])
    const [businessRuleId, setBusinessRuleId] = useState<number>()
    const [selectedBusinessRule, setSelectedBusinessRule] = useState<any>()
    const [showEditBusinessRule, setShowEditBusinessRule] = useState(false)

    useEffect(() => {
        if (isFetching) {
            fetchBusinessRules('BusinessRules/config', 'GET')
                .then(data => setBusinessData(data.map((item: IBusinessRulesConfig) => ({...item, key: item.id}))))
                .catch(error => setError(`Error fetching data: ${error.message}`))
            fetchBusinessRules('BusinessRules/available', 'GET')
                .then(data => setAvailableBusinessRules(data.map((item: IAvailableBusinessRules) => ({
                    ...item,
                    key: item.code
                }))))
                .catch(error => setError(`Error fetching data: ${error.message}`))
            setIsFetching(false)
        }
    }, [isFetching])

    useEffect(() => {
        if (businessRuleId) {
            fetchBusinessRules(`BusinessRules/config/${businessRuleId}`, 'GET')
                .then(data => {
                    setSelectedBusinessRule(data)
                    setShowEditBusinessRule(true);
                })
        }
        setBusinessRuleId(undefined)
    }, [businessRuleId])

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'pagetitle',
                onRender: () => (
                    <span className="page-title">Business rules</span>
                )
            }
        ],
        []
    )

    const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'newItem',
                text: 'Toevoegen',
                iconProps: {iconName: 'Add', className: 'greenIcon'},
                split: false,
                ariaLabel: 'Toevoegen',
                onClick: () => setShowBusinessRulesSelectionPopup(true)
            },
            {
                key: 'refresh',
                text: 'Vernieuwen',
                iconProps: {iconName: 'Refresh', className: 'icon'},
                split: false,
                ariaLabel: 'Vernieuwen',
                onClick: () => setIsFetching(true)
            }
        ],
        []
    )

    const handleBusinessRuleSelection = (selectedBusinessRule: IAvailableBusinessRules) => {
        setSelectedBusinessRule(selectedBusinessRule)
    }

    const onEditBusinessRule = (businessRuleId: number | undefined) => {
        setBusinessRuleId(businessRuleId);
    };

    return (
        <Stack className="businessrules">
            <div className="businessrules-header">
                <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles}/>
            </div>
            {error && <MessageBar>{Error}</MessageBar>}
            {isFetching && <Spinner size={SpinnerSize.small}/>}
            {businessData &&
                <BusinessRulesTable items={businessData}
                                    setShowBusinessRulesSelectionPopup={setShowBusinessRulesSelectionPopup}
                                    onEditBusinessRule={onEditBusinessRule}
                                    isFetching={isFetching}/>}
            <BusinessRulesSelectionPopup updateSavingPopup={setShowEditBusinessRule}
                                         onSelectionChanged={handleBusinessRuleSelection}
                                         isPopupOpen={showBusinessRulesSelectionPopup}
                                         updatePopup={setShowBusinessRulesSelectionPopup}
                                         updateBusinessRulesRefetching={setIsFetching}
                                         availableBusinessRules={availableBusinessRules}/>
            {selectedBusinessRule && showEditBusinessRule &&
                <BusinessRuleConfigurationPopup updateSelectedBusinessRule={setSelectedBusinessRule}
                                                businessRules={selectedBusinessRule}
                                                updateBusinessRules={setIsFetching}
                                                openPopup={showEditBusinessRule}
                                                updatePopup={setShowEditBusinessRule}/>}
        </Stack>
    )
}

export default BusinessRules