import React, { useCallback, useMemo } from 'react';
import { IBerichtbezorging } from 'interfaces/berichtbezorging';
import { FontIcon, IColumn, IconButton, Link, mergeStyles, TooltipHost } from '@fluentui/react';
import { getPropertyName } from 'lib/interfaceUtils';
import './BerichtBezorgingenTable.scss';
import { stringToLocaleString } from 'lib/internationalization';
import { fetchBlob } from 'store/actions/blobs/data';
import { useAppDispatch } from 'store';
import { Constants } from 'lib/constants/icons';
import OhkTable from 'components/OhkTable';
import { BerichtZoekTerm } from '../Zoeken/BerichtZoekTerm';
import { ZoekVelden } from '../Zoeken/ZoekVelden';

interface IBerichtBezorgingTableProps {
    items: IBerichtbezorging[];
    fetchStatus: string;
    statusFilter: number;
    zoekTermFilter?: BerichtZoekTerm
    onSelectionChanged?: (ids: string[]) => void;
    onVanClicked?: (id: number) => void;
    onNaarClicked?: (id: number) => void;
}

const BerichtBezorgingenTable = ({items, fetchStatus, statusFilter, zoekTermFilter, onSelectionChanged, onVanClicked, onNaarClicked}: IBerichtBezorgingTableProps) => {
    const dispatch = useAppDispatch();

    function handleVanNaarClick(id: number, isVan: boolean) {
        if (isVan) {
            onVanClicked?.(id);
        } else {
            onNaarClicked?.(id);
        }
    }

    const handleBlobDownload = useCallback(
        (blobId: string | undefined, soort: 'ontvangen-bericht' | 'response-ontvangst' | 'bezorgd-bericht' | 'response-bezorging') => {
            if (!blobId) return;
            
            const fileName = `${soort}-${blobId}.xml`;
            dispatch(fetchBlob({id: blobId, fileName})).then();
        },
        [dispatch]
    );

    const getFilteredItems = ():  IBerichtbezorging[] => {
        const statusFiltered = statusFilter === 0 ? items : items.filter(item => item.statusId === statusFilter);
        if (!zoekTermFilter || !zoekTermFilter.zoekTerm || zoekTermFilter.zoekTerm.trim().length === 0) {
            return statusFiltered;
        }
        switch (zoekTermFilter.veld) {
            case ZoekVelden.opdrachtnummer:
                const term = zoekTermFilter.zoekTerm.trim().toLowerCase();
                return statusFiltered.filter(item => item.opdracht?.toLowerCase().includes(term));
        }
    };

    let filteredItems = useMemo(() => {
        return getFilteredItems()
    }, [items, statusFilter, zoekTermFilter]);
    
    const statusIcon = (statusId: number): string => {
        switch (statusId) {
            case 1:
                return Constants.IconBerichtStatusOnbestelbaar;
            case 2:
                return Constants.IconBerichtStatusInBehandeling;
            case 3:
                //StatusCircleCheckmark
                return Constants.IconBerichtStatusBezorgd;
            case 4:
                return Constants.IconBerichtStatusNietBezorgd;
            case 5:
                return Constants.IconBerichtStatusHandmatigAfgehandeld;
            default:
                return Constants.IconBerichtStatusHOnbekend;
        }
    }
    
    const statusFieldClass = (statusId: number): string => {
        return `status-field status-field-${statusId}`;
    }

    const iconStatusClass = mergeStyles({
        fontSize: 16,
        height: 16,
        width: 16,
        margin: '0 4px',
    });
    
    const columns: IColumn[] = useMemo(() => 
        [
            {
                key: 'iconStatus',
                name: 'Status icon',
                className: 'status-field',
                iconClassName: 'header-status-field-icon',
                iconName: 'Page',
                isIconOnly: true,
                minWidth: 24,
                maxWidth: 24,
                onRender: (item: IBerichtbezorging) => (
                    <div className={statusFieldClass(item.statusId)}><FontIcon aria-label="status icon" iconName={statusIcon(item.statusId)} className={iconStatusClass} /></div>
                )
            },
            {
                key: getPropertyName<IBerichtbezorging>('ontvangen'),
                name: 'Ontvangen',
                fieldName: getPropertyName<IBerichtbezorging>('ontvangen'),
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: 'date',
                onRender: (item: IBerichtbezorging) => (
                    <span>{item.ontvangen && stringToLocaleString(item.ontvangen)}</span>
                ),
            },
            {
                key: getPropertyName<IBerichtbezorging>('opdracht'),
                name: 'Opdrachtnummer',
                fieldName: getPropertyName<IBerichtbezorging>('opdracht'),
                minWidth: 100,
                maxWidth: 140,
                isResizable: true,
                data: 'string',
            },
            {
                key: getPropertyName<IBerichtbezorging>('opdrachtStatus'),
                name: 'Opdrachtstatus',
                fieldName: getPropertyName<IBerichtbezorging>('opdrachtStatus'),
                minWidth: 60,
                maxWidth: 80,
                isResizable: true,
                data: 'string',
            },            
            {
                key: getPropertyName<IBerichtbezorging>('soort'),
                name: 'Soort',
                fieldName: getPropertyName<IBerichtbezorging>('soort'),
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
            },
            {
                key: getPropertyName<IBerichtbezorging>('werkwijze'),
                name: 'Werkwijze',
                fieldName: getPropertyName<IBerichtbezorging>('werkwijze'),
                minWidth: 60,
                maxWidth: 80,
                isResizable: true,
                data: 'string',
            },
            {
                key: getPropertyName<IBerichtbezorging>('status'),
                name: 'Status',
                fieldName: getPropertyName<IBerichtbezorging>('status'),
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
            },
            {
                key: getPropertyName<IBerichtbezorging>('laatsteMutatie'),
                name: 'Laatste mutatie',
                fieldName: getPropertyName<IBerichtbezorging>('laatsteMutatie'),
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: 'date',
                onRender: (item: IBerichtbezorging) => (
                    <span>{item.laatsteMutatie && stringToLocaleString(item.laatsteMutatie)}</span>
                ),
            },
            {
                key: getPropertyName<IBerichtbezorging>('van'),
                name: 'Van',
                fieldName: getPropertyName<IBerichtbezorging>('van'),
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                onRender: (item: IBerichtbezorging) => {
                    const hasVanApp = !!item.vanId;
                    return (
                        <Link
                            key={item.berichtId}
                            onClick={() => (hasVanApp && !!item.vanId ? handleVanNaarClick(item.vanId, true) : undefined)}
                        >{item.van}</Link>
                    )
                },
            },
            {
                key: getPropertyName<IBerichtbezorging>('naar'),
                name: 'Naar',
                fieldName: getPropertyName<IBerichtbezorging>('naar'),
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                onRender: (item: IBerichtbezorging) => {
                    const hasNaarApp = item.naarId ;
                    return (
                        <> 
                            {item.naarId && item.naarId > 0 ?
                                <Link
                                    key={item.berichtId}
                                    onClick={() => (hasNaarApp && !!item.naarId ? handleVanNaarClick(item.naarId, false) : undefined)}
                                >{item.naar}</Link>
                                : 
                                <span>{item.naar}</span>
                            }
                        </>
                    )
                },
            },
            {
                key: getPropertyName<IBerichtbezorging>('toelichting'),
                name: 'Toelichting',
                fieldName: getPropertyName<IBerichtbezorging>('toelichting'),
                minWidth: 80,
                maxWidth: 600,
                isResizable: true,
                data: 'string',
                onRender: (item: IBerichtbezorging) => {
                    return (
                        <TooltipHost 
                            content={item.toelichting ?? ''}
                            id='tooltip-toelichting'
                        >
                            <span>{item.toelichting}</span>
                        </TooltipHost>
                    )
                }
            },
            {
                key: 'origineelbericht',
                name: '',
                minWidth: 20,
                maxWidth: 20,
                isResizable: false,
                onRender: (item: IBerichtbezorging) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', height: 'auto' }}>
                        <IconButton
                            styles={{
                                root: { height: 'auto', ':active': { background: '#ffffff' } },
                                splitButtonContainer: { width: 'auto' },
                            }}
                            iconProps={{ iconName: 'MoreVertical' }}
                            menuProps={{
                                items: [
                                    {
                                        key: 'berichtinfo',
                                        text: `Bericht: ${item.berichtId.toString()}-${item.bezorgingId.toString()}`,
                                        disabled: true,
                                    },
                                    {
                                        key: 'downloadontvangstbericht',
                                        text: 'Ontvangen bericht',
                                        iconProps: { iconName: 'Download' },
                                        disabled: !item.ontvangenBerichtId,
                                        onClick: () => handleBlobDownload(item.ontvangenBerichtId, 'ontvangen-bericht'),
                                    },
                                    {
                                        key: 'downloadontvangstResponse',
                                        text: 'Response ontvangst',
                                        iconProps: { iconName: 'Download' },
                                        disabled: !item.ontvangenBerichtResponseId,
                                        onClick: () => handleBlobDownload(item.ontvangenBerichtResponseId, 'response-ontvangst'),
                                    },
                                    {
                                        key: 'downloadbezorgdbericht',
                                        text: 'Bezorgd bericht',
                                        iconProps: { iconName: 'Download' },
                                        disabled: !item.bezorgdBerichtId,
                                        onClick: () => handleBlobDownload(item.bezorgdBerichtId, 'bezorgd-bericht'),
                                    },                                    
                                    {
                                        key: 'downloadbezordResponse',
                                        text: 'Response bezorging',
                                        iconProps: { iconName: 'Download' },
                                        disabled: !item.bezorgdBerichtResponseId,
                                        onClick: () => handleBlobDownload(item.bezorgdBerichtResponseId, 'response-bezorging'),
                                    },
                                    {
                                        key: 'httpResponseCode',
                                        text: item.bezorgingHttpStatusCode ? `HTTP response status code bezorging: ${item?.bezorgingHttpStatusCode}` : '',
                                        disabled: true,
                                    },
                                ],
                            }}
                            hidden={true}
                            menuIconProps={{ iconName: '' }}
                        />
                    </div>
                )
            }
        ], 
        [handleVanNaarClick]);

    const handleGetKey = (item): string => {
        const key = (item as IBerichtbezorging).key;
        return key;
    }

    return (
        <div className="berichtbezorgingen-table">
            <OhkTable
                disableSort={false}
                columns={columns}
                items={filteredItems}
                loading={fetchStatus === 'pending'}
                onGetKey={handleGetKey}
                onSelectionChanged={onSelectionChanged} 
            />
        </div>
    )
}

export default BerichtBezorgingenTable